import { Modal } from "antd";
import React, { useState, useRef, useEffect } from "react";
import "./imgcrop.scss";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import cloneDeep from "lodash.clonedeep";
const ImgCropModal = ({
  showImgCropModal,
  setShowImgCropModal,
  imgs,
  setImgs,
  editImgIndex,
}) => {
  //  const imgUrl = URL.createObjectURL(imgs[editImgIndex].originFileObj);
  const handleOk = () => {
    saveCroppedImage();
    setShowImgCropModal(false);
  };
  // const handleCancel = () => {};
  // console.log("imgs ", imgs.length);
  // console.log("imgs ", editImgIndex);

  const [zoomValue, setZoomValue] = useState(1);
  const [rotateValue, setRotateValue] = useState(0);
  const EditorRef = useRef();
  // const [croppedImg, setCroppedImg] = useState({});
  const [imgUrl, setimgUrl] = useState("");

  //xxxxx
  // const onCrop = () => {
  //       console.log('in cropper');
  //       const imageElement = EditorRef?.current;
  //       const cropper = imageElement?.cropper;
  //       cropper.getCroppedCanvas().toBlob((blob) => {
  //         setCroppedImg(blob);
  //       });

  //       //  setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  //     };
  const saveCroppedImage = async () => {
    if (EditorRef.current) {
      const imageElement = EditorRef?.current;
      const cropper = imageElement?.cropper;
      let canvas = cropper?.getCroppedCanvas();
      canvas?.toBlob((blob) => {
        let arr = cloneDeep(imgs);
        arr[editImgIndex] = {
          id: editImgIndex,
          file: blob,
          url: URL.createObjectURL(blob),
        };
        setImgs([...arr], "crop");
      });
    }
  };
  // const handleZoomPlus = (val) => {
  //   if (zoomValue < 5) {
  //     setZoomValue(zoomValue + 1);
  //   }
  // };
  // const handleZoomMinus = (val) => {
  //   if (zoomValue > 1) {
  //     setZoomValue(zoomValue - 1);
  //   }
  // };
  // const handleRotatePlus = (val) => {
  //   if (rotateValue >= 360) {
  //     setRotateValue(0);
  //   } else {
  //     setRotateValue(rotateValue + 5);
  //   }
  // };
  // const handleRotateMinus = (val) => {
  //   if (rotateValue < 1) {
  //     setRotateValue(0);
  //   } else {
  //     setRotateValue(rotateValue - 5);
  //   }
  // };
  useEffect(() => {
    setimgUrl(imgs[editImgIndex].url);
  }, [editImgIndex]);
  return (
    <>
      <Modal
        title="Edit Image"
        open={showImgCropModal}
        onOk={handleOk}
        okText="Save changes"
        onCancel={() => setShowImgCropModal(false)}
        width={650}
        style={{ top: 5 }}
        destroyOnClose
      >
        <Cropper
          crossOrigin="anonymous"
          checkCrossOrigin={false}
          src={imgUrl}
          style={{ height: "400px", width: "100%" }}
          initialAspectRatio={1 / 1}
          guides={false}
          // crop={() => onCrop()}
          ref={EditorRef}
          viewMode={1}
          // guides={true}
          // minCropBoxHeight={10}
          // minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          aspectRatio={1 / 1}
          checkOrientation={false}
          scalable={true}
          rotatable={true}
          // cropBoxResizable={false}
          // zoomTo={0.5}
        />
      </Modal>
    </>
  );
};
export default ImgCropModal;
