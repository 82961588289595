import { Button, Steps } from "antd";
import React, { useEffect } from "react";
import "./style.css";
import { Select } from "./Content/Select";
import AddPhoto from "./Content/AddPhoto";
import { ChooseAlbum } from "./Content/ChooseAlbum";
import { AddAddress } from "./Content/AddAddress/AddAddress";
import { Checkout } from "./Content/Checkout/Checkout";
import Approve from "./Content/approve/Approve";
import { memo } from "react";
const { Step } = Steps;

const SelectPicSwagger = ({ currentIndex, prev }) => {
  console.log("hghjghg", currentIndex);
  console.log("precv", prev);
  // console.log("prev ", prev);
  // const { select } = useSelector((state) => state.orders);
  // console.log("values are ", select);
  // const navigate = useNavigate();
  // const [current, setCurrent] = useState(0);

  // const next = () => {
  //   setCurrent(current + 1);
  // };

  // const prev = () => {
  //   setCurrent(current - 1);
  // };
  // const handleClick = () => {
  //   message.success("Processing complete!");
  //   navigate("/orders-success");
  // };
  const steps = [
    {
      title: "Select",
      content: <Select />,
    },
    {
      title: "Add Photos",
      content: <AddPhoto />,
    },
    {
      title: "Approve",
      content: <Approve />,
    },
    {
      title: "Album Cover",
      content: <ChooseAlbum />,
    },
    {
      title: "Ship-To Address",
      content: <AddAddress />,
    },
    {
      title: "Checkout",
      content: <Checkout />,
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentIndex]);
  return (
    <div className="order container">
      {currentIndex > 0 && (
        <Button
          style={{
            margin: "12px 8px",
          }}
          onClick={prev}
          className="back"
        >
          Back
        </Button>
      )}
      <Steps current={currentIndex} labelPlacement="vertical">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} className="steps" />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentIndex].content}</div>
      {console.log("cdc", steps[currentIndex].content)}
      {/* <div className="steps-action">
        {current < steps.length - 1 &&
          (current == 0 ? (
            <Button onClick={() => next()} className="btn-x">
              NEXT: ADD PHOTOS
            </Button>
          ) : current == 1 ? (
            <div className="btn-approves">
              <button className="btn-save">SAVE CHANGES</button>
              <button className="btn-save" onClick={() => next()}>
                NEXT: APPROVE PICSWAGGER
              </button>
            </div>
          ) : current == 2 ? (
            <div></div>
          ) : (
            <Button
              className={`btn-next  ${current == 3 ? "btnX" : ""}`}
              onClick={() => next()}
            >
              {current == 3
                ? "NEXT: ADD SHIP-TO ADDRESS"
                : current == 4
                ? "NEXT:CHECKOUT"
                : "NEXT"}
            </Button>
          ))}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={handleClick} className="btn-x">
            PLACE ORDER
          </Button>
        )}
      
      </div> */}
    </div>
  );
};

export default memo(SelectPicSwagger);
