import { useEffect, useState } from "react";
import { getAllProducts,} from "../../APIS/DatabaseApis";
export default function useAllProducts(trigger) {
  const [allProducts, setallProducts] = useState([]);
  const getData = async () => {
    const res = await getAllProducts();
    setallProducts(res);
  };
  useEffect(() => {
    getData();
  }, [trigger]);

  return { allProducts };
}
