import { PublicLayout } from "../../PublicLayout/PublicLayout";
import React from "react";
import "./ordersuccess.scss";
import osuccess from "../../../assets/orders/osuccess.png";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
function OrderSuccess() {
  const navigate = useNavigate();
  // const { state } = useLocation();
  const { number } = useParams();
  const keepOrder = () => {
    navigate("/orders");
  };
  const gohome = () => {
    navigate("/");
  };
  // useEffect(() => {
  //  if (localStorage.getItem('reload')==='true') {
  //    window.location.reload();
  //    localStorage.setItem("reload", "false");
  //  }
  // }, [])
  return (
    <PublicLayout>
      <div className="container">
        <div className="order-success">
          <img src={osuccess} alt="" />
          <p>Thank you for your order.</p>
          <p className="text-center">
            Here is your order number <span>#{number}</span>
          </p>
          <div className="desc">
            We are on it! Your order will now be submitted to our print shop. We
            will update you when your order ships. Orders typically ship out on
            the next business day and take 1-3 days to arrive depending on your
            location.
          </div>
          <div className="btn-oder">
            <Button className="btn-x newbtn" onClick={keepOrder}>
              Keep Shoping
            </Button>
            <Button className="btn-x newbtn" onClick={gohome}>
              Go Home
            </Button>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default OrderSuccess;
