import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

import newbanner from "../../../assets/home/banner.png";

export const Banner = () => {
  const navigate = useNavigate();
 

  return (
    <>
      <div class="mainBannerDiv">
        <div className="container">
          <div class="mainBannerSubDiv ">
            {/* col-sm-5 col-md-5 col-lg-4 */}
            <div className="BannerHeading ">
              <h1>
                Add <br /> a Little <br /> Swagger...
                {/* Create Your Own Photo Album With Pic
                <span style={{ color: "#2A5DF0" }}>Swagger</span> */}
              </h1>
              <br />
              <div>
                <span className="heading_p">To your next celebration. </span>
              </div>
              {/* <p>
                Maintaining a focus on meeting the needs of the present without
                compromising the needs of future generations.{" "}
              </p> */}
              <div className="heading_btn_div">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="btn BannerButton mb-3 p-2 px-3"
                >
                  order now
                  {/* <i className="fa fa-shopping-cart p-1"></i>Order Now */}
                </button>
              </div>
            </div>
            {/* col-sm-12 col-md-7 col-lg-8 */}
            <div className="BannerImg ">
              <img className="img-xu" src={newbanner} alt="Snow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
