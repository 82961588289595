import React from 'react'
import './cover_fram.style.css';
export default function CoverFram({coverImg,albumImg}) {
  return (
    <div className="main_cover_fram_div">
      <div className="album_cover_div_">
        <div className="album_div_">
          <img
            className="cover_img_"
            src={coverImg}
            onClick={() => window.open(coverImg)}
          />
        </div>
        <div className="user_img_div_">
          <img
            className="user_img_"
            src={albumImg}
            onClick={() => window.open(albumImg)}
          />
        </div>
      </div>
    </div>
  );
}

