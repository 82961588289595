import React, { useEffect, useState } from "react";
import "./style.css";
import { json, Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.png";
import { BiCart } from "react-icons/bi";
import profile from "../../assets/profile.png";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "antd";
import {
  saveAddress,
  saveAlbumCoverName,
  saveName,
  savePhoto,
  setCurrentIndex,
  setOrderId,
} from "../../redux/slices/orderSlice";
export const Header = () => {
  let data = JSON.parse(localStorage.getItem("data"));
  const { address } = useSelector((state) => state.orders);
  const [cart, setCart] = useState(0);
  useEffect(() => {
    if (address) {
      let cartCount = Math.round(
        address.reduce((acc, val) => {
          acc += val.quantity;
          return acc;
        }, 0)
      );
      setCart(cartCount);
    }
  }, [JSON.stringify(address)]);
  return (
    <div>
      {/* // < !--Navbar -- > */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        {/* <!-- Container wrapper --> */}
        <div className="container">
          {/* <!-- Toggle button --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars"></i>
          </button>

          {/* <!-- Collapsible wrapper --> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <!-- Navbar brand --> */}
            <NavLink to="/" className="navbar-brand">
              {/* <h1>
                <b>PicSwagger</b>
              </h1> */}
              <img src={logo} className="img-x" />
            </NavLink>
            {/* <!-- Left links --> */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/home">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/orders">
                  Order
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/products">
                  Products
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/giftcard">
                  Gift Cards
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/inspiration">
                  Inspiration
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/faqs">
                  FAQs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About Us
                </NavLink>
              </li>
              {!data && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/signin">
                      <span id="signin">Sign In</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/signup">
                      <span id="signup">Sign Up</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            {/* <!-- Left links --> */}
          </div>
          {/* <!-- Collapsible wrapper --> */}

          {/* <!-- Right elements --> */}

          <div className="d-flex align-items-center x-clp">
            {data && (
              <NavLink className="nav-link" to="/account/my-account">
                <div className="account-x">
                  <h6 className="">Hi, {data.data.firstName}</h6>
                  <img src={profile} />
                </div>
              </NavLink>
            )}
            {/* {address?.length > 0 && <Badge count={cart}><BiCart className="shopping-cart" /></Badge>} */}
          </div>

          {/* <!-- Right elements --> */}
        </div>
        {/* <!-- Container wrapper --> */}
      </nav>
      {/* // <!--Navbar --> */}
    </div>
  );
};
