import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AlertModal = ({
  title,
  msg,
  showAlertModal,
  setShowAlertModal,
  extra,
}) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setShowAlertModal(false);
    if (extra) {
      navigate("/signin");
    }
  };

  const handleCancel = () => {
    setShowAlertModal(false);
    if (extra) {
      navigate("/signin");
    }
  };

  return (
    <>
      <Modal
        title={title}
        open={showAlertModal}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <p>{msg}</p>
      </Modal>
    </>
  );
};

export default AlertModal;
