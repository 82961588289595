import { Link } from "react-router-dom";

export const accordion = [
  {
    title: "What is a PicSwagger?",
    description: `PicSwagger is a custom accordion photo banner that offers a unique way to display your photos while celebrating your loved ones and special memories. 16-24 of your favorite photos offer the perfect backdrop to birthday parties, weddings, sports banquets, and other special events. PicSwagger is offered in two sizes: The Classic - 8x8" panels and The Mini - 4x4" panels. While the Classic size is often the center of attention at a party, the Mini version makes a fun keepsake for a trip or sports season and fits great on a bookshelf or nightstand. To learn more about how PicSwaggers came to be,`,
    link: <Link to={"/about"}>feel free to read our story here</Link>,
  },
  {
    title: "How long does it take for my PicSwagger to arrive?",
    description:
      "PicSwaggers are typically printed and shipped on the next business day.  All orders are shipped from our print shop in Salt Lake City, Utah. Standard shipping time is 2-4 days depending on proximity to SLC,UT. Priority shipping is available and usually takes 1-2 days.",
  },
  {
    title: "How much time does it take to order a PicSwagger? ",
    description:
      "Once you have your photos gathered, ordering a PicSwagger is a snap. You can create and order a PicSwagger on our website in less than 10 minutes. Watch this tutorial to see how fun and easy the process is.",
    // video:
    // "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2Fvideos%2Fpicswagger-toturial.mp4?alt=media&token=e4772d35-bcf7-49ac-a363-10fd597c89f0",
    video:"https://player.vimeo.com/video/786972261?h=f6f9428f3c&autoplay=1&loop=1&muted=1",
    // video: "https://player.vimeo.com/video/786346444?h=965739ca23",
    // video: "https://vimeo.com/786972261/f6f9428f3c",
  },
  {
    title: "What are the sizes of the Classic and the Mini PicSwagger? ",
    description: `The panels on the Classic size are just under 8x8". A 16-page PicSwagger is 8" tall by 64" long and covers around 4.5 feet when spread out on a table.  The Mini is 4x4".  A 16-page Mini PicSwagger is 4" tall by 32" long and covers around 2.5 feet when spread out on a shelf or desk.`,
  },
  {
    title: "Can I get my order rush shipped? ",
    description: "Priority shipping is available at checkout. ",
  },
];
