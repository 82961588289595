import { Badge, Col, Row, Image, Card, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import "./socailImgs.scss";
const FacebookImagesModal = ({ fimgs, setImgs, showModal, setShowModal }) => {
  const [allImgs, setAllImgs] = useState([]);

  const handleOk = () => {
    setShowModal(false);
    handleDone();
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleSelect = (i, v) => {
    if (v.select) {
      let arr = [...allImgs];
      arr[i].select = false;
      setAllImgs([...arr]);
    } else {
      let arr = [...allImgs];
      arr[i].select = true;
      setAllImgs([...arr]);
    }
  };
  const handleDone = () => {
    let finalArray = allImgs.filter((v) => v.select);
    if (finalArray?.length > 0) {
      setImgs(
        finalArray?.map((v) => {
          return { file: v.file, url: v.url, id: v.url };
        })
      );
    } else {
      alert("No imgs select");
    }
  };
  useEffect(() => {
    if (fimgs?.length > 0) {
      setAllImgs(
        fimgs.map((v) => {
          return {
            select: false,
            ...v,
          };
        })
      );
    }
  }, [JSON.stringify(fimgs)]);

  return (
    <>
      <Modal
        title={"Facebook Gallery"}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"70%"}
        destroyOnClose
      >
        <div>
          <Row>
            {allImgs?.map((v, i) => {
              return (
                <Col key={i} className={"p-4"}>
                  <Badge.Ribbon
                    text={
                      <p
                        className="gallery_p"
                        onClick={() => {
                          handleSelect(i, v);
                        }}
                      >
                        {v.select ? (
                          <AiFillCheckCircle />
                        ) : (
                          <AiOutlineCheckCircle />
                        )}
                      </p>
                    }
                    color={"primary"}
                  >
                    <Card
                      hoverable
                      style={{
                        width: 200,
                        height: 150,
                      }}
                    >
                      <Image
                        className="image"
                        width={150}
                        height={100}
                        alt="images"
                        src={v.url}
                      />
                    </Card>
                  </Badge.Ribbon>
                </Col>
              );
            })}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default FacebookImagesModal;
