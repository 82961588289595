import { Progress } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './progressbar.style.css';
import { CheckCircleOutlined } from "@ant-design/icons";
import { red, green } from '@ant-design/colors';
function ProgressLoader({ value }) {
  const [percent, setPercent] = useState(0);
  const { select } = useSelector((state) => state.orders);
  const overlayStyles = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: 999,
  };
  const loadingStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
    textAlign: 'center',
    backgroundColor: "#f3b5c0",
    padding: '20px',
    borderRadius: '20px',
  };

  useEffect(() => {
    setPercent(value);
  }, [value]);
  return (
    <>
      <div className="overlay_div" style={overlayStyles}></div>
      <div className="loading_div" style={loadingStyles}>
        <div className="ps_logo_div">
          <img className="ps_logo" src={"/assets/loader/loader1.png"} />
          {/* <img className="ps_logo" src={"/assets/home/arrow.png"} /> */}
        </div>
        <div>
          {percent <= 0 ? (
            <p style={{ fontSize: "18px" }}>
              <b>
                We're now uploading your photos to our servers so we can make
                the magic happen. Thanks for your patience!
              </b>
            </p>
          ) : percent == 100 ? (
            <p style={{ fontSize: "18px" }}>
              <b>
                Final Step: Building Print PDF. <br />
                Thanks for your patience!
              </b>
            </p>
          ) : (
            <p style={{ fontSize: "18px" }}>
              <b>High-res image</b>{" "}
              <span style={{ color: "green" }}>
                {`#${Math.round((percent * select?.page) / 100)} of ${
                  select?.page
                } upload complete   `}
                <CheckCircleOutlined />
              </span>
            </p>
          )}
        </div>
        <Progress
          type="line"
          percent={percent}
          steps={select?.page}
          strokeColor={new Array(
            Math.round((percent * select?.page) / 100)
          ).fill(green[6])}
          style={{
            marginRight: 8,
            color: "#ea6a45",
          }}
        />
      </div>
    </>
  );
}

export default React.memo(ProgressLoader);
