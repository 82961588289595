import React from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { reviewDetails } from "./data";
// import { ReviewCard } from "./ReviewCard";
import "./style.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
const prlImages = [
  {
    title: "IRL Neutral Background",
    url: "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FpicswaggerImages%2FpicSwagger_IRL_GRAPHIC_neutral_background.new_png.png?alt=media&token=c353ff78-805a-4bd5-b975-2f9d37c059fb",
  },
  {
    title: "IRL Blue Background",
    url: "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FpicswaggerImages%2FpicSwagger_IRL_GRAPHIC_blue_background_new.png?alt=media&token=6fec0bb3-0bb3-4f3b-b092-f056317f8f8c",
  },
  {
    title: "IRL Pink Background",
    url: "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FpicswaggerImages%2FpicSwagger_IRL_GRAPHIC_pink_background_new.png?alt=media&token=58abc7e0-0e42-4fa8-8ef3-5e4a29825092",
  },
];

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };
export const Review = () => {
  // return (
  //   <div className="reviewCard">
  //     <Carousel
  //       responsive={responsive}
  //       infinite={true}
  //       arrows={false}
  //       className="container "
  //     >
  //       {reviewDetails?.map((record) => (
  //         <ReviewCard
  //           img={record.img}
  //           description={record.description}
  //           name={record.name}
  //           address={record.address}
  //         />
  //       ))}
  //     </Carousel>
  //   </div>
  // );
  return (
    <>
      <p style={{ textAlign: "center" }}>
        <h1 className="fw-bold h1-q">PicSwagger IRL</h1>
      </p>

      <div className="reviewCard">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          scrollbar={{ draggable: true }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <div className="prl_img_div">
              {/* <h4>{record?.title}</h4> */}
              <img src={prlImages[1]?.url} alt="" />
            </div>
          </SwiperSlide>

          {/* {reviewDetails?.map((record) => (
          <SwiperSlide>
            <ReviewCard
              img={record.img}
              description={record.description}
              name={record.name}
              address={record.address}
            />
          </SwiperSlide>
        ))} */}
        </Swiper>
      </div>
    </>
  );
};
