import { memo, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Dropdown,
  Space,
  Radio,
  Card,
  Popconfirm,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  createCustomer,
  createPaymentIntent,
  updateOrder,
} from "../../../../../APIS/DatabaseApis";
import { useSelector, useDispatch } from "react-redux";
import FullPageLoading from "../../../../Loading/FullPageLoading";
import usePaymentMethods from "../../../../hooks/usePaymentMethods";
import { setPaymentDetail } from "../../../../../redux/slices/orderSlice";
import { Link, useNavigate } from "react-router-dom";

const CheckoutForm = ({ amount, placeOrder }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { paymentMethods } = usePaymentMethods();
  const [saveCard, setSaveCard] = useState(false);
  const [cardElements, setCardElements] = useState({});
  const { orderId } = useSelector((state) => state.orders);
  const [loading, setloading] = useState(false);
  const user = JSON.parse(localStorage.getItem("data"));
  const customer = user && user.data.customerId;

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    let price = parseFloat(amount * 100).toFixed(1);
    let index = price.lastIndexOf(".");
    let decimal = price.slice(index + 1, price.length);
    decimal = parseInt(decimal);
    price = parseFloat(price);
    if (decimal > 5) {
      price = Math.ceil(price);
    } else {
      price = Math.floor(price);
    }

    console.log("final price", price);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setloading(true);
    let customerData = {
      name: user?.data?.firstName,
      email: user?.data?.email,
    };
    const costmer = customer ? customer : await createCustomer(customerData);

    let paymentData = {
      customer: costmer,
      amount: price,
      currency: "usd",
      metadata: { orderId },
    };

    if (saveCard) {
      paymentData["setup_future_usage"] = "on_session";
    }
    const paymentIntent = await createPaymentIntent(paymentData);
    const result = await stripe.confirmCardPayment(
      paymentIntent?.client_secret,
      {
        //`Elements` instance that was used to create the Payment Element
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.data.firstName + user?.data.lastName,
            email: user?.data.email,
          },
        },
      }
    );
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setloading(false);
    } else {
      // console.log(result);
      let paymentdata = {
        id: result?.paymentIntent?.id,
        status: result?.paymentIntent?.status,
        amount: result?.paymentIntent?.amount / 100,
      };
      let orderdata = {
        payment: paymentdata,
        totalAmount: amount,
      };
      await updateOrder(orderId, orderdata);
      dispatch(setPaymentDetail(paymentdata));
      placeOrder(paymentData);
      setloading(false);
      // alert(result?.paymentIntent?.status);
    }
  };

  const handleSaveMethod = async (item) => {
    try {
      setloading(true);
      const costmer = customer;
      console.log(costmer);
      let paymentData = {
        customer: costmer,
        amount: amount * 100,
        currency: "usd",
        metadata: { orderId },
        payment_method: item.id,
        confirm: true,
      };
      const paymentIntent = await createPaymentIntent(paymentData);
      let paymentdata = {
        id: paymentIntent?.id,
        status: paymentIntent?.status,
        amount: paymentIntent?.amount / 100,
      };
      let orderdata = {
        payment: paymentData,
        totalAmount: amount,
      };
      await updateOrder(orderId, orderdata);
      dispatch(setPaymentDetail(paymentdata));
      placeOrder(paymentdata);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  return (
    <>
      {loading ? <FullPageLoading /> : ""}
      <div className="billingInfo">
        <div className="creditcard_btns_div">
          <p className="credit_dropdown">
            <Dropdown
              menu={{
                items: paymentMethods?.map((v, i) => {
                  return {
                    label: (
                      <div className="creditcard_content">
                        <Card
                          title={<Space>{v?.card?.brand.toUpperCase()}</Space>}
                          style={{
                            width: 250,
                          }}
                          extra={
                            <Space>
                              <Popconfirm
                                title={"Are you sure?"}
                                onConfirm={(e) => {
                                  e.stopPropagation();
                                  handleSaveMethod(v);
                                }}
                              >
                                <Button type="primary">Proceed</Button>
                              </Popconfirm>
                            </Space>
                          }
                          actions={[
                            <Space>
                              <span>Expire</span>
                              <span>
                                {v?.card?.exp_month}/{v?.card?.exp_year}
                              </span>
                            </Space>,
                            <Space>
                              <span>Cvv</span>
                              <span>***</span>
                            </Space>,
                          ]}
                        >
                          <span>**** **** **** {v?.card?.last4}</span>
                        </Card>
                      </div>
                    ),
                    key: { i },
                  };
                }),
              }}
              trigger={["click"]}
            >
              <p>
                <Button>
                  <Space>
                    Choose From Save Cards
                    <DownOutlined />
                  </Space>
                </Button>
              </p>
            </Dropdown>
          </p>
        </div>
        <h4>Card Info</h4>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="strip_card_div">
              <p className="stripe_card">
                <CardElement
                  id="card-element"
                  onChange={(e) => {
                    setCardElements(e);
                  }}
                />
              </p>
              <p>
                <span style={{ float: "right" }}>Powered By Stripe</span>
              </p>
              <p>
                <Checkbox
                  checked={saveCard}
                  onChange={(e) => setSaveCard(e.target.checked)}
                >
                  Save Card for future use
                </Checkbox>
              </p>
            </div>

            <div
              className="steps-action"
              style={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <button
                disabled={!cardElements?.complete}
                type="submit"
                className="btn-x"
                style={{
                  width: "150px",
                  borderRadius: "10px",
                  border: "none",
                  opacity: !cardElements?.complete ? "0.6" : 1,
                }}
              >
                {"Place Order"}
              </button>

              {/* <button
                disabled={cardElements?.complete}
                type="submit"
                className="btn-x"
                onClick={()=>navigate('/')}
                style={{
                  width: "150px",
                  borderRadius: "10px",
                  border: "none",
                  opacity: cardElements?.complete ? "0.6" : 1,
                  
                }}
              >
                {"Go To Home"}
              </button>
              <button
                disabled={cardElements?.complete}
                type="submit"
                className="btn-x"
                onClick={()=>navigate('/orders')}
                style={{
                  width: "150px",
                  borderRadius: "10px",
                  border: "none",
                  opacity: cardElements?.complete ? "0.6" : 1,

                  
                }}
              >
                {"Keep Shoping"}
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default memo(CheckoutForm);
