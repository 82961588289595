import { Link } from "react-router-dom";

export const accordion = [
  {
    title: "What is a PicSwagger?",
    description: `PicSwagger is a custom accordion photo banner that offers a unique way to display your photos while celebrating your loved ones and special memories. 16-24 of your favorite photos offer the perfect backdrop to birthday parties, weddings, sports banquets, and other special events. PicSwagger is offered in two sizes: The Classic - 8x8" panels and The Mini - 4x4" panels. While the Classic size is often the center of attention at a party, the Mini version makes a fun keepsake for a trip or sports season and fits great on a bookshelf or nightstand. To learn more about how PicSwaggers came to be,`,
    link: <Link to={"/about"}>feel free to read our story here</Link>,
  },
  {
    title: "How long does it take for my PicSwagger to arrive?",
    description:
      "PicSwaggers are typically printed and shipped on the next business day.  All orders are shipped from our print shop in Salt Lake City, Utah. Standard shipping time is 2-4 days depending on proximity to SLC,UT. Priority shipping is available and usually takes 1-2 days.",
  },
  {
    title: "How much time does it take to order a PicSwagger? ",
    description:
      "Once you have your photos gathered, ordering a PicSwagger is a snap. You can create and order a PicSwagger on our website in less than 10 minutes. Watch this tutorial to see how fun and easy the process is.",
    // video:
    //   "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2Fvideos%2Fpicswagger-toturial.mp4?alt=media&token=e4772d35-bcf7-49ac-a363-10fd597c89f0",
    video:
      "https://player.vimeo.com/video/786972261?h=f6f9428f3c&autoplay=1&loop=1&muted=1",
  },

  {
    title: "What are the sizes of the Classic and the Mini PicSwagger? ",
    description: `The panels on the Classic size are just under 8x8". A 16-page PicSwagger is 8" tall by 64" long and covers around 4.5 feet when spread out on a table.  The Mini is 4x4".  A 16-page Mini PicSwagger is 4" tall by 32" long and covers around 2.5 feet when spread out on a shelf or desk.`,
  },
  {
    title: "Can I get my order rush shipped? ",
    description: "Priority shipping is available at checkout. ",
  },
  {
    title: "How much does it cost to ship a PicSwagger? ",
    description:
      "A Classic PicSwagger ships via USPS 1st-class and costs $3 each. A Mini PicSwagger ships via USPS 1st-class and costs $1 each. Priority shipping is available and costs $8 for both Mini and Classic PicSwaggers. ",
  },
  {
    title: "Is there a minimum photo resolution I should use? ",
    description:
      "Your pics should be at least 800 x 800 pixels to make a clear print. If you use a screen shot off your phone or computer, make sure to use as large of a starting image as possible. ",
  },
  {
    title: "Can I use photos from my Facebook and Instagram? ",
    description: `Yes! On the add photos step, select "Import from Facebook" or "Import from Instagram" when adding your photos.  You can also import images from your device as well as Google photos.`,
  },
  {
    title: "How do I use Canva to create my PicSwagger? ",
    description:
      "Canva is a popular design tool that’s easy to pick up and use right away. It’s a perfect solution for adding photo collages, word art and extra design flair to your PicSwagger. We created starting templates in Canva to make the process easy and fun. You can watch a tutorial video here to see how it’s done. ",
    // video:
    // "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2Fvideos%2FCanva-tutorial.mp4?alt=media&token=43e967ad-61d5-4b6e-b0d4-1a79dd9cc1db",
    video:
      "https://player.vimeo.com/video/787103941?h=dbc70c15c3&autoplay=1&loop=1&muted=1",
  },
  {
    title: "Can I use photos from my Google Drive and Dropbox? ",
    description:
      "Right now, we don’t offer this access directly. However, you can access photos from Google Drive and Dropbox via the Canva editor and then import your Canva PDF directly into PicSwagger.  ",
  },
  {
    title: "Do you ship internationally? ",
    description:
      "Currently, we only offer shipping within the United States on our website. We plan to offer shipping to select international countries in 2023. ",
  },
  {
    title: "Do you offer custom album covers for PicSwagger? ",
    description:
      "Currently, we don’t support custom album covers on our website. If you are ordering for a larger event where you will be ordering 20 or more PicSwaggers, contact customer support to add a custom album cover for an additional charge (support@picswagger.com). ",
  },
];
