import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import "./forgot.scss";
import logo from "../../assets/logo/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AlertModal from "../../components/modals/alertmodal/AlertModal";
import FullPageLoading from "../../components/Loading/FullPageLoading";
const ForgotPass = () => {
  const parm = useParams();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [loadingX, setLoadingX] = useState(false);

  const onFinish = async (values) => {
    setLoadingX(true);
    let token = parm?.token?.replaceAll("ABC45", ".");
    // https://picswagger-backend.uc.r.appspot.com/
    const data = {
      id: parm?.id,
      token: token,
      password: values?.newPassword,
    };
    try {
      const res = await axios.post(
        "https://picswagger-backend.uc.r.appspot.com/user/reset_password",
        { ...data }
      );
      console.log("response is ", res);
      // alert(res.message);
      setLoadingX(false);
      setShowAlertModal(true);
      // navigate("/home");
    } catch (e) {
      console.log("errror is ", e);
      setLoadingX(false);
    }
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="form-x">
      {loadingX && <FullPageLoading />}
      {showAlertModal && (
        <AlertModal
          title="Password Reseted"
          msg="Success! your password is reset."
          showAlertModal={showAlertModal}
          setShowAlertModal={setShowAlertModal}
          extra={true}
        />
      )}
      <img src={logo} alt="" />
      <div className="content">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                min: 8,
                message: "Password must be 8 character long.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please Enter your confirm password!",
              },
              {
                min: 8,
                message: "Password must be 8 character long.",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ForgotPass;
