export const productDetails = [
  {
    size: 'Classic (8x8")',
    pages: 16,
    detial: `Picswagger Classic - 16 page
- 8, 8x8" photos per side (16 in total)
- Covers a ~4.5 foot area spread out on a table
- Printed on premium paper stock with rounded corners
- Delivered in a hip album cover sleeve of your choice
- $16.99
- First-class ($3.00) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
  {
    size: 'Classic (8x8")',
    pages: 20,
    detial: `Picswagger Classic - 20 page
- 10, 8x8" photos per side (20 in total)
- Covers a ~5.5 foot area spread out on a table
- Printed on premium paper stock with rounded corners
- Delivered in a hip album cover sleeve of your choice
- $19.99
- First-class ($3.25) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
  {
    size: 'Classic (8x8")',
    pages: 24,
    detial: `Picswagger Classic - 24 page
- 12, 8x8" photos per side (24 in total)
- Covers a ~6.5 foot area spread out on a table
- Printed on premium paper stock with rounded corners
- Delivered in a hip album cover sleeve of your choice
- $22.99
- First-class ($3.50) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
  {
    size: 'Mini (4x4")',
    pages: 16,
    detial: `Picswagger Mini - 16 page
- 8, 4x4" photos per side (16 in total)
- Covers a 27 inch area spread out on a shelf / desk
- Printed on premium, thick paper stock
- Delivered in a hip album cover sleeve of your choice
- $12.99
- First-class ($1.00) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
  {
    size: 'Mini (4x4")',
    pages: 20,
    detial: `Picswagger Mini - 20 page
- 10, 4x4" photos per side (20 in total)
- Covers a 34 inch area spread out on a shelf / desk
- Printed on premium, thick paper stock
- Delivered in a hip album cover sleeve of your choice
- $14.99
- First-class ($1.25) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
  {
    size: 'Mini (4x4")',
    pages: 24,
    detial: `Picswagger Mini - 24 page
- 12, 4x4" photos per side (24 in total)
- Covers a 40 inch area spread out on a shelf / desk
- Printed on premium, thick paper stock
- Delivered in a hip album cover sleeve of your choice
- $16.99
- First-class ($1.50) or Priority ($8.50) shipping available to anywhere in the USA.
- You'll love it or your money back!`,
  },
];
