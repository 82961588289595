import { Row, Col } from "antd";
import React from "react";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
import "./about.scss";
import girl from "../../assets/about/girl.jpg";
import girl2 from "../../assets/about/girl2.png";
import ogift from "../../assets/about/ogift.png";
import affordable from "../../assets/about/affordable.png";
import customizegift from "../../assets/about/customizegift.png";
import qualitygift from "../../assets/about/qualitygift.png";
import { BsCartPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  return (
    <PublicLayout>
      <div className="about">
        <h1
          style={{
            fontFamily: "Groovy Fruity",
            fontSize: "70px",
            color: "#262f7c",
          }}
        >
          How PicSwagger was born
        </h1>
        <div className="about-desc">
          <Row>
            <Col
              xs={24}
              sm={12}
              lg={12}
              className="pr-5"
              style={{ paddingRight: "30px" }}
            >
              {/* <h3 style={{ fontFamily: "Groovy Fruity", fontSize: "60px" }}>
                How PicSwagger was born
              </h3> */}

              <p>
                Anyone who knows Co-founder Sarah Gardner well has likely
                experienced one of her many parties. Her love language involves
                bringing people together to celebrate, share laughs and make
                memories. For the past few years, this party energy was
                channeled on parties and fun events for her four kids - like the
                time she made gourmet crepes for 75 7th graders or let 15 9-year
                olds use unlimited glitter and supplies for various art
                projects.
                <br />
                <br />
                PicSwagger started out as a family tradition to gather framed
                pictures of the guest of honor from around the house and display
                them on the kitchen island along with the cake and balloons.
                This evolved into designing and making an accordion photo banner
                for every special occasion, a “PicSwagger” long before it even
                had a name.
                <br />
                <br />
                Sarah and her husband JD have owned a successful printing
                business for over 15 years. The best part of this for Sarah has
                been having access to top printing equipment to take her DIY
                projects to the next level, including photo party hats, family
                cook books, custom Guess Who and Memory games to name a few.
                PicSwaggers quickly became the most requested of these DIY gifts
                by family and friends. After making hundreds of custom
                PicSwaggers, the Gardners decided to share the love and make
                this novel photo product available to everyone.
              </p>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <img src={girl2} className="img-x" />
            </Col>
          </Row>
        </div>
        {/* <div className="calculations">
          <div className="item-x">
            <h3>10k+</h3>
            <p>Album Created</p>
          </div>
          <div className="item-x">
            <h3>10k+</h3>
            <p>Happy Customer</p>
          </div>
          <div className="item-x">
            <h3>30k+</h3>
            <p>Photo Printed</p>
          </div>
          <div className="item-x">
            <h3>30k+</h3>
            <p>Team Members</p>
          </div>
        </div> */}
        {/* <div className="why-PicSwagger"> */}
        {/* <h3>Why PicSwagger</h3> */}
        <h1
          style={{
            fontFamily: "Groovy Fruity",
            fontSize: "70px",
            color: "#262f7c",
          }}
        >
          Why PicSwagger?
        </h1>
        <div className="section">
          <Row>
            <Col xs={24} lg={12} sm={12}>
              <h4
                style={{
                  fontFamily: "Groovy Fruity",
                  fontSize: "60px",
                  color: "#f3b5c0",
                }}
              >
                Celebrate Life with Swagger
              </h4>
              <p>
                Has there ever been an occasion or special person in your life
                that truly needs to be celebrated? Where going a little above
                and beyond is 100% justified and even called for. We created
                PicSwagger to help you rise to the occasion on these very sorts
                of special occasions. You'll make a big statement at your next
                big event and then cherish the memories and this keepsake for
                years afterwards.
              </p>
              {/* <button
                onClick={() => {
                  navigate("/products");
                }}
                className="btn-x"
              >
                <BsCartPlus className="icon" />
                Order Now
              </button> */}
            </Col>
            <Col xs={24} lg={12} sm={12}>
              <img src={ogift} className="img-x" />
            </Col>
          </Row>
        </div>

        <div className="section">
          <Row>
            <Col xs={24} lg={12} sm={12}>
              <img src={customizegift} className="img-x" />
            </Col>
            <Col xs={24} lg={12} sm={12}>
              <h3
                style={{
                  fontFamily: "Groovy Fruity",
                  fontSize: "60px",
                  color: "blue",
                }}
              >
                Design Fun and Flexibility
              </h3>
              <p>
                Our service makes it super quick and fun to design and order
                your next PicSwagger. It also provides you unlimited design
                flexibility with Canva. To add a little extra flair, you can
                easily add custom word art, stickers, photo collages, effects
                and more. The sky's the limit on what’s possible!
              </p>
              {/* <button
                onClick={() => {
                  navigate("/products");
                }}
                className="btn-x"
              >
                Try Now
              </button> */}
            </Col>
          </Row>
        </div>
        <div className="section">
          <Row>
            <Col xs={24} lg={12} sm={12}>
              <h3
                style={{
                  fontFamily: "Groovy Fruity",
                  fontSize: "50px",
                  color: "#fc5f37",
                }}
              >
                Above and Beyond Thoughtful
              </h3>
              <p>
                PicSwagger is way up there on the “Hierarchy of Care” spectrum
                and easily outshines many more expensive gift options. It's true
                someone may forget what you say and do, but they'll always
                remember when you make them feel special and loved. PicSwagger’s
                mission is to help your loved ones truly feel the love.
              </p>

              {/* <button
                onClick={() => {
                  navigate("/products");
                }}
                className="btn-x"
              >
                <BsCartPlus className="icon" />
                Order Now
              </button> */}
            </Col>
            <Col xs={24} lg={12} sm={12}>
              <img src={qualitygift} className="img-x" />
            </Col>
          </Row>
        </div>
        <div className="section">
          <Row>
            <Col xs={24} lg={12} sm={12}>
              <img src={affordable} className="img-affordable" />
            </Col>
            <Col xs={24} lg={12} sm={12}>
              <h3
                style={{
                  fontFamily: "Groovy Fruity",
                  fontSize: "60px",
                  color: "#262f7c",
                }}
              >
                Affordable Awesomeness
              </h3>
              <p>
                Don’t let our affordable pricing fool you, PicSwaggers are
                designed and produced to have a hip & hand-crafted artisan feel.
                We print on thick, premium paper stock with the best digital
                printers available. We die-cut each album cover and round each
                corner with care to make your PicSwagger a unique masterpiece.
                You’ll love how your PicSwagger feels in your hands and displays
                on your table.
              </p>
              {/* <button
                onClick={() => {
                  navigate("/products");
                }}
                className="btn-x"
              >
                Try Now
              </button> */}
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </PublicLayout>
  );
}

export default About;
