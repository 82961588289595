import "./addphoto.scss";
import { Modal, Tooltip, Space } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { memo, useEffect, useState, useRef } from "react";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";
import { SiCanva } from "react-icons/si";
// import { BiUpload } from "react-icons/bi";
// import girl from "../../../../assets/about/girl.jpg";
import emptypic from "../../../../assets/orders/emptypic.png";
import "antd/es/modal/style";
import "antd/es/slider/style";
import Imgs16Pair from "./imgsPair/Imgs16Pair";
import Imgs20Pair from "./imgsPair/Imgs20Pair";
import Imgs24Pair from "./imgsPair/Imgs24Pair";
import { useDispatch, useSelector } from "react-redux";
import InfoAlert from "../../../modals/infoAlert/InfoAlert";
import {
  savePhoto,
  next,
  saveName,
  instaAuthState,
} from "../../../../redux/slices/orderSlice";
import {
  FacebookSdk,
  getInstaAuthorization,
  getInstaMedia,
} from "../../../../APIS/API";
import FacebookImagesModal from "../../../modals/socialMediaimgs/facebookImgsModal";
import InstagramImagesModal from "../../../modals/socialMediaimgs/instagramImgsModal";
import FullPageLoading from "../../../Loading/FullPageLoading";
// import { updateOrder } from "../../../../APIS/DatabaseApis";
///hooks
import useUserOrders from "../../../hooks/useUserOrders";
import share from "../../../../assets/orders/canva/share.png";
import download from "../../../../assets/orders/canva/download.png";
import download2 from "../../../../assets/orders/canva/download2.png";
import pdfprint from "../../../../assets/orders/canva/pdfprint.png";
import ProgressLoader from "../../../Loading/ProgressLoader";
import { Link } from "react-router-dom";
const AddPhoto = () => {
  const dropRef = useRef(null);
  const dispatch = useDispatch();
  const { userOrders } = useUserOrders();
  const [progressLoading, setProgressLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dragging, setdragging] = useState(false);
  const {
    select,
    photos,
    name,
    instaState,
    orderId,
    isViewOnly,
    productPrice,
  } = useSelector((state) => state.orders);
  let imgs = [...photos];
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  const setImgs = (arr, from) => {
    if (from && ["swap", "delete", "crop"].includes(from)) {
      let newArr = arr.map((v, i) => {
        return {
          url: v.url,
          file: v.file,
          id: i,
        };
      });
      dispatch(savePhoto(newArr));
    } else {
      let newArr = arr.map((v, i) => {
        return {
          url: v.url,
          file: v.file,
          id: imgs.length + i,
        };
      });
      dispatch(savePhoto([...imgs, ...newArr]));
    }
  };
  // console.log("values are ", currentIndex);
  let flag = select.page;
  const [showModel, setShowModel] = useState(false);
  const [showFbImagesModel, setShowFbImagesModel] = useState(false);
  const [showInstaImagesModel, setShowInstaImagesModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isFb, setIsFb] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [isCanva, setIsCanva] = useState(false);
  // const [imgs, setImgs] = useState([]);
  const [fimgs, setFImgs] = useState([]);
  const [instaImgs, setInstaImgs] = useState([]);

  //
  const [isDel, setIsDel] = useState({
    flag: false,
    file: null,
  });
  const modalClose = () => {
    setShowModel(false);
    setIsFb(false);
    setIsCanva(false);
    setIsDel({
      flag: false,
      file: null,
    });
  };

  const addImgs = (e) => {
    setLoading(true);
    let files = [...e.target.files];
    let arr = [];
    files?.map(async (v, i) => {
      let file = v;
      let url = URL.createObjectURL(file);
      // console.log('before===>',file.size/1024);
      // file = await CropImage(file, url);
      // console.log("after===>", file.size / 1024);
      // url = URL.createObjectURL(file);
      arr.push({ id: i, file, url });
      if (arr.length === files.length) {
        setImgs([...arr].sort((a, b) => a.id - b.id));
        setLoading(false);
      }
      // var img = document.createElement("img");
      // img.crossOrigin = "Anonymous";
      // img.src = url;
      // img.onload = function () {
      //   var width = img.width;
      //   var height = img.height;
      //   var canvas = document.createElement("canvas");
      //   canvas.width = width;
      //   canvas.height = height;
      //   // canvas.style.objectFit="fit";
      //   var ctx = canvas.getContext("2d");
      //   ctx.drawImage(img, 0, 0, width, height);
      //   canvas.toBlob(async(blob) => {
      //     console.log('before blob',blob);
      //     let newurl = URL.createObjectURL(blob);

      //     arr.push({ id: i, file: blob, url: newurl });
      //     let neblob = await CropImage(blob);
      //     console.log("after blob", neblob);

      //     if (arr.length === files.length) {
      //       let fltrArray = arr.filter((v) => !v.unsupported);
      //       setImgs([...fltrArray].sort((a, b) => a.id - b.id));
      //       setLoading(false);
      //     }
      //   },);
      // };
      // img.onerror = () => {
      //   arr.push({ unsupported: true });
      //   message.warning(
      //     "sorry,unsupported format, or corrupted image can't upload"
      //   );
      // };
    });
    //  let reader = new FileReader();
    //  reader.readAsArrayBuffer(file);
    //  reader.onload = function () {
    //    let blob = new Blob([reader.result], { type: file.type });
    //    console.log(blob);
    //    arr.push({ id: i, file: blob, url });
    //    if (arr.length === files.length) {
    //      setImgs([...arr]);
    //    }
    //  };
  };

  const deleteImgs = (file) => {
    let indx = imgs.findIndex((val) => val.url === file.url);
    // console.log(indx);
    let imgsArr = JSON.parse(JSON.stringify(imgs));
    imgsArr.splice(indx, 1);

    setImgs([...imgsArr], "delete");
    setIsDel({
      flag: false,
      file: {},
    });
  };
  // const warningMessage = (msg, duration) => {
  //   message.warning({
  //     content: msg,
  //     style: { fontSize: "16px" },
  //     duration,
  //   });
  // };
  const pdfConvertor = (url) => {
    setProgressLoading(true);

    var PDFJS = window["pdfjs-dist/build/pdf"];

    PDFJS.GlobalWorkerOptions.workerSrc =
      "//mozilla.github.io/pdf.js/build/pdf.worker.js";

    var loadingTask = PDFJS.getDocument(url);
    loadingTask.promise.then(
      function (pdf) {
        // var canvasdiv = document.getElementById("canvas");
        var totalPages = pdf.numPages;
        var arr = [];
        let length = imgs.length;
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });

            var canvas = document.createElement("canvas");
            // canvasdiv.appendChild(canvas);

            // Prepare canvas using PDF page dimensions
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              //   data.push(canvas.toDataURL("image/png"));
              // console.log(data.length + " page(s) loaded in data");
              canvas.toBlob((blob) => {
                let url = URL.createObjectURL(blob);
                let data = {
                  id: length + pageNumber,
                  file: blob,
                  url: url,
                };
                arr.push(data);
                setProgress((100 / totalPages) * pageNumber);
                if (arr.length === totalPages) {
                  setImgs([...arr]);
                  setProgressLoading(false);
                }
              });
            });
          });
        }
      },
      function (reason) {
        // PDF loading error
        console.error(reason);
      }
    );
  };
  const Resize_Save_Image = (url) => {
    // var img = new Image();
    var img = document.createElement("img");
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      var width = img.width;
      var height = img.height;
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      // canvas.getContext("2d").drawImage(img, 0, 0, width, height);
      var ctx = canvas.getContext("2d");

      // Actual resizing
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        let data = {
          file: blob,
          url: URL.createObjectURL(blob),
        };
        setFImgs((prev) => [...prev, data]);
      });
      // resizedImage = DataUrlToBlob(dataUrl);
    };
  };
  const facebookHandler = async () => {
    try {
      let fbData = await FacebookSdk();
      if (fbData) {
        let arr = [];
        fbData?.albums?.data?.map((pd) => {
          pd?.photos?.data?.map(async (cd) => {
            Resize_Save_Image(cd?.images[0]?.source);
            arr.push(cd.images[0]);
          });
        });
        setShowFbImagesModel(true);
        modalClose();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      modalClose();
    }
  };
  const instagramHandler = async () => {
    let instaData = JSON.parse(localStorage.getItem("instaData"));
    let token = instaData && atob(instaData.token);
    let notExpire = instaData && new Date() < new Date(instaData.to);
    if (instaData && token && notExpire) {
      setLoading(true);
      let data = await getInstaMedia(token);
      setInstaImgs([...data]);
      modalClose();
      setShowInstaImagesModel(true);
      setLoading(false);
    } else {
      getInstaAuthorization();
    }
  };
  const instagramAuthHandler = async () => {
    let instaData = JSON.parse(localStorage.getItem("instaData"));
    let token = instaData && window.atob(instaData.token);
    let notExpire = instaData && new Date() < new Date(instaData.to);
    if (instaData && token && notExpire) {
      setLoading(true);
      console.log(token);
      let data = await getInstaMedia(token);
      setInstaImgs([...data]);
      modalClose();
      setShowInstaImagesModel(true);
      dispatch(instaAuthState(false));
      setLoading(false);
    }
  };
  const saveChangesHandle = async () => {
    if (!name) {
      // warningMessage("Please select your album name first", 5);
      setAlertInfo({
        show: true,
        title: "Project Name Missing!",
        msg: "Please name your PicSwagger Project to proceed.",
      });
    } else if (imgs.length < flag) {
      // warningMessage(
      //   "Please uploads " +
      //     (flag - imgs.length) +
      //     " more photos to complete your PicSwagger!",
      //   8
      // );
      setAlertInfo({
        show: true,
        title: "Upload more photos!",
        msg:
          "Please upload upto" +
          (flag - imgs.length) +
          " photos to complete your PicSwagger!",
      });
    } else {
      try {
        setLoading(true);
        let data = {
          size: select.size,
          pages: select.page,
          albumImages: imgs,
          albumName: name,
          status: "approve",
          currentIndex: 2,
          productPrice: productPrice,
        };
        if (orderId) {
          // let res = updateOrder(orderId, data);
          dispatch(savePhoto(imgs?.sort((a, b) => a.id - b.id)));
          dispatch(next());
        } else {
          dispatch(savePhoto(imgs?.sort((a, b) => a.id - b.id)));
          dispatch(next());
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  function dropHandler(ev) {
    console.log("File(s) dropped");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    // ev.stopPropagation();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      let newArr = [];
      let number = 0;
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them

        if (item.kind === "file") {
          const file = item.getAsFile();
          if (
            file.type === "image/png" ||
            file.type === "image/jpg"
            // file.type === "image/jpeg"
          ) {
            number = number + 1;
            console.log(`… file[${i}].name = ${file.name}`);
            let data = {
              id: imgs.length,
              file: file,
              url: URL.createObjectURL(file),
            };
            newArr.push(data);
            if (number === newArr.length) {
              setImgs([...newArr]);
              setdragging(false);
            }
          }
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        console.log(`… file[${i}].name = ${file.name}`);
      });
    }
  }
  function dragOverHandler(ev) {
    ev.preventDefault();
  }
  useEffect(() => {
    setAlbumName(name);
    if (instaState) {
      instagramAuthHandler();
    }
  }, [instaState]);

  return (
    <>
      {loading ? <FullPageLoading /> : ""}
      {progressLoading ? <ProgressLoader value={progress} /> : ""}
      <InfoAlert
        title={alertInfo?.title}
        msg={alertInfo.msg}
        setShowInfoAlert={(value) =>
          setAlertInfo({ ...alertInfo, show: value })
        }
        showInfoAlert={alertInfo?.show}
      />
      <FacebookImagesModal
        showModal={showFbImagesModel}
        setShowModal={setShowFbImagesModel}
        fimgs={fimgs}
        setImgs={setImgs}
      />
      <InstagramImagesModal
        showModal={showInstaImagesModel}
        setShowModal={setShowInstaImagesModel}
        fimgs={instaImgs}
        setImgs={setImgs}
      />
      {showModel && (
        <Modal
          open={showModel}
          footer={null}
          closable={false}
          onCancel={modalClose}
        >
          <div className="modal-x">
            <div className="close-x" onClick={modalClose}>
              X
            </div>
            <div className="icon-div">
              {isFb ? (
                <AiOutlineFacebook className="icon" />
              ) : (
                <AiOutlineInstagram className="icon" />
              )}
            </div>
            <div className="content-x">
              {isFb ? (
                <h6>Import your Design/Photos from Facebook</h6>
              ) : (
                <h6>Import your Design/Photos from Instagram</h6>
              )}
              <p>
                <b>
                  Follow these instructions to import your design / photos from{" "}
                  {isFb ? "Facebook" : "Instagram"}
                </b>
              </p>
              <p>Step 1: Connect your account Make this a button</p>
              <p>Step 2: Authorize Access</p>
              <p>Step 3: Select Photos to Use</p>
              <p>Step 4: Click Done with Finished</p>
              <button
                onClick={() => {
                  // alert('Comming Soon...')
                  if (isFb) {
                    facebookHandler();
                  } else {
                    instagramHandler();
                  }
                }}
                className="btn-done"
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      )}
      {isCanva && (
        <Modal
          open={isCanva}
          footer={null}
          closable={false}
          onCancel={modalClose}
          width="700px"
          style={{
            top: 20,
          }}
        >
          <div className="modal-x">
            <div className="close-x" onClick={modalClose}>
              X
            </div>
            <div className="icon-div">
              <SiCanva className="icon-canva" />
            </div>
            <div className="content-x">
              <h4>Steps to import your Design/Photos from Canva:</h4>
              <b>
                {/* <p style={{ fontSize:'17px'}}>Steps to import your Design/Photos from Canva:</p> */}
              </b>
              <ol>
                {/* <li>
                  <a
                    target={"_blank"}
                    href="https://player.vimeo.com/video/787103941?h=dbc70c15c3"
                  >
                    Click to view tutorial
                  </a>
                </li> */}
                <li>
                  <a
                    href={
                      flag == 16
                        ? "https://bit.ly/3TohaqI"
                        : flag == 20
                        ? "https://bit.ly/3VRZg1a"
                        : "https://bit.ly/3VR887i"
                    }
                    target="_blank"
                  >
                    Start by using this Canva starter template in Canva.
                    {/* Canva Template for Your Desired Design */}
                  </a>

                  {/* Start by using this Canva starter template in Canva */}
                  {/* bit.ly/1234 (free account required) */}
                </li>
                <li>
                  Replace each image on pages of the starter template with your
                  photos/designs.{" "}
                  <a
                    target={"_blank"}
                    href="https://player.vimeo.com/video/787103941?h=dbc70c15c3"
                  >
                    (watch a quick how-to tutorial)
                  </a>
                </li>
                <li>
                  When your design is complete, click the Share button in the
                  upper-right hand corner, then Click Download. <br />
                  <div className="special-downloads">
                    <img src={share} className="img-share" alt="" />
                    <img src={download2} className="img-download2" alt="" />
                    {/* <img src={pdfprint} className="img-pdfprint" /> */}
                    {/* <img src={download} className="img-download" /> */}
                  </div>
                  Select File Type: PDF Print and Flatten PDF options. Now
                  download your design PDF to your desktop/device.
                </li>
                <div className="special-downloads">
                  {/* <img src={share} className="img-share" /> */}
                  {/* <img src={download2} className="img-download2" /> */}
                  <img src={pdfprint} className="img-pdfprint" alt="" />
                  <img src={download} className="img-download" alt="" />
                </div>
                <li>
                  Click the Import Photos button below and select your
                  downloaded PDF to import.
                </li>

                <div>
                  <label>
                    <div className="btn-done2">Import Photos</div>
                    <div className="upload_imges_input_div">
                      <input
                        // style={{ display: "none" }}
                        accept="application/pdf"
                        type={"file"}
                        onClick={(e) => (e.target.value = "")}
                        onChange={(e) => {
                          let url = URL.createObjectURL(e.target.files[0]);
                          pdfConvertor(url);
                          modalClose();
                        }}
                      />
                    </div>
                  </label>
                </div>
              </ol>
            </div>
          </div>
        </Modal>
      )}

      {isDel.flag && (
        <Modal
          open={isDel.flag}
          footer={null}
          closable={false}
          onCancel={modalClose}
          style={{
            width: "300px",
          }}
        >
          <div className="modal-x">
            <div className="close-x" onClick={modalClose}>
              X
            </div>
            <div className="content-del">
              <b>
                <p>Are you sure you want to delete this photo?</p>
              </b>
              <div className="btns-del">
                <button className="btn-no" onClick={modalClose}>
                  No
                </button>
                <button
                  className="btn-yes"
                  onClick={() => deleteImgs(isDel.file)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div
        className={`add-photo-x ${dragging ? "overlayDragging" : ""}`}
        onDragEnter={(e) => {
          e.preventDefault();
          setdragging(true);
          dropRef.current.focus();
          dropRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      >
        <h5 className="fw-bold mt-4">Name your PicSwagger</h5>
        <div className="name-x">
          <input
            type="text"
            placeholder="Enter your project name"
            onChange={(e) => setAlbumName(e.target.value)}
            value={albumName}
          />
          <Tooltip
            color="black"
            placement="top"
            title={
              albumName !== "" && name !== "" ? "saved" : "enter unique name"
            }
            trigger={"click"}
          >
            <button
              onClick={() => {
                if (albumName !== "") {
                  if (
                    userOrders.some(
                      (v) => v.albumName === albumName && v._id !== orderId
                    )
                  ) {
                    alert("Please enter unique name this name already exist");
                  } else {
                    dispatch(saveName(albumName));
                  }
                }
              }}
            >
              <Space>
                <span>Save</span>
                {albumName !== "" && albumName === name && (
                  <MdCheckCircle className="icon" />
                )}
              </Space>
            </button>
          </Tooltip>
        </div>
        <h5 className="fw-bold mt-3">Uploads Your Photos!</h5>
        <div className="upload-x">
          <label>
            <div className="item-x">
              <div className="circle">
                <AiOutlinePlus className="icon" />
              </div>
              <h6>Upload From Desktop/Device</h6>
            </div>
            <div className="upload_imges_input_div">
              <input
                id="imgs_uploader"
                // style={{ display: "none" }}
                multiple={true}
                type={"file"}
                accept=".png,.jpg"
                onClick={(e) => (e.target.value = "")}
                onChange={addImgs}
              />
            </div>
          </label>
          {/* <Upload
            showUploadList={false}
            multiple={true}
            onChange={addImgs}
            fileList={imgs}
            accept={"image/*"}
          >
            <div className="item-x">
              <div className="circle">
                <AiOutlinePlus className="icon" />
              </div>
              <h6>Upload From Desktop</h6>
            </div>
          </Upload> */}
          <div className="item-x">
            <div className="circle" onClick={() => setIsCanva(true)}>
              <SiCanva className="icon-canva" />
            </div>
            <h6>Import From Canva</h6>
          </div>
          <div className="item-x">
            <div
              className="circle"
              onClick={() => {
                setAlertInfo({
                  show: true,
                  msg: "Instagram Sdk under process",
                  title: "Coming Soon...",
                });
                // setShowModel(true);
              }}
            >
              <AiOutlineInstagram className="icon" />
            </div>
            <h6>Import From Instagram</h6>
          </div>
          <div className="item-x">
            <div
              className="circle"
              onClick={() => {
                setAlertInfo({
                  show: true,
                  msg: "Facebook Sdk under process",
                  title: "Coming Soon...",
                });
                // setShowModel(true);
                // setIsFb(true);
              }}
            >
              <AiOutlineFacebook className="icon" />
            </div>
            <h6>Import From Facebook</h6>
          </div>
        </div>

        <h5 className="fw-bold mt-3">
          Click and Drag any Photo to another Photo to Swap Positions.
        </h5>
        {imgs?.length > 0 && (
          <h5 className="fw-bold mt-1">
            Note: Please verify all auto-cropped (non-square) photos look as
            desired.
          </h5>
        )}
        <div>
          {imgs?.length < flag && (
            <div
              id="drop_area_div"
              ref={dropRef}
              className={`empty-x ${dragging && "dragging"}`}
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
              onClick={() => {
                document.getElementById("imgs_uploader").click();
              }}
              // onDragEnterCapture={(e) => {
              //   let ele = document.getElementById("imgs_drag_div")
              // }}
            >
              <img height={70} src={emptypic} alt="" />
              <p>
                Click or Drag images to this area to upload!{" "}
                {imgs.length > 0 && `${imgs.length}/${flag}`}
              </p>
            </div>
          )}
          {imgs.length > 0 ? (
            <div
              className={`uploaded-x ${
                flag == 16
                  ? "imgs-pack-16"
                  : flag == 20
                  ? "imgs-pack-20"
                  : "imgs-pack-24"
              }`}
            >
              <div
                className="img-count"
                style={{
                  fontSize: `${
                    flag == 24 ? "18px" : flag == 20 ? "20px" : "17px"
                  }`,
                }}
              >
                <MdCheckCircle className="icon" />
                <b>{imgs.length} Photos</b> Have Been Uploaded!
                {flag - imgs.length > 0 ? (
                  <span className="img-count-error">
                    Upload {flag - imgs.length} more photos to complete your
                    Picswagger.
                  </span>
                ) : (
                  <></>
                )}
              </div>

              {flag == 24 ? (
                <Imgs24Pair imgs={imgs} setIsDel={setIsDel} setImgs={setImgs} />
              ) : flag == 20 ? (
                <Imgs20Pair imgs={imgs} setIsDel={setIsDel} setImgs={setImgs} />
              ) : (
                <Imgs16Pair imgs={imgs} setIsDel={setIsDel} setImgs={setImgs} />
              )}
            </div>
          ) : (
            ""
            // <div className="empty-x">
            //   <img src={emptypic} />
            //   <p style={{ color: "gray", fontSize: "17px" }}>
            //     No Photos Uploaded Yet
            //   </p>
            // </div>
          )}
          <div className="empty-y">
            <img height={70} src={emptypic} alt="" />
            <p>NO PHOTO UPLOAD YET!</p>
          </div>
        </div>
      </div>
      <div className="steps-action">
        <div className="btn-approves">
          {/* <button className="btn-save" onClick={saveChangesHandle}>
            SAVE CHANGES
          </button> */}
          <button
            className="btn-save"
            onClick={() => {
              let sData = JSON.parse(localStorage.getItem("data"));
              if (sData?.data?._id) {
                if (isViewOnly) {
                  dispatch(next());
                } else {
                  saveChangesHandle();
                }
              } else {
                setAlertInfo({
                  show: true,
                  title: "Signin first!",
                  msg: (
                    <p>
                      Please sign-in to proceed.
                      <Link style={{ color: "black" }} to={"/signin"}>
                        <u> Click here to sign-in or to create an account.</u>
                      </Link>
                    </p>
                  ),
                });
              }
            }}
          >
            NEXT: APPROVE PICSWAGGER
          </button>
        </div>
      </div>
    </>
  );
};
export default memo(AddPhoto);
