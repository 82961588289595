import { Row, Col, Popconfirm, Empty } from "antd";
import React, { useState,useEffect } from "react";
import { BiEdit, BiDuplicate } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
//modal
import EditAddressModal from "../../modals/editAddress/editAddressModal";
//hooks
import useUserAddresses from "../../hooks/useUserAdresses";
import useAllPayments from "../../hooks/useAllPayments";
import { deleteAddress } from "../../../APIS/DatabaseApis";
import FullPageLoading from "../../Loading/FullPageLoading";

function PaymentsRecored() {
  const [loading, setloading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [address, setaddress] = useState({});
  const [updateTrigger, setupdateTrigger] = useState(false);
    const { addresses } = useUserAddresses(updateTrigger);
    const { allPayments } = useAllPayments();
  const navigate = useNavigate();

  const handleDelete = async (item) => {
    try {
      setloading(true);
      await deleteAddress(item?._id, item);
      setupdateTrigger(!updateTrigger);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };
   useEffect(() => {
     if (allPayments.length > 0) {
       setloading(false);
     }
     setTimeout(() => {
       setloading(false);
     }, 10000);
   }, [JSON.stringify(allPayments)]);

  return (
    <div className="recipients-x">
      <div className="header">
        <h5>Payment Recoreds</h5>
        {/* <button
          onClick={() => navigate("/account/recipients-address/new-address")}
        >
          Add New Address
        </button> */}
      </div>
      <div className="addresses">
        <Row>
          <Col xs={8} sm={8} lg={8}>
            <b>ID</b>
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <b>Amount</b>
          </Col>
          <Col xs={6} sm={6} lg={6}>
            <b>Date</b>
          </Col>
          <Col xs={6} sm={6} lg={6}>
            <b>Status</b>
          </Col>
        </Row>
        {loading ? (
          <FullPageLoading />
        ) : allPayments.length > 0 ? (
          ""
        ) : (
          <Empty />
        )}
        {allPayments?.map((item, indx) => {
          return (
            <Row className="py-3 border-bottom">
              <Col xs={8} sm={8} lg={8}>
                {item?.id}
              </Col>
              {/* <Col xs={12} sm={12} lg={12}>
                {item?.address}, {item?.city}, {item?.state}, {item?.zip}{" "}
                {item?.country}
              </Col> */}
              <Col xs={4} sm={4} lg={4}>
                ${item?.amount / 100}
              </Col>
              <Col xs={6} sm={6} lg={6}>
                {new Date(item?.created * 1000).toLocaleString()}
              </Col>
              <Col xs={6} sm={6} lg={6}>
                {item?.status}
              </Col>
              {/* <Col xs={5} sm={5} lg={5}>
                <div className="actions-x">
                  <div
                    onClick={() => {
                      setaddress(item);
                      setShowModal(true);
                    }}
                    className="action"
                  >
                    <BiEdit className="icon" />
                    <span>Edit</span>
                  </div>
                  <Popconfirm
                    title={"Are you Sure?"}
                    onConfirm={() => {
                      handleDelete(item);
                    }}
                  >
                    <div className="action">
                      <RiDeleteBinLine className="icon" />
                      <span>Delete</span>
                    </div>
                  </Popconfirm>
                </div>
              </Col> */}
            </Row>
          );
        })}
      </div>
    </div>
  );
}

export default PaymentsRecored;
