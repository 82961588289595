import React from "react";
import "./style.css";
// import girlready from "../../../assets/home/girlready.png";
import { Link } from "react-router-dom";
const readyswaggerpic =
  "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FpicswaggerImages%2Ftime_to_rock_n_roll_graphic.png?alt=media&token=2a306617-aa4f-4ced-a723-9e043fd3878d";
export const ReadyPicSwagger = () => {
  return (
    <>
      <div class="mainReadyPicSwaggerDiv">
        <div className="container">
          <div class="mainReadyPicSwaggerSubDiv row">
            <div className="ReadyPicSwaggerHeading col-sm-5 col-md-5 col-lg-5">
              <h1 className="ready_heading">
                It's time to <br /> Rock and Roll...{" "}
              </h1>

              {/* <p>
                Maintaining a focus on meeting the needs of the present without
                compromising the needs of future generations.{" "}
              </p> */}
              <button className="btn ReadyPicSwaggerButton fw-bold mb-5 mt-2">
                <i className="fa fa-shopping-cart me-2"></i>
                <Link className="ready_link" to={"/orders"}>
                  Order Now
                </Link>
              </button>
            </div>
            <div className="ReadyPicSwaggerImg col-sm-12 col-md-7 col-lg-7">
              <img className="ready-pic" src={readyswaggerpic} alt="Snow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
