import React from "react";
import { Modal, Button } from "antd";
import "./info.scss";
import loginLogo from "../../../assets/login/loginLogo.png";
function InfoAlert({ title, msg, showInfoAlert, setShowInfoAlert }) {
  const handleCancel = () => {
    setShowInfoAlert(false);
  };
  return (
    <Modal
      title=""
      open={showInfoAlert}
      onOk={handleCancel}
      onCancel={handleCancel}
      destroyOnClose
      footer={null}
      bodyStyle={{ backgroundColor: "#f3b5c0" }}
    >
      <div className="info-alert-x ">
        <img src={loginLogo} />
        <p className="title">{title}</p>
        <p className="msg">{msg}</p>

        <Button
          type="primary"
          style={{
            borderRadius: "10px",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={handleCancel}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default InfoAlert;
