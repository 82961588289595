import React from 'react'
import "./cover_fram.style.css";
export default function CoverFramSmall({ coverImg, albumImg }) {
  return (
    <div className="main_cover_fram_div">
      <div className="album_cover_div_small">
        <div className="album_div_small">
          <img
            className="cover_img_small"
            src={coverImg}
            onClick={() => window.open(coverImg)}
          />
        </div>
        <div className="user_img_div_small">
          <img
            className="user_img_small"
            src={albumImg}
            onClick={() => window.open(albumImg)}
          />
        </div>
      </div>
    </div>
  );
}
