import { Row, Col, Popconfirm } from "antd";
import React, { useState } from "react";
import { BiEdit, BiDuplicate } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import "./recipients.scss";
//modal
import EditAddressModal from "../../modals/editAddress/editAddressModal";
//hooks
import useUserAddresses from "../../hooks/useUserAdresses";
import { deleteAddress } from "../../../APIS/DatabaseApis";
import FullPageLoading from "../../Loading/FullPageLoading";
function RecipientsAddress() {
  const [loading, setloading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [address, setaddress] = useState({});
  const [updateTrigger, setupdateTrigger] = useState(false);
  const { addresses } = useUserAddresses(updateTrigger);
  const navigate = useNavigate();

  const handleDelete = async (item) => {
    try {
      setloading(true);
         await deleteAddress(item?._id, item);
      setupdateTrigger(!updateTrigger);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
 
  };
  return (
    <div className="recipients-x">
      {loading?<FullPageLoading/>:""}
      <EditAddressModal
        showModal={showModal}
        setShowModal={setShowModal}
        setupdateTrigger={setupdateTrigger}
        address={address}
      />
      <div className="header">
        <h5>RECIPIENTS ADDRESS</h5>
        <button
          onClick={() => navigate("/account/recipients-address/new-address")}
        >
          Add New Address
        </button>
      </div>
      <div className="addresses">
        <Row>
          <Col xs={7} sm={7} lg={7}>
            <b>Name</b>
          </Col>
          <Col xs={12} sm={12} lg={12}>
            <b>Address</b>
          </Col>
          <Col xs={5} sm={5} lg={5}>
            <b>Actions</b>
          </Col>
        </Row>
        {addresses?.map((item, indx) => {
          return (
            <Row className="py-3 border-bottom">
              <Col xs={7} sm={7} lg={7}>
                {item?.name}
              </Col>
              <Col xs={12} sm={12} lg={12}>
                {item?.address}, {item?.city}, {item?.state}, {item?.zip}{" "}
                {item?.country}
              </Col>
              <Col xs={5} sm={5} lg={5}>
                <div className="actions-x">
                  <div
                    onClick={() => {
                      setaddress(item);
                      setShowModal(true);
                    }}
                    className="action"
                  >
                    <BiEdit className="icon" />
                    <span>Edit</span>
                  </div>
                  <Popconfirm
                    title={"Are you Sure?"}
                    onConfirm={() => {
                      handleDelete(item);
                    }}
                  >
                    <div className="action">
                      <RiDeleteBinLine className="icon" />
                      <span>Delete</span>
                    </div>
                  </Popconfirm>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}

export default RecipientsAddress;
