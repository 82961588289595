import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/gothic/Century-Gothic.ttf";
import { store } from "./redux/store";
import { Provider } from "react-redux";
// import OrderSuccess from "./components/Order/ordersuccess/OrderSuccess";
// import { Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

// Router.onBeforeAction(function () {
//     if (Headers.get("x-forwarded-host") !== "localhost:3000") {
//       if (Headers.get("x-forwarded-proto") === "http") {
//         window.location = window.location.href.replace("http", "https");
//       }
//     }
// });
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </BrowserRouter> */}
  </React.StrictMode>
);
