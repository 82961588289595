import React from "react";
import { Link } from "react-router-dom";
import { accordion } from "./data";
export const Accordion = () => {
  return (
    <>
      {accordion?.map((record, index) => (
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseOne${index}`}
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <b>{record.title}</b>
              </button>
            </h2>
            <div
              id={`collapseOne${index}`}
              class={`accordion-collapse collapse ${index == 0 ? "show" : ""}`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {record.description}
                {record?.link && record?.link}
              </div>
              {record?.video && (
                <div className="video_div">
                  <iframe
                    width={"100%"}
                    height={400}
                    src={record.video}
                    allow="fullscreen"
                    allowFullScreen
                    frameborder="0"
                  ></iframe>
                  {/* <iframe
                    // src="https://player.vimeo.com/video/330769192"
                    src={record.video}
                    width="640"
                    height="360"
                    // frameborder="0"
                    allow="fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe> */}
                  {/* <video playsInline width={"100%"} height={400} controls={"controls"}>
                    <source src={record.video} type={"video/webm"} />
                  </video> */}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
