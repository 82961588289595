import { Row, Col, Select, Dropdown, Space, message } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  next,
  savePhoto,
  setFilePath,
  setOrderId,
  setOrderNumber,
} from "../../../../../redux/slices/orderSlice";
import "./approve.scss";
import { TbArrowBackUp } from "react-icons/tb";
import { DownOutlined } from "@ant-design/icons";
import {
  saveImgsToDb,
  updateOrder,
  addNewOrder,
  ImgsToPdf,
} from "../../../../../APIS/DatabaseApis";
import FullPageLoading from "../../../../Loading/FullPageLoading";
import { ImagesUrls } from "./data";
import ProgressLoader from "../../../../Loading/ProgressLoader";
import ProgressBarLoading from "../../../../Loading/ProgressBarLoading";
import cloneDeep from "lodash.clonedeep";
const { Option } = Select;
function Approve() {
  const dispatch = useDispatch();
  const { photos, select, orderId, isViewOnly, name, productPrice,filePath,orderNumber } =
    useSelector((state) => state.orders);
  let flag = select.page;
  const [imgToggle, setImgToggle] = useState(true);
  const [toggleIndex, settoggleIndex] = useState(0);
  const [background, setBackground] = useState({
    img: "Floating",
    title: "Floating",
  });

  const [drag, setdrag] = useState({});
  const [loading, setLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  let imgs = [...photos];
  let size = select.size.includes("Mini") ? 4 : 8;
  const setImgs = (arr, from) => {
    let newArr = arr.map((v, i) => {
      return {
        ...v,
        id: i,
      };
    });
    dispatch(savePhoto(newArr));
  };
  const DragImg = (file, index) => {
    setdrag({ index, file });
  };
  const DropImg = (file, index) => {
     let arr = cloneDeep(imgs);
    arr[index] = { ...drag.file };
    arr[drag.index] = { ...file };
    setImgs([...arr], "swap");
  };

  const handleSaveChange = async () => {
    try {
      setProgress(0);
      setProgressLoading(true);
     
      let sdata = JSON.parse(localStorage.getItem("data"));
      let userId = sdata?.data?._id;
      let path = `albumImages/${userId}/${name.replace(/\s/g, "")}`;
      let d = orderNumber ? orderNumber: Math.floor(Math.random() * 9000000) + 1000000;
      dispatch(setOrderNumber(d));
      let dbImgs = await saveImgsToDb([...imgs].slice(0, flag), path, setProgress);
       let pdfUrl =
         filePath && imgs.every((v) => v.uploadedAt)
           ? filePath
           :await ImgsToPdf([...dbImgs].slice(0, flag), d, (url) =>
               dispatch(setFilePath(url))
             );
      let data = {
        size: select.size,
        pages: select.page,
        albumImages: dbImgs,
        albumName: name,
        status: "approve",
        currentIndex: 3,
        userId,
        productPrice: productPrice,
        orderNumber:d,
        filePath:pdfUrl
      };
      if (orderId) {
        let order = await updateOrder(orderId, data);
        dispatch(savePhoto(dbImgs?.sort((a, b) => a.id - b.id)));
        
        setProgressLoading(false);
        //  message.success('images upload successfull!')
        dispatch(next());
      } else {
        let res = await addNewOrder(data);
        dispatch(savePhoto(dbImgs?.sort((a, b) => a.id - b.id)));
        dispatch(setOrderId(res?._id));
        dispatch(next());
        // message.success('images upload successfull!')
        setProgressLoading(false);
      }
    } catch (error) {
      console.log(error);
      setProgressLoading(false);
    }
  };

  return (
    <div className="approve-x">
      {loading ? <FullPageLoading /> : ""}
      {progressLoading ? <ProgressBarLoading value={progress} /> : ""}
      <div className="preview-x">
        <h5>
          Preview and Approve
        </h5>
      
        <div
          className="img-x"
          style={{
            backgroundImage: `url("${ImagesUrls[background?.img]}")`,
            // backgroundImage: `url("/assets/approve/${background?.img}")`,
          }}
        >
          <div
            className={`cover_img_div ${
              background?.title === "Floating" && "floating_cover"
            }
             
            `}
            //  ${background?.title === "Kitchen" && "kitchen_cover"}
            style={{ bottom: size == 4 ? "13%" : "24%" }}
          >
            <div className={`imgs_div`}>
              {imgs
                ?.sort((a, b) => a.id - b.id)
                ?.slice(toggleIndex, flag / 2 + toggleIndex)
                .map((v, i) => {
                  return (
                    <div
                      style={{
                        transform:
                          i % 2 === 0 ? " skewY(-8deg)" : "skewY(8deg)",
                        width:
                          size == 4
                            ? background?.title === "Floating"
                              ? "90px"
                              : "60px"
                            : "90px",
                        height:
                          size == 4
                            ? background?.title === "Floating"
                              ? "90px"
                              : "60px"
                            : "90px",
                      }}
                      className="img_div"
                    >
                      <img src={v?.url} onClick={() => window.open(v?.url)} />
                    </div>
                  );
                })}
            </div>
          </div>

          {/* <img src={photos[toggleIndex]?.url} /> */}

          {imgToggle ? (
            <button
              className="img-toogle"
              onClick={() => {
                settoggleIndex(flag / 2);
                setImgToggle(!imgToggle);
              }}
            >
              <TbArrowBackUp className="icon" />
              <span>{imgToggle ? "Back Side" : "Front Side"}</span>
            </button>
          ) : (
            <button
              className="img-toogle"
              onClick={() => {
                settoggleIndex(0);
                setImgToggle(!imgToggle);
              }}
            >
              <TbArrowBackUp className="icon" />
              <span>{imgToggle ? "Back Side" : "Front Side"}</span>
            </button>
          )}
        </div>
        <h4>{imgToggle ? "Front Side" : "Back Side"}</h4>
        <button
          className="approve-btn"
          onClick={() => {
            if (isViewOnly) {
              dispatch(next());
            } else {
              handleSaveChange();
            }
          }}
        >
          APPROVE PICSWAGGER
        </button>
      </div>
      <div className="side-x">
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder={"Choose Background"}
            defaultValue={background?.title}
            onChange={(val) => {
              setBackground({ img: val, title: val });
              // if (val === "Floating") {
              //   setBackground({ img: "white-wall-backgroud.jpg", title: val });
              // } else {
              //   let data = {
              //     img:
              //       select.size.slice(0, 4) === "Mini"
              //         ? `${val}-scene-4-${flag / 2}.png`
              //         : `${val}-scene-8-${flag / 2}.png`,
              //     title: val,
              //   };

              // }
              // setBackground(data);
            }}
          >
            <Option value={"Floating"}>{"Floating"}</Option>
            <Option value={"Kitchen"}>{"Kitchen Island"}</Option>
            <Option value={"Wall"}>{"Wall Shelf"}</Option>
            {/* <Option value={"Living-Space"}>{"Living Room"}</Option> */}
          </Select>
        </div>
        <p className="p-x">Front Side Photos</p>
        <div
          className="fnt-grid"
          style={{
            gridTemplateColumns: `repeat(${flag / 4}, minmax(50px, 25%))`,
          }}
        >
          {photos?.slice(0, flag / 2).map((file, index) => {
            return (
              <div
                onTouchMove={() => DropImg(file, file?.id)}
                onDragOver={(event) => event.preventDefault()}
                onDrop={() => DropImg(file, file?.id)}
              >
                <img
                  draggable={true}
                  onDragStart={() => {
                    DragImg(file, file?.id);
                  }}
                  onTouchStart={() => {
                    DragImg(file, file?.id);
                  }}
                  src={file?.url}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <p className="p-x">Back Side Photos</p>
        <div
          className="fnt-grid"
          style={{
            gridTemplateColumns: `repeat(${flag / 4}, minmax(50px, 25%))`,
          }}
        >
          {photos?.slice(flag / 2, flag).map((file, index) => {
            return (
              <div
                onDragOver={(event) => event.preventDefault()}
                onDrop={() => DropImg(file, file?.id)}
              >
                <img
                  draggable={true}
                  onDragStart={() => {
                    DragImg(file, file?.id);
                  }}
                  src={file?.url}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <div>
          <p className="p-x">
            Click and Drag any Photo to another Photo to Swap Positions
          </p>
        </div>
      </div>
    </div>
  );
}

export default Approve;

