
  let sdata = JSON.parse(localStorage.getItem("data"));
  let userId = sdata?.data._id;

export const footerItem = [
  {
    title: "About",
    items: [
      {
        item: "About Us",
        link: "/about",
      },
      // {
      //   item: "Our Company",
      //   link: "/about",
      // },
      // {
      //   item: "How its work",
      //   link: "/home",
      // },

      {
        item: "FAQ",
        link: "/faqs",
      },
      // {
      //   item: "Blog",

      // },
    ],
  },

  {
    title: "Important Links",
    items: [
      {
        item: "Refund Policy",
        link: "/refundPolicy",
      },
      {
        item: "Privacy Policy",
        link: "/privacyPolicy",
      },
      {
        item: "Terms&Conditions",
        link: "/termsConditions",
      },
      {
        item: "Contact Us",
        link: "mailto:support@picswagger.com",
      },

      // {
      //   item: "Sitemap",
      // },
      // {
      //   item: "Shipping Policy",
      // },
    ],
  },

  {
    title: "My Accounts",
    items: [
      {
        item: "Login",
        link: userId ? "/home" : "/signin",
      },
      {
        item: "Sign UP",
        link: userId ? "/" : "/signup",
      },
      // {
      //   item: "Orders",
      //   link: "orders",
      // },

      {
        item: "My Account",
        link: userId ? "/account/my-account" : "/signin",
      },
      // {
      //   item: "Shipping",
      // },
    ],
  },
];
