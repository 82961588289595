import React from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
function PrivacyPolicy() {
  return (
    <div className="faq_main_div container">
      <Header />

      <h2>PicSwagger Return Policy</h2>

      <h2>Return / Refund policy</h2>
      <h5>
        It is our goal at PicSwagger to make quality print and photo products
        our customers love!
      </h5>

      <h5>
        We fulfill printing and photo product orders automatically from our
        website, therefore, no one manually reviews or verifies the accuracy,
        order or visual correctness of your order prior to it being printed and
        fulfilled. Your order will print exactly as the information was entered
        and approved by you on our website. We are not responsible for any
        incomplete or inaccurate information or for any mistakes or typos
        associated with your order
      </h5>
      <h5>
        If you make a mistake on your order or are otherwise dissatisfied,
        please contact us as soon as possible. We will try to help you in any
        way that we can. Again, your satisfaction is our ultimate goal. The best
        way to reach us is at
        <a href="mailto:E-mail:support@picswagger.com" target="_blank">
          support@picswagger.com
        </a>
      </h5>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
