import React from "react";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
import { Input } from "antd";
import { BsCheck } from "react-icons/bs";
import { GoGift } from "react-icons/go";
import { RiMailLine } from "react-icons/ri";

import { Button } from "antd";

import "./index.scss";

const GiftCard = () => {
  // const [cardactive, setCardactive] = useState("1")
  // const halndleClick = (val) => {
  //     setCardactive(val)
  // }

  const card = [
    {
      id: 1,
      title: "3 Tiles",
      amount: "$45",
    },
    {
      id: 2,
      title: "6 Tiles",
      amount: "$90",
    },
    {
      id: 3,
      title: "8 Tiles",
      amount: "$120",
    },
    {
      id: 4,
      title: "12 Tiles",
      amount: "$180",
    },
    {
      id: 5,
      title: "16 Tiles",
      amount: "$240",
    },
    {
      id: 6,
      title: "20 Tiles",
      amount: "$300",
    },
  ];

  return (
    <PublicLayout>
      <div className="giftCards">
        <div className="mixtile-flex pt-5">
          <div className="empty-div"> </div>
          <div className="img-mixtile">
            <div className="mixtile-bg-img">
              <div>
                <p className="mixtile-img-tiles-text pt-5">PicSwagger</p>
              </div>
              <div className="mixtile-text-top-padding">
                <h1 className="mixtile-img-tiles-text">3 Tiles</h1>
                <p className="mixtile-gift-img-card">Gift Card</p>
              </div>
            </div>
            <p className="mt-3 mixtile-deliver-text">Delivered by email</p>
          </div>
          <div className="content-mixtile">
            <div className="d-flex  flex-column">
              <p className="mixtile-gift-card">PicSwagger Gift Card</p>
              <p className="mixtile-text-gift">Who is the Gift for</p>
              <Input placeholder="Email" type="email" className="input" />
              <p className="mixtile-text-gift pt-2">Select number of tiles</p>
              <p className="mixtile-text-note">
                Note:Gift card tiles are always 8"x8".
              </p>
            </div>
            <div className="d-flex gap-2 w-95 flex-wrap cursor-p">
              {card.map((card, index) => (
                <div key={index} className="d-flex mixtile-cards flex-column">
                  <p className="mixtile-text-card-p">{card.title}</p>
                  <p className="mixtile-text-card-p-amount">{card.amount}</p>
                </div>
              ))}
            </div>

            <div className="d-flex gap-2 align-items-center mt-3">
              <span className="h4">
                {" "}
                <BsCheck />
              </span>
              <p className="mixtile-text-note">Shipping Included</p>
            </div>
            <div>
              <Button type="primary" className="mixtile-button">
                Buy
              </Button>
            </div>
            <div>
              <p className="mixtile-gift-card">How does it work?</p>
              <div className="d-flex gap-2 align-items-baseline">
                <span className="h4">
                  {" "}
                  <GoGift />{" "}
                </span>
                <p className="mixtile-text-gift">
                  1. Buy the digital gift card
                </p>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <span className="h4">
                  {" "}
                  <RiMailLine />{" "}
                </span>
                <p className="mixtile-text-gift">
                  2. We send it over email to your friend or family member
                </p>
              </div>
              <div className="d-flex gap-2 align-items-baseline">
                <span className="h4">
                  {" "}
                  <GoGift />
                </span>
                <p className="mixtile-text-gift">
                  3. They order Mixtiles and apply the gift code at checkout!
                </p>
              </div>
              <div>
                <p>
                  Any question?{" "}
                  <a className="mixtile-contact-a" href="Contact us">
                    Contact us
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="empty-div"> </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default GiftCard;
