import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./draft.scss";
import gift from "../../../assets/checkout/gift.png";
import { BiEdit, BiDuplicate } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  savePhoto,
  saveName,
  setOrderId,
  setCurrentIndex,
  saveSelect,
  setProductPrice
} from "../../../redux/slices/orderSlice";
import { useDispatch } from "react-redux";
//hooks
import useUserOrders from "../../hooks/useUserOrders";
import { addNewOrder, deleteOrder } from "../../../APIS/DatabaseApis";
import { Popconfirm } from "antd";
import { coverImages } from "../../Order/SelectPicSwagger/Content/ChooseAlbum";
function DraftProject() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateTrigger, setupdateTrigger] = useState(false);
  const { userOrders } = useUserOrders(updateTrigger);
  const [loading, setloading] = useState(false);

  const editHandler = (order) => {
    dispatch(
      saveSelect({
        size: order.size,
        page: order.pages,
      })
    );
    dispatch(savePhoto(JSON.parse(JSON.stringify(order.albumImages))));
    dispatch(saveName(order.albumName));
    dispatch(setOrderId(order._id));
    dispatch(setCurrentIndex(order.currentIndex));
    dispatch(setProductPrice(order.productPrice));

   
    navigate("/orders");
  };
  const duplicateHandle = async (item) => {
    try {
      let nameLength = userOrders?.filter((v) =>
        v.albumName.includes(item.albumName)
      ).length;
      delete item._id;
      let data = { ...item,duplicate:true, albumName: item.albumName + `(${nameLength})` };
      await addNewOrder(data);
      setupdateTrigger(!updateTrigger);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteHandle = async (item) => {
    try {
      await deleteOrder(item?._id, item);
      setupdateTrigger(!updateTrigger);
    } catch (error) {
      console.log(error);
    }

  };
 

  return (
    <div className="draft-x">
      <h5>ORDERS</h5>
      <div className="orders-x">
        {userOrders
          .filter((v) => v.status === "draft")
          ?.map((order, i) => {
            return (
              <div key={i} className="o-card">
                <div className="main-content">
                  <div className="o-img">
                    <img
                      width={150}
                      height={150}
                      src={
                        coverImages.find(
                          (v) =>
                            order?.albumCoverName &&
                            v.name === order?.albumCoverName
                        )?.img || coverImages[0]?.img
                      }
                    />
                  </div>
                  <div className="o-options">
                    <h6>Options</h6>
                    <div className="icons">
                      <div
                        onClick={() => {
                          editHandler(order);
                        }}
                        className="icon-box"
                      >
                        <BiEdit className="icon" />
                        <span>Edit</span>
                      </div>

                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => duplicateHandle(order)}
                      >
                        <div className="icon-box">
                          <BiDuplicate className="icon" />
                          <span>Duplicate</span>
                        </div>
                      </Popconfirm>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => deleteHandle(order)}
                      >
                        <div className="icon-box">
                          <RiDeleteBinLine className="icon" />
                          <span>Delete</span>
                        </div>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
                <p>{`${order?.albumName}/${coverImages[0].name}`}</p>
              </div>
            );
          })}
        {/*  
        <div className="o-card">
          <div className="main-content">
            <div className="o-img">
              <img src={gift} />
            </div>
            <div className="o-options">
              <h6>Options</h6>
              <div className="icons">
                <div className="icon-box">
                  <BiEdit className="icon" />
                  <span>Edit</span>
                </div>
                <div className="icon-box">
                  <BiDuplicate className="icon" />
                  <span>Duplicate</span>
                </div>
                <div className="icon-box">
                  <RiDeleteBinLine className="icon" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </div>
          <p>PicSwagger w/ stock album cover</p>
        </div> */}
      </div>
    </div>
  );
}

export default DraftProject;
