import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "./Accordion";
import "./style.css";

export const Questions = () => {
  return (
    <div className="row question">
      <p style={{ textAlign: "center" }}>
        <h1 className="fw-bold h1-q">FAQs</h1>
      </p>
      <div className="col-sm-12 col-md-4 col-lg-4">
        <h1 className="fw-bold h1-q">Have more questions? </h1>
        <p>
          If you have other questions, please visit our <br />
          <Link to="/faqs">FAQ Page</Link> or{" "}
          <a href="mailto:support@picswagger.com">Send Us Message</a>
        </p>
      </div>
      <div className="col-sm-12 col-md-8 col-lg-8">
        <Accordion />
      </div>
    </div>
  );
};
