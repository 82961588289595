import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { updateUser } from "../../../APIS/DatabaseApis";
import "./myaccount.scss";
const MyAccount = () => {
  const sdata = JSON.parse(localStorage.getItem("data"));
  const onFinish = async (values) => {
    // console.log("Success:", values);
    if (window.confirm("Are you sure?")) {
      await updateUser(values);
      message.success("Successfully Done!");
      setLSData(values);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const setLSData = (user) => {
    let data = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    };
    form.setFieldsValue({ ...data });
  };
  useEffect(() => {
    if (sdata) {
      setLSData(sdata?.data);
    }
  }, [sdata]);

  return (
    <>
      <h5>PROFILE</h5>
      <h6>Account Information</h6>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input your last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <h6>Change Password</h6>
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: false,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: false,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-x">
            SAVE CHANGES
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default MyAccount;
