import { Input, Radio, Space, Button, Upload, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import React, { useState, useEffect } from "react";
import "./style.css";
import ogiftX from "../../../../assets/orders/ogiftX.png";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAlbumCoverName,
  next,
  setCustomAlbumCover,
  setProductPrice,
} from "../../../../redux/slices/orderSlice";
import all_smile from "../../../../assets/cover/all_smile.png";
import good_time from "../../../../assets/cover/good_time.png";
import flower_power from "../../../../assets/cover/flower_power.png";
import modren_geo from "../../../../assets/cover/modren_geo.png";
import stand_out from "../../../../assets/cover/stand_out.png";
import thanks from "../../../../assets/cover/thanks.png";
import greatest_hits from "../../../../assets/cover/Greatest_Hits.png";
import Celebrate_Album from "../../../../assets/cover/Celebrate_Album.png";
import custom from "../../../../assets/cover/custom.jpg";

import {
  backgroundPlaceOrderapi,
  CropImage,
  CustomCoverImgsToPdf,
  sendSuccessMail,
  updateOrder,
  uploadImage,
} from "../../../../APIS/DatabaseApis";
import FullPageLoading from "../../../Loading/FullPageLoading";

export const coverImages = [
  { name: "Thanks for the Memories", img: thanks },
  { name: "Let the Good Times Roll", img: good_time },
  { name: "Modern Geo", img: modren_geo },
  { name: "All Smiles", img: all_smile },
  { name: "Flower Power", img: flower_power },
  { name: "Stand Out", img: stand_out },
  { name: "Greatest Hits", img: greatest_hits },
  { name: "Celebrate", img: Celebrate_Album },
  { name: "Custom Album Cover (+ $3.00)", img: custom },
];
export const ChooseAlbum = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [customCover, setcustomCover] = useState(null);
  const [customCoverBack, setcustomCoverBack] = useState(null);

  const {
    photos,
    albumCoverName,
    orderId,
    currentIndex,
    isViewOnly,
    name,
    customAlbumCover,
    productPrice,
    orderNumber,
  } = useSelector((state) => state.orders);

  // For radio
  const [value, setValue] = useState(0);
  // console.log("values ", value);
  const clickHandler = async () => {
    try {
      setloading(true);
      let data = {
        albumCoverName: coverImages[value].name,
        currentIndex: 4,
        customAlbumCover: customAlbumCover,
        productPrice: productPrice,
      };
      let order = await updateOrder(orderId, data);
      dispatch(saveAlbumCoverName(coverImages[value].name));
      dispatch(next());
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const CustomAlbumCoverUplaod = async () => {
    setloading(true);
    let sdata = JSON.parse(localStorage.getItem("data"));
    let userId = sdata?.data?._id;
    let pathFront = `albumImages/${userId}/${name.replace(
      /\s/g,
      ""
    )}/customCoverFront`;
    let newFileObj = await CropImage(
      customCover,
      URL.createObjectURL(customCover)
    );
    let frontUrl = await uploadImage(newFileObj, pathFront);
    let pathBack = `albumImages/${userId}/${name.replace(
      /\s/g,
      ""
    )}/customCoverBack`;
    let newFileObjBack = await CropImage(
      customCoverBack,
      URL.createObjectURL(customCoverBack)
    );
    let backUrl = await uploadImage(newFileObjBack, pathBack);

    let pdfUrl = await CustomCoverImgsToPdf(
      [
        { id: 1, url: frontUrl },
        { id: 2, url: backUrl },
      ],
      orderNumber,
      (url) => {
        console.log(url);
      },
      `customCoverImagesPdf/${orderNumber}/customCoverFile`
    );
    coverImages[coverImages.length - 1].img = frontUrl;
    dispatch(
      setCustomAlbumCover({
        front: frontUrl,
        back: backUrl,
        pdf: pdfUrl,
        price: 3.0,
      })
    );

    dispatch(setProductPrice(productPrice + 3.0));
    setcustomCover(null);
    setcustomCoverBack(null);
    setloading(false);
  };
  useEffect(() => {
    if (albumCoverName) {
      setValue(coverImages.findIndex((v) => v.name === albumCoverName));
    }
  }, []);

  return (
    <div className="container chooseAlbum">
      {loading ? <FullPageLoading /> : ""}
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="contentLeft">
            <h4 className="fw-bold">Choose Album Cover</h4>
            <Radio.Group
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <Space direction="vertical">
                {coverImages.map((val, index) => {
                  return <Radio value={index}>{val.name}</Radio>;
                })}
              </Space>
            </Radio.Group>
            {coverImages[value]?.name === "Custom Album Cover (+ $3.00)" &&
              !customAlbumCover && (
                <>
                  <p className="mt-1">
                    <b>
                      Click to Choose Front & Back 2(Images) for Custom Album
                      Cover
                    </b>
                  </p>
                  <Row gutter={24}>
                    <Col span={10}>
                      <Upload
                        listType="picture"
                        multiple={false}
                        maxCount={1}
                        accept={"image/*"}
                        onChange={(e) => {
                          if (e.file.status === "removed") {
                            setcustomCover(null);
                          } else {
                            setcustomCover(e?.file);
                          }
                        }}
                        beforeUpload={() => {
                          return false;
                        }}
                        // showUploadList={false}
                      >
                        <Button disabled={isViewOnly}>Choose Front</Button>
                      </Upload>
                    </Col>
                    <Col span={10}>
                      <Upload
                        listType="picture"
                        multiple={false}
                        maxCount={1}
                        accept={"image/*"}
                        onChange={(e) => {
                          if (e.file.status === "removed") {
                            setcustomCoverBack(null);
                          } else {
                            setcustomCoverBack(e?.file);
                          }
                        }}
                        beforeUpload={() => {
                          return false;
                        }}
                        // showUploadList={false}
                      >
                        <Button disabled={isViewOnly}>Choose Back</Button>
                      </Upload>
                    </Col>
                  </Row>
                  <div>
                    {customCover && customCoverBack && (
                      <div>
                        <Button
                          onClick={CustomAlbumCoverUplaod}
                          className="btn-upload-custom-cover d-flex mt-1"
                          icon={<UploadOutlined />}
                        >
                          Upload Custom Cover
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h3 className="fw-bold">Preview Album Cover</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="content">
              <div className="album_cover_div">
                <div className="album_div">
                  <img
                    className="cover_img"
                    src={
                      coverImages[value]?.name === "Custom Album Cover"
                        ? customAlbumCover
                          ? customAlbumCover?.front
                          : coverImages[value]?.img
                        : coverImages[value]?.img
                    }
                    onClick={() => window.open(coverImages[value]?.img)}
                  />
                </div>
                <div className="user_img_div">
                  <img
                    className="user_img"
                    src={photos[0]?.url}
                    onClick={() => window.open(photos[0]?.url)}
                  />
                </div>
              </div>
              {/* <img className="" src={photos[0]?.url} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="steps-action">
        <Button
          className="btn-x"
          onClick={() => {
            if (isViewOnly) {
              dispatch(next());
            } else {
              if (
                coverImages[value]?.name === "Custom Album Cover (+ $3.00)" &&
                !customAlbumCover
              ) {
                message.warn("Please upload custom album cover first!");
              } else {
                if (
                  coverImages[value]?.name !== "Custom Album Cover (+ $3.00)" &&
                  customAlbumCover
                ) {
                  dispatch(setCustomAlbumCover(null));
                  dispatch(setProductPrice(productPrice - 3.0));
                }
                clickHandler();
              }
            }
          }}
        >
          NEXT: ADD SHIP-TO ADDRESS
        </Button>
      </div>
    </div>
  );
};
