import { Progress } from "antd";
import React, { useState, useEffect } from "react";
function ProgressLoader({ value }) {
  const [percent, setPercent] = useState(0);

  const loadingStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  };
  const overlayStyles = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: 99999,
  };
  useEffect(() => {
    setPercent(value);
  }, [value]);
  return (
    <>
      <div style={overlayStyles}></div>
      <div style={loadingStyles}>
        <Progress
          type="circle"
          percent={percent}
          strokeColor={{
            "0%": "#ea6a45",
            "100%": "#87d068",
          }}
          style={{
            marginRight: 8,
            color: "#ea6a45",
          }}
        />
      </div>
    </>
  );
}

export default React.memo(ProgressLoader);
