import { reject } from "lodash";

let baseUrl = "https://picswagger-backend.uc.r.appspot.com/";
// let baseUrl = "http://localhost:5000/";

export function FacebookSdk() {
  return new Promise((resolve) => {
    console.log("facebook sdk");
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      // 1076349573167332
      // 3269323233288362
      // 137074809122528
      window.FB.init({
        appId: "1059841788749942",
        cookie: true,
        xfbml: true,
        status: true,
        version: "v15.0",
      });
      window.FB.XFBML.parse();
      const GetAllImages = async (userId) => {
        await window.FB.api(
          `/me?fields=albums{name,photos{picture,picture_full,images}}`,
          function (response) {
            if (response && !response.error) {
              /* handle the result */
              // console.log(response);
              resolve(response);
            }
          }
        );
      };
      // auto authenticate with the api if already logged in with facebook
      async function login() {
        // login with facebook then authenticate with the API to get a JWT auth token
        await window.FB.login(
          function (response) {
            // handle the response
            let id = response?.authResponse?.userID;
            GetAllImages(id);
          },
          { scope: "user_photos" }
          // {
          //   scope: 'user_photos', // the new selected permissions
          //   auth_type: 'rerequest',
          // }
        );
      }
      async function logout() {
        // login with facebook then authenticate with the API to get a JWT auth token
        window.FB.logout(function (response) {
          // handle the response
          console.log("logout", response);
        });
      }
      // login();
      const GetLoginStatus = async () => {
        await window.FB.getLoginStatus(function (response) {
          if (response.status === "connected") {
            // logout();
            let id = response?.authResponse?.userID;
            GetAllImages(id);
            // logout();
          } else {
            login();
          }
        });
      };
      GetLoginStatus();
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
export const getInstaAuthorization = () => {
  window.location.replace(`${baseUrl}insta/get-auth-code`);
};

export const getInstaAccessToken = async (code) => {
  fetch(`${baseUrl}insta/getAccessToken/${code}`)
    .then((res) => res.json())
    .then(async (res) => {
      let resp = JSON.parse(res);
      var date = new Date();
      let expireDate = new Date(date.setMinutes(date.getMinutes() + 60));
      let data = {
        token: window.btoa(resp.access_token),
        from: date,
        to: expireDate,
        longLived: false,
      };
      localStorage.setItem("instaData", JSON.stringify(data));
      await longLiveAccessToken(resp.access_token);
      console.log(res);
    });
};
export const longLiveAccessToken = async (access_token) => {
  fetch(`${baseUrl}insta/longLiveToken/${access_token}`)
    .then((res) => res.json())
    .then((res) => {
      let resp = JSON.parse(res);
      console.log(resp);
      var today = new Date();
      var expireDate = new Date(new Date().setDate(today.getDate() + 60));
      let data = {
        token: window.btoa(resp.access_token),
        from: today,
        to: expireDate,
        longLived: true,
      };
      if (resp?.access_token) {
        localStorage.setItem("instaData", JSON.stringify(data));
      }
    });
};
const Resize_Save_Image = (url, setImgs) => {
  // var img = new Image();
  return new Promise((resolve, reject) => {
    var img = document.createElement("img");
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      var width = img.width;
      var height = img.height;
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      // canvas.getContext("2d").drawImage(img, 0, 0, width, height);
      var ctx = canvas.getContext("2d");

      // Actual resizing
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        let data = {
          file: blob,
          url: URL.createObjectURL(blob),
        };
        resolve(data);
      });
    };
  });
};
export const getInstaMedia = (atoken) => {
  try {
    return new Promise((resolve, reject) => {
      fetch(
        `https://graph.instagram.com/me/media?fields=id,caption,display_url&access_token=${atoken}`
      )
        .then((res) => res.json())
        .then((res) => {
          let arr = [];
          res?.data?.map((v) => {
            console.log(v);
            fetch(
              `https://graph.instagram.com/${v.id}?fields=id,media_type,media_url,username,timestamp&access_token=${atoken}`
            )
              .then((resp) => resp.json())
              .then(async (resp) => {
                console.log(resp);
                if (resp.media_type === "IMAGE") {
                  let data = await Resize_Save_Image(resp.media_url);
                  arr.push(data);
                } else {
                  arr.push({
                    image: false,
                  });
                }
                if (arr.length === res?.data.length) {
                  console.log(arr);
                  resolve(arr.filter((v) => !v.image));
                }
              });
          });
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  } catch (err) {
    reject(err);
    console.log(err.code);
  }
};
