import { useEffect, useState } from "react";
import { getPayments } from "../../APIS/DatabaseApis";
export default function useAllPayments(trigger) {
  const [allPayments, setallPayments] = useState([]);
  const getData = async () => {
    const res = await getPayments();
    setallPayments(res);
  };
  useEffect(() => {
    getData();
  }, [trigger]);

  return { allPayments };
}
