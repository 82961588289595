import { useEffect, useState } from "react";
import { getPaymentMethods } from "../../APIS/DatabaseApis";
export default function usePaymentMethods(trigger) {

  const [paymentMethods, setpaymentMethods] = useState([]);
  const getData = async () => {
      const paymentM = await getPaymentMethods();
      setpaymentMethods(paymentM)
  };
  useEffect(() => {
    getData();
  }, [trigger]);

  return { paymentMethods };
}
