import React, { Children } from 'react'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
export const PublicLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <div className="container">
        <Footer />
      </div>
    </>
  )
}
