import cloneDeep from "lodash.clonedeep";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineCrop } from "react-icons/md";
import ImgCropModal from "../../../../modals/imgcrop/ImgCropModal";
function Imgs16Pair({ imgs, setIsDel, setImgs }) {
  const [showImgCropModal, setShowImgCropModal] = useState(false);
  const [editImgIndex, setEditImgIndex] = useState(0);
  const [drag, setdrag] = useState({});

  const DragImg = (file, index) => {
    // console.log("Drag", index);
    setdrag({ index, file });
  };
  const DropImg = (file, index) => {
    let arr = cloneDeep(imgs);
    arr[index] = drag.file;
    arr[drag.index] = file;
    setImgs([...arr], "swap");
  };
  return (
    <>
      {showImgCropModal && (
        <ImgCropModal
          showImgCropModal={showImgCropModal}
          setShowImgCropModal={setShowImgCropModal}
          imgs={imgs}
          setImgs={setImgs}
          editImgIndex={editImgIndex}
        />
      )}
      {imgs
        ?.sort((a, b) => a.id - b.id)
        ?.map((file, index) =>
          index % 6 === 0 ? (
            <>
              <div className="front-title">
                <h3 style={{ fontSize: "16px" }}>
                  <b>
                    {index == 0
                      ? "Front"
                      : index == 6
                      ? "Front"
                      : index == 12
                      ? "Back"
                      : index == 18
                      ? "Back"
                      : index == 24
                      ? "Extra"
                      : ""}
                  </b>
                </h3>
                <h3 style={{ fontSize: "16px" }}>
                  <b>
                    {index == 0
                      ? "(1-6)"
                      : index == 6
                      ? "(7-12)"
                      : index == 12
                      ? "(13-18)"
                      : index == 18
                      ? "(19-24)"
                      : index == 24
                      ? "Photos"
                      : ""}
                  </b>
                </h3>
              </div>
              <div
                onDragOver={(event) => event.preventDefault()}
                onDrop={(e) => {
                  e.stopPropagation();
                  DropImg(file, index);
                }}
                className="img-x"
                key={index}
              >
                <img
                  // src={URL.createObjectURL(file.originFileObj)}
                  src={file.url}
                  style={{ width: "160px", height: "160px", cursor: "grab" }}
                />
                <div
                  draggable={true}
                  onDragStart={() => {
                    DragImg(file, index);
                  }}
                  className="img-overlay"
                  style={{ width: "140px", height: "140px" }}
                >
                  <MdOutlineCrop
                    className="icon-img"
                    onClick={() => {
                      setEditImgIndex(index);
                      setShowImgCropModal(true);
                    }}
                  />
                  <AiOutlineDelete
                    className="icon-img"
                    onClick={() =>
                      setIsDel({
                        flag: true,
                        file,
                      })
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              className="img-x"
              key={index}
              onDragOver={(event) => event.preventDefault()}
              onDrop={(e) => {
                e.stopPropagation();
                DropImg(file, index);
              }}
            >
              <img
                // src={URL.createObjectURL(file.originFileObj)}
                src={file.url}
                draggable={true}
                style={{ width: "160px", height: "160px" }}
              />
              <div
                className="img-overlay"
                style={{ width: "140px", height: "140px" }}
                draggable={true}
                onDragStart={() => {
                  DragImg(file, index);
                }}
              >
                <MdOutlineCrop
                  className="icon-img"
                  onClick={() => {
                    setEditImgIndex(index);
                    setShowImgCropModal(true);
                  }}
                />
                <AiOutlineDelete
                  className="icon-img"
                  onClick={() =>
                    setIsDel({
                      flag: true,
                      file,
                    })
                  }
                />
              </div>
            </div>
          )
        )}
    </>
  );
}

export default Imgs16Pair;
