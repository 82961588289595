import { PublicLayout } from "../../../components/PublicLayout/PublicLayout";
import "./signup.scss";
import React, { useState } from "react";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import { userSuccess } from "../../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import ForgotPasswordModal from "../../../components/modals/forgotPassword/ForgotPasswordModal";
import axios from "axios";
import loginLogo from "../../../assets/login/loginLogo.png";
import InfoAlert from "../../../components/modals/infoAlert/InfoAlert";
import { message } from "antd";
const baseUrl = "https://picswagger-backend.uc.r.appspot.com/";
// const baseUrl = "http://localhost:5000/";

function Signup() {
  const navigate = useNavigate();
  const [isEye, setIsEye] = useState(true);
  const [loadingX, setLoadingX] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const { currentIndex } = useSelector((state) => state.orders);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email format")
      .required("Email is Required"),
    password: Yup.string().required("Password is Required").min(8),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const onSubmit = async () => {
    if (formik.values.email && formik.values.password) {
      setLoadingX(true);
      try {
        let data = {
          ...formik.values,
          verifiedEmail: false,
          customerId: "",
        };
        delete data.confirmPassword;
        const res = await axios.post(`${baseUrl}user/signup`, { ...data });
        // setAlertInfo({
        //   alertInfo: true,
        //   title: 'Email Verification',
        //   msg:'Please check your email to verified your account.'
        // })
        // alert("Please check your email to verified your account.");
        const user = res.data;
        // console.log("user data is ", user);
        let token = window.btoa(user.token);
        localStorage.setItem(
          "data",
          JSON.stringify({ data: user.user, token: token })
        );
        console.log(user);
        setLoadingX(false);
        navigate(currentIndex > 0 ? "/orders" : "/home");
      } catch (e) {
        setLoadingX(false);
        if (e?.response?.data?.message?.code === 11000) {
          message.error("Email already exists");
        } else {
          message.error(e.message);
        }
        console.log(e);
      }
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <PublicLayout>
      <ForgotPasswordModal
        showForgotPasswordModal={showForgotPasswordModal}
        setShowForgotPasswordModal={setShowForgotPasswordModal}
      />
      <InfoAlert
        title={alertInfo?.title}
        msg={alertInfo.msg}
        setShowInfoAlert={(value) =>
          setAlertInfo({ ...alertInfo, show: value })
        }
        showInfoAlert={alertInfo?.show}
      />
      <div className="signup-x">
        {loadingX && <FullPageLoading />}
        <div className="login-form">
          <img src={loginLogo} alt="" />

          <h2>
            <b>Hi, Welcome to PicSwagger!</b>
          </h2>
          <form className="auth_form" onSubmit={formik.handleSubmit}>
            <div className="fields">
              <input
                type="text"
                className="field-email"
                name="firstName"
                placeholder="Enter your first name"
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div id="error">{formik.errors.firstName}</div>
              ) : null}
              <UserOutlined className="icon-user" />
              <div className="password-field">
                <input
                  type="text"
                  className="field-password"
                  name="lastName"
                  placeholder="Enter your last name"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div id="error">{formik.errors.lastName}</div>
                ) : null}
                <UserOutlined className="icon-lock" />
              </div>
            </div>
            <div className="fields">
              <input
                type="email"
                className="field-email"
                name="email"
                placeholder="Enter your email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div id="error">{formik.errors.email}</div>
              ) : null}
              <MailOutlined className="icon-user" />
              <div className="password-field">
                <input
                  type={isEye ? "password" : "text"}
                  className="field-password"
                  placeholder="Enter your password"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div id="error">{formik.errors.password}</div>
                ) : null}
                {isEye ? (
                  <AiFillEye
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                )}
                <LockOutlined className="icon-lock" />
              </div>{" "}
              <div className="password-field">
                <input
                  type={isEye ? "password" : "text"}
                  className="field-password"
                  placeholder="Confirm your password"
                  name="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div id="error">{formik.errors.confirmPassword}</div>
                ) : null}
                {isEye ? (
                  <AiFillEye
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                )}
                <LockOutlined className="icon-lock" />
              </div>
            </div>
            {/* <p
              className="forgot"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Forgot password
            </p> */}
            <div className="btn">
              <button type="submit">Sign Up </button>
            </div>
          </form>
          <div>
            <p onClick={() => navigate("/signin")} className="create_acct_p">
              <b>
                <u>Already have an account</u>
              </b>
            </p>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Signup;
