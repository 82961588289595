import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { addAddress } from "../../../APIS/DatabaseApis";
import { useNavigate } from "react-router-dom";
import "./newaddress.scss";
import FullPageLoading from "../../Loading/FullPageLoading";
//hooks
const { Option } = Select;
const NewAddress = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async(values) => {
    try {
      setLoading(true);
      let data={...values,defaultAddress:false}
      await addAddress(data);
      form.resetFields();
      navigate("/account/recipients-address");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
 
  return (
    <>
      {loading ? <FullPageLoading /> : ""}
      <h6>
        <b>ADD NEW ADDRESS</b>
      </h6>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please input your city name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please input your address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please input your state name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[
            {
              required: true,
              message: "Please input your zip code",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select your country"
            onChange={handleChange}
            allowClear
          >
            <Option value="United State">United State</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-x">
            ADD ADDRESS
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default NewAddress;
