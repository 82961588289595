export const productIds = {
  Classic: {
    16: "143",
    20: "144",
    24: "145",
  },
  Mini: {
    16: "147",
    20: "148",
    24: "149",
  },
  "Custom Album Cover": {
    Classic: "150",
    Mini: "151",
  },
};

export const upgradesIds = {
  ["Thanks for the Memories"]: "219",
  ["Let the Good Times Roll"]: "220",
  ["All Smiles"]: "221",
  ["Stand Out"]: "222",
  ["Flower Power"]: "223",
  ["Modern Geo"]: "224",
  ["Greatest Hits"]: "225",
  ["Celebrate"]: "226",
  ["Custom Album Cover (+ $3.00)"]: "229",
};

export const shppingMethods = {
  ["USPS First-class"]: "1C",
  ["USPS Priority"]: "PR",
};
