import React from "react";
import { Routes, Route } from "react-router-dom";
import PvtRoute from "./PvtRoutes";
import OrderSuccess from "../components/Order/ordersuccess/OrderSuccess";
import About from "../pages/about/About";
import Account from "../pages/account/Account";
import InstaAuth from "../pages/auth/instagramAuth/instaAuth";
import Signin from "../pages/auth/signin/Signin";
import Signup from "../pages/auth/signup/Signup";
import ForgotPass from "../pages/forgotpassword/ForgotPass";
import { Home } from "../pages/Home";
import Inspiration from "../pages/inspiration/Inspiration";
import { Orders } from "../pages/Orders";
import Products from "../pages/products/Products";
import Tutorial from "../pages/tutorial/Tutorial";
import PrivacyPolicy from '../components/Privacy Policy/PrivacyPolicy';
import Policy from '../components/policy/policy';
import TermAndCondition from '../components/Terms/TermsConditions';
import PageNotFound from "../pages/not_found/page_not_found";
import GiftCard from "../pages/giftCard/GiftCard";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="home" element={<Home />} />
      <Route path="signin" element={<Signin />} />
      <Route path="signup" element={<Signup />} />
      <Route path="about" element={<About />} />
      <Route path="giftcard" element={<GiftCard />} />
      <Route path="products" element={<Products />} />
      <Route
        path="orders"
        element={
          // <PvtRoute>
          <Orders />
          // </PvtRoute>
        }
      />
      <Route path="faqs" element={<Tutorial />} />
      <Route path="inspiration" element={<Inspiration />} />
      <Route path="refundPolicy" element={<PrivacyPolicy />} />

      <Route path="privacyPolicy" element={<Policy />} />

      <Route path="termsConditions" element={<TermAndCondition />} />

      <Route
        path="orders-success/:number"
        element={
          <PvtRoute>
            <OrderSuccess />
          </PvtRoute>
        }
      />
      <Route path="resetPassword/:id/:token" element={<ForgotPass />} />
      <Route
        path="instaAuth"
        element={
          // <PvtRoute>
          <InstaAuth />
          // </PvtRoute>
        }
      />
      <Route
        path="account/*"
        element={
          <PvtRoute>
            <Account />
          </PvtRoute>
        }
      />
    </Routes>
  );
};
