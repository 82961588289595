import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import InfoAlert from "../components/modals/infoAlert/InfoAlert";

function PvtRoute({ children }) {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  let sdata = JSON.parse(localStorage.getItem("data"));
  let userId = sdata?.data._id;
  const showAlert = () => {
    setAlertInfo({
      show: true,
      title: "Signin first!",
      msg: (
        <p>
          Please sign-in to proceed.
          <Link style={{ color: "black" }} to={"/signin"}>
            <u> Click here to sign-in or to create an account.</u>
          </Link>
        </p>
      ),
    });
  };

  !userId && showAlert();
  return (
    <>
      <InfoAlert
        title={alertInfo?.title}
        msg={alertInfo.msg}
        setShowInfoAlert={(value) =>
          setAlertInfo({ ...alertInfo, show: value })
        }
        showInfoAlert={alertInfo?.show}
      />
      {userId ? children : <Navigate to="/" />}
    </>
  );
}

export default PvtRoute;
