import { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import { BrowserRouter } from "react-router-dom";
import { AllRoutes } from "./Routes/AllRoutes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// "pk_live_51M74LEAzV89Dw9Mno1eKlSljZoCIgEaoBFSIXUNOeGAk90IlbU2JFBoKEDMmTF0i57ROT1OIN2Vv2WiokHxGZ3R900e9AivGcD"
// "pk_test_51M74LEAzV89Dw9MnGkf9cJqhoZEyCiAZVrsyze8XdUZpKHJmFb1HPzeURmjRTbCscAlsjnLhyY9EfBvAiMx6pDFI006Z3sv4n6";

const stripePromise = loadStripe(
  "pk_live_51M74LEAzV89Dw9Mno1eKlSljZoCIgEaoBFSIXUNOeGAk90IlbU2JFBoKEDMmTF0i57ROT1OIN2Vv2WiokHxGZ3R900e9AivGcD"
  // "pk_test_51M74LEAzV89Dw9MnGkf9cJqhoZEyCiAZVrsyze8XdUZpKHJmFb1HPzeURmjRTbCscAlsjnLhyY9EfBvAiMx6pDFI006Z3sv4n6"
);
console.log('ase url', process.env);

function App() {
  const [clientSecret, setClientSecret] = useState("");
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <AllRoutes />
      </Elements>
    </BrowserRouter>
  );
}

export default App;
