import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAXjm44ihZXHLaBbtBcXus99G5jJLOvqKw",
  authDomain: "picswagger.firebaseapp.com",
  projectId: "picswagger",
  storageBucket: "picswagger.appspot.com",
  messagingSenderId: "219988103849",
  appId: "1:219988103849:web:8dfedb6c376e12cdfd8fb8",
  measurementId: "G-KTV117VXJG",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


export { storage };
