export const data = [
  {
    id: 1,
    comment: "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.",
    username: "hollox",
    img: "https://images.pexels.com/photos/2093578/pexels-photo-2093578.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 2,
    comment: "Lorem ipsum dollar sign xy hellow world",
    username: "hollox",
    img: "https://images.pexels.com/photos/3755751/pexels-photo-3755751.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 3,
    comment: "Lorem ipsum dollar sign xy hellow world",
    username: "hollox",
    img: "https://images.pexels.com/photos/2084711/pexels-photo-2084711.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
  {
    id: 4,
    comment: "Lorem ipsum dollar sign xy hellow world",
    username: "hollox",
    img: "https://images.pexels.com/photos/3023270/pexels-photo-3023270.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 5,
    comment: "Lorem ipsum dollar sign xy hellow world",
    username: "hollox",
    img: "https://images.pexels.com/photos/298509/pexels-photo-298509.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
  },
];
