export const ImagesUrls = {
  Kitchen:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-blank-background-min.png?alt=media&token=912fb6ac-74c9-4c44-8571-b317041ef439",
  Floating:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fwhite-wall-backgroud.jpg?alt=media&token=efbd30f5-7dc4-41e1-8a45-e47736894b51",
  Wall: "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fblack-shelf-plain.png?alt=media&token=8fd51aa1-2057-49d7-9c07-29fc14dd2534",
  "Kitchen-scene-4-8.png":
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-scene-4-8.png?alt=media&token=47196c92-9a6f-48eb-9e66-0aee1b7cd601",
  ["Kitchen-scene-4-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-scene-4-10.png?alt=media&token=bc4df9b4-4bfe-4df4-955c-295a57e73590",
  ["kitchen-scene-4-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-scene-4-12.png?alt=media&token=1328b343-c89b-4eb7-a2bf-0e22f373671a",
  ["Kitchen-scene-8-8.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FKitchen-scene-8-8.png?alt=media&token=44c3e01d-dff5-46e4-a988-4b8b4d480b6c",
  ["Kitchen-scene-8-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-scene-8-10.png?alt=media&token=313e4829-c534-4c0c-81ee-bbfd67d42a63",
  ["Kitchen-scene-8-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fkitchen-scene-8-12.png?alt=media&token=6c9ff755-6912-4eab-a2f4-7e24b1d23cad",
  ["Wall-scene-4-8.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-4-8.png?alt=media&token=7baf74d1-938d-4ae7-92ee-84d32df2795e",
  ["Wall-scene-4-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-4-12.png?alt=media&token=bb74c79d-798d-4bd6-8440-1356019c8c32",
  ["Wall-scene-4-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-4-10.png?alt=media&token=13f4829f-a551-4535-90fa-fdd76d13a3dd",
  ["Wall-scene-8-8.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-8-8.png?alt=media&token=85245513-aa2c-44bf-a3ce-ed9e56209109",
  ["Wall-scene-8-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-8-10.png?alt=media&token=75aec786-712e-4d2d-b681-25ccf4b905ac",
  ["Wall-scene-8-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FWall-scene-8-12.png?alt=media&token=714e1f22-0447-4e48-a3a4-ceef5bc5992a",
  ["Living-Space-scene-4-8.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-4-8.png?alt=media&token=88d0725a-c7df-40e7-9d93-8ca58f0af9d0",
  ["Living-Space-scene-4-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-4-10.png?alt=media&token=173d7345-46e4-4667-a6f1-ee8605c48f2d",
  ["Living-Space-scene-4-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-4-12.png?alt=media&token=69f08b2f-b91f-417d-8b48-5757d20e9c1a",
  ["Living-Space-scene-8-8.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-8-8.png?alt=media&token=58d85884-c83d-49e8-871e-f2be274663d6",
  ["Living-Space-scene-8-10.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-8-10.png?alt=media&token=951a1ea8-e54e-475c-be74-e73993c76955",
  ["Living-Space-scene-8-12.png"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2FLiving-Space-scene-8-12.png?alt=media&token=4e1227ca-7254-41ff-aad5-b00a6905a01b",
  ["white-wall-backgroud.jpg"]:
    "https://firebasestorage.googleapis.com/v0/b/picswagger.appspot.com/o/uploads%2FapproveFramImages%2Fwhite-wall-backgroud.jpg?alt=media&token=efbd30f5-7dc4-41e1-8a45-e47736894b51",
};