import React, { useState,useEffect } from "react";
import "./myorders.scss";
import gift from "../../../assets/checkout/gift.png";
import useUserOrders from "../../hooks/useUserOrders";
import {
  saveName,
  savePhoto,
  saveSelect,
  setCurrentIndex,
  setOrderId,
  saveAlbumCoverName,
  saveAddress,
  setIsViewOnly,
  setProductPrice,
  setPaymentDetail,
  setFilePath,
  setOrderNumber,
  setCustomAlbumCover,
} from "../../../redux/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewOrder, deleteOrder } from "../../../APIS/DatabaseApis";
import { coverImages } from "../../Order/SelectPicSwagger/Content/ChooseAlbum";
import CoverFramSmall from "../../Order/SelectPicSwagger/Content/CoverFram/CoverFramSmall";
import AddressStatusModal from "../../modals/addressStatus/AddressStatus";
import { Empty, Popconfirm } from "antd";
import FullPageLoading from "../../Loading/FullPageLoading";

function MyOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddressStatusModal, setAddressStatusModal] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const [updateTrigger, setupdateTrigger] = useState(false);
  const { userOrders } = useUserOrders(updateTrigger);
  const [loading, setloading] = useState(true);
  const handleViewDetail = (order) => {
    dispatch(
      saveSelect({
        size: order.size,
        page: order.pages,
      })
    );
    dispatch(savePhoto(JSON.parse(JSON.stringify(order.albumImages))));
    dispatch(saveName(order.albumName));
    dispatch(setOrderId(order._id));
    dispatch(setProductPrice(order.productPrice));
    dispatch(setCurrentIndex(0));
    order?.albumCoverName &&
      dispatch(saveAlbumCoverName(order?.albumCoverName));
    order?.addresses && dispatch(saveAddress(order?.addresses));
    dispatch(setIsViewOnly(true));
    order?.productPrice && dispatch(setProductPrice(order?.productPrice));
    dispatch(setPaymentDetail(order?.payment ? order?.payment : {}));
    dispatch(setFilePath(order?.filePath ? order?.filePath : null));
    dispatch(setOrderNumber(order?.orderNumber ? order?.orderNumber : null));
   dispatch(
     setCustomAlbumCover(
       order?.customAlbumCover
         ? {
             front: order?.customAlbumCover?.front,
             back: order?.customAlbumCover?.back,
             pdf: order?.customAlbumCover?.pdf,
           }
         : null
     )
   );
    navigate("/orders");
  };
  useEffect(() => {
    if (userOrders.length>0) {
    setloading(false)
    }
   setTimeout(() => {
     setloading(false);
   }, 10000);
  }, [JSON.stringify(userOrders)])

  return (
    <>
      <div className="my-orders-x">
        <AddressStatusModal
          showAddressStatusModal={showAddressStatusModal}
          setAddressStatusModal={setAddressStatusModal}
          addresses={addresses}
        />
        <h5>ORDERS</h5>
        {loading ? <FullPageLoading /> : userOrders.length > 0 ? "" : <Empty />}
        <div className="orders-x">
          {userOrders
            ?.filter((v) => v.status === "order")
            ?.map((order, i) => {
              return (
                <div key={i} className="o-card">
                  <div className="main-content">
                    <div className="o-img">
                      <img
                        width={150}
                        height={150}
                        src={
                          order?.customAlbumCover
                            ? order?.customAlbumCover?.front
                            : coverImages.find(
                                (v) =>
                                  order?.albumCoverName &&
                                  v.name === order?.albumCoverName
                              )?.img || coverImages[0]?.img
                        }
                      />
                      {/* <CoverFramSmall
                      coverImg={
                        coverImages.find(
                          (v) =>
                            order?.albumCoverName &&
                            v.name === order?.albumCoverName
                        )?.img || coverImages[0]?.img
                      }
                      albumImg={order?.albumImages[0]?.url}
                    /> */}
                    </div>
                    <div className="o-date">
                      <h6>Order Number</h6>
                      <p>{order?.orderNumber}</p>
                    </div>
                    <div className="o-date">
                      <h6>Order Date</h6>
                      <p>{new Date(order?.orderDate).toUTCString()}</p>
                    </div>
                    <div className="o-status">
                      <h6>Order Status</h6>
                      <p
                        onClick={() => {
                          setAddressStatusModal(true);
                          setAddresses(order?.addresses);
                        }}
                        className="printing"
                      >{`View Status`}</p>
                      <Popconfirm
                        title={"Changes not be saved only view the order!"}
                        onConfirm={() => {
                          handleViewDetail(order);
                        }}
                      >
                        <p className="details">
                          <b>View Details</b>
                        </p>
                      </Popconfirm>
                    </div>
                  </div>
                  <h6 className="fw-bold mt-3">
                    {`${order.albumName}${
                      order?.albumCoverName && "/ " + order?.albumCoverName
                    }`}
                  </h6>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default MyOrders;
