import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import logo from "../../../assets/logo/logo.png";
import { useNavigate, } from "react-router-dom";

import FullPageLoading from "../../../components/Loading/FullPageLoading";
import { getInstaAccessToken } from "../../../APIS/API";
import { useDispatch } from "react-redux";
import { instaAuthState,setCurrentIndex } from "../../../redux/slices/orderSlice";
const InstaAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  const [loadingX, setLoadingX] = useState(false);
  let code = window.location.href.split("code=")[1]?.slice(0, -2);
  return (
    <div className="form-x">
      {loadingX && <FullPageLoading />}
      <img src={logo} />
      <div className="content text-center">
              <Popconfirm title={"Are you sure?"} onConfirm={async() => {
                  setLoadingX(true)
                await  getInstaAccessToken(code).then(() => {
                      dispatch(instaAuthState(true))
                      dispatch(setCurrentIndex(1));
                      navigate('/orders')
                      setLoadingX(false)
                  }).catch((e) => {
                      setLoadingX(false);
                      console.log(e);
                  })
        }}>
          <Button>Fetch Gallery</Button>
        </Popconfirm>
      </div>
    </div>
  );
};
export default InstaAuth;
