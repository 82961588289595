import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveInfluencer, findInfluencer } from "../../../APIS/DatabaseApis";
import FullPageLoading from "../../Loading/FullPageLoading";
import "./influencer.scss";
//hooks
const { Option } = Select;
const Platform = ["YouTube", "FaceBook", "Instagram", "Others"];
const Influencer = () => {
  const [loading, setLoading] = useState(true);
  const [iAmInfluencer, setIamInfluencer] = useState(false);
  const [isExists, setIsExists] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      const res = await saveInfluencer(values);
      if (res.status) {
        setIsExists(true);
        setIamInfluencer(res.data.isAccepted);
      }
      form.resetFields();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //hello
  const insF = async () => {
    console.log("use effect is running ");
    const ins = JSON.parse(sessionStorage.getItem("influencer"));
    console.log("sesstion storage data is ", ins);
    if (ins) {
      console.log("hi influencer ", ins);
      setIsExists(true);
      setIamInfluencer(ins.status);
      setLoading(false);
      console.log(" this is the first part of conditions ");
    } else {
      const res = await findInfluencer();
      if (res.status) {
        console.log(" this is the 2nd part of conditions");

        setIsExists(true);
        setIamInfluencer(res.data.isAccepted);
        sessionStorage.setItem(
          "influencer",
          JSON.stringify({ status: res.data.isAccepted })
        );
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    insF();
  }, []);
  return (
    <>
      {loading && <FullPageLoading />}
      <h6>
        <b>BECOME INFLUENCER</b>
      </h6>
      <p className="p-influencer">
        If you are influencer on <b>PicSwagger </b>then you can earn revenue
        through our Platform using sharing your coupons on your Influing
        Platform for further details contact support team.
      </p>
      {!isExists ? (
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          // autoComplete="off"
        >
          <Form.Item
            label="Influncing Platform"
            name="platform"
            rules={[
              {
                required: true,
                message: "Platform is required",
              },
            ]}
          >
            <Select
              // onChange={handleChangePlatform}
              placeholder="Influecing Platform"
              allowClear
              mode="single"
            >
              {Platform?.map((v) => {
                return <Option value={v}>{v}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Your Name on platform"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your public name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Platform URL"
            name="platformUrl"
            rules={[
              {
                required: true,
                message: "Platform url is required.",
              },
              {
                type: "url",
                message: "This field must be a valid url.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-influencer">
              Become Influencer
            </Button>
          </Form.Item>
        </Form>
      ) : iAmInfluencer ? (
        <div>You are already influencer</div>
      ) : (
        <div>
          Your Status is in Pending Process. Your status will soon enabled.
        </div>
      )}
    </>
  );
};
export default Influencer;
