import { Col, Row, Modal } from "antd";
import React, { useState, useEffect } from "react";
import "./addressstatus.scss";

const AddressStatusModal = ({
  showAddressStatusModal,
  setAddressStatusModal,
  addresses
}) => {
  // const addresses = [
  //   {
  //     name: "Khan",
  //     address: "Karim Block",
  //     city: "Lahore",
  //     state: "Punjab",
  //     zip: "1111",
  //     country: "Pakistan",
  //   },
  // ];
  const handleOk = () => {
    setAddressStatusModal(false);
  };

  const handleCancel = () => {
    setAddressStatusModal(false);
  };

  return (
    <>
      <Modal
        title={"All Addresses"}
        open={showAddressStatusModal}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <div className="addressstatus">
          <div className="addresses">
            <Row>
              <Col xs={7} sm={7} lg={7}>
                <b>Name</b>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <b>Address</b>
              </Col>
              <Col xs={5} sm={5} lg={5}>
                <b>Status</b>
              </Col>
            </Row>
            {addresses?.map((item, indx) => {
              return (
                <Row className="py-3 border-bottom">
                  <Col xs={7} sm={7} lg={7}>
                    {item?.name}
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    {item?.address}, {item?.city}, {item?.state}, {item?.zip}{" "}
                    {item?.country}
                  </Col>
                  <Col xs={5} sm={5} lg={5}>
                    <div className="actions-x">
                      <span>{item?.statusId }</span>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddressStatusModal;
