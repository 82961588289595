
import React,{useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
export const FooterComponent = ({ title, items }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="col-sm-12 col-md-3 col-lg-3">
      <ul>
        <h3 className="fw-bold ">{title}</h3>
        <li>{/* <h3 className="fw-bold text-white">{title}</h3> */}</li>
        {/* {items?.map((v, i) => {
          return (
            <li key={i} className="footer-li">
              <Link to={v.link} className="footer-li">
                {v.item}
              </Link>
            </li>
          );
        })} */}

        {items?.map((v, i) => {
          return (
            <li key={i} className="footer-li">
              {title === "Social Links" || v.item === "Contact Us" ? (
                <a href={v.link} target={"_blank"} className="footer-li">
                  {v.item}
                </a>
              ) : (
                <NavLink 
                    to={v.link}
                    className={`footer-li ${v.link===window.location.pathname?'active_footer_link':""}`}
                >
                  {v.item}
                </NavLink>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
