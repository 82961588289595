import {
  Input,
  Radio,
  Space,
  Button,
  Checkbox,
  Form,
  Select,
  InputNumber,
} from "antd";
import React, { useState, useEffect } from "react";
import ogift from "../../../../../assets/../assets/orders/ogift.png";

import "../style.css";
import "./addaddress.scss";
import { shipingForm } from "./data";
import { BsCart2, BsChevronUp, BsChevronDown } from "react-icons/bs";
import {
  next,
  saveAddress,
  saveYourAddresses,
} from "../../../../../redux/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  newImgsToPdf,
  updateAddress,
  updateOrder,
  uploadImage,
} from "../../../../../APIS/DatabaseApis";
import useUserAddresses from "../../../../hooks/useUserAdresses";
import FullPageLoading from "../../../../Loading/FullPageLoading";
import useAllProducts from "../../../../hooks/useAllProducts";
import { coverImages } from "../ChooseAlbum";
import CoverFram from "../CoverFram/CoverFram";
import InfoAlert from "../../../../modals/infoAlert/InfoAlert";

const { Option } = Select;
export const AddAddress = () => {
  const { allProducts } = useAllProducts();
  const dispatch = useDispatch();
  const {
    address,
    yourAddresses,
    photos,
    select,
    orderId,
    isViewOnly,
    productPrice,
    albumCoverName,
    customAlbumCover,
  } = useSelector((state) => state.orders);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const { addresses } = useUserAddresses(updateTrigger);
  const [showAdress, setShowAddress] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [shippingValue, setShippingValue] = useState(0);
  const [cartAddress, setCartAddress] = useState(null);
  const [quantity, setQuantity] = useState(1);
  // const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipping, setShipping] = useState({});
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  const shippings = [
    {
      label: "USPS First-class",
      charges: shipping?.firstClassCharges ? shipping?.firstClassCharges : 0,
      hint: "shipping typically take 2-3 days",
    },
    {
      label: "USPS Priority",
      charges: shipping?.priorityShippingCharges
        ? shipping?.priorityShippingCharges
        : 0,
      hint: "shipping typically take 1-2 days",
    },
  ];

  const handleShipingValue = (e) => {
    setShippingValue(e.target.value);
  };
  const makeDefault = async (item) => {
    try {
      setLoading(true);
      let prevDefalut = addresses.find((v) => v.defaultAddress);
      if (
        addresses.length > 0 &&
        prevDefalut &&
        prevDefalut?._id !== item?._id
      ) {
        await updateAddress(prevDefalut?._id, { defaultAddress: false });
        await updateAddress(item?._id, { defaultAddress: true });
        setUpdateTrigger(!updateTrigger);
      } else {
        await updateAddress(item?._id, { defaultAddress: true });
        setUpdateTrigger(!updateTrigger);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    let id = addresses.length >= 1 ? addresses.length + 1 : 1;
    let defaultAddress = !!values.defaultAddress ?? false;
    let data = {
      ...values,
      defaultAddress,
    };
    try {
      setLoading(true);
      let res = await addAddress(data);
      if (defaultAddress) {
        let prevDefalut = addresses.find((v) => v.defaultAddress);
        if (addresses.length > 0 && prevDefalut) {
          await updateAddress(prevDefalut?._id, { defaultAddress: false });
        }
        setCartAddress({ ...res });
      }
      setUpdateTrigger(!updateTrigger);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    form.resetFields();
    setAddNewAddress(false);
  };
  const handleClick = async (address) => {
    if (address.length > 0 && address[0]?.quantity > 0) {
      try {
        setLoading(true);
        let data = {
          addresses: address,
          currentIndex: 5,
        };
        await updateOrder(orderId, data);
        dispatch(saveYourAddresses(addresses));
        dispatch(saveAddress([...address]));
        dispatch(next());
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setAlertInfo({
        show: true,
        title: "Add PicSwagger fisrt!",
        msg: <p>Plaese add atleast one PicSwagger in the cart.</p>,
      });
      // alert("Add atleast one item in the cart");
    }
  };
  const handleAddToCart = () => {
    if (cartAddress) {
      let arr = [
        ...address,
        {
          ...cartAddress,
          shippingValue,
          price: productPrice,
          quantity: quantity,
        },
      ];
      let obj = {};
      arr.forEach((item) => {
        let key = item._id;
        let quantity =
          obj[key]?.quantity !== undefined
            ? obj[key].quantity + item.quantity
            : item.quantity;
        obj[key] = {
          ...item,
          id: key,
          quantity,
        };
        // setQuantity(quantity);
      });
      dispatch(saveAddress([...Object.values(obj)]));
      setAlertInfo({
        show: true,
        title: "PicSwagger Added!",
        msg: (
          <p>
            Your PicSwagger has been added to your cart.
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClick([...Object.values(obj)]);
              }}
            >
              Click here to check-out.
            </u>
          </p>
        ),
      });
    } else {
      setAlertInfo({
        show: true,
        title: "Missing Ship-to-addresss!",
        msg: "Please select a ship-to address to proceed.",
      });

      // alert("You must add a sending address");
    }
  };
  useEffect(() => {
    let x = address?.length > 0 && address[address.length - 1];
    if (x) {
      setQuantity(x?.quantity);
      setCartAddress(x);
      setShippingValue(x?.shippingValue);
    } else {
      let prevDefalut = addresses.find((v) => v.defaultAddress);
      setCartAddress(prevDefalut);
    }
  }, [JSON.stringify(addresses)]);
  useEffect(() => {
    if (allProducts?.length > 0) {
      let obj = allProducts?.find(
        (v) =>
          select?.size.includes(v?.size?.slice(0, v?.size?.length - 2)) &&
          select?.page === v?.pages
      );
      if (obj) {
        setShipping({
          firstClassCharges: obj?.fstShippingPrice.toFixed(2),
          priorityShippingCharges: obj?.priorityShippingPrice.toFixed(2),
        });
      }
    }
  }, [JSON.stringify(allProducts), select]);

  return (
    <div className="container AddAddress">
      {loading ? <FullPageLoading /> : ""}
      <InfoAlert
        title={alertInfo?.title}
        msg={alertInfo.msg}
        setShowInfoAlert={(value) =>
          setAlertInfo({ ...alertInfo, show: value })
        }
        showInfoAlert={alertInfo?.show}
      />

      <div className="row">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <div className="address-x">
            <h5 className="fw-bold">Select Ship-To Recipient/Address:</h5>
            <div
              className="s-address-x"
              onClick={() => setShowAddress(!showAdress)}
            >
              <span>Select Addresss</span>
              <span>
                {showAdress ? (
                  <BsChevronUp className="icon" />
                ) : (
                  <BsChevronDown className="icon" />
                )}
              </span>
            </div>
            <div className="addresses-x">
              {showAdress
                ? addresses.map((item, index) => {
                    return (
                      <div
                        className="address-item"
                        onClick={() => {
                          setCartAddress(item);
                        }}
                      >
                        <p>
                          <Radio
                            checked={cartAddress?._id === item?._id}
                            onChange={() => {
                              setCartAddress(item);
                              setShowAddress(false);
                            }}
                          />
                          <b>{item?.name}</b>
                        </p>
                        <p>
                          {item?.address} {item?.city}
                        </p>
                        <p>
                          {item?.state} {item?.country} {item?.zip}
                        </p>
                        <p
                          className="make-default"
                          onClick={(e) => {
                            e.stopPropagation();
                            makeDefault(item);
                            setCartAddress({
                              ...item,
                              defaultAddress: true,
                            });
                            setShowAddress(false);
                          }}
                        >
                          {item?.defaultAddress ? "Defalut" : " Make default"}
                        </p>
                      </div>
                    );
                  })
                : cartAddress && (
                    <div className="address-item">
                      <p>
                        <b>{cartAddress?.name}</b>
                      </p>
                      <p>
                        {cartAddress?.address} {cartAddress?.city}
                      </p>
                      <p>
                        {cartAddress?.state} {cartAddress?.country}{" "}
                        {cartAddress?.zip}
                      </p>
                    </div>
                  )}
            </div>
            {addNewAddress ? (
              <div className="address-form-x">
                <Form
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your address",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please input your state name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Zip"
                    name="zip"
                    rules={[
                      {
                        required: true,
                        message: "Please input your zip code",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="country"
                    label="Country"
                    initialValue="United States"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select placeholder="Select your country" allowClear>
                      <Option value="United States">United States</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="defaultAddress"
                    style={{ marginLeft: "30px" }}
                  >
                    <span>
                      <Radio value={true} />
                      Make this my default address
                    </span>
                  </Form.Item>
                  <Form.Item>
                    <div className="bottom-x">
                      <button htmlType="submit" className="custom-btn">
                        Add Address
                      </button>
                      <button
                        className="custom-btn cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          setAddNewAddress(!addNewAddress);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <h6
                className="fw-bold mt-3 new-x"
                onClick={() => setAddNewAddress(!addNewAddress)}
              >
                <u>Add New Recipient / Ship-To Address:</u>
              </h6>
            )}
          </div>
          <div className="address-x">
            <h5 className="fw-bold">Select Shipping Service</h5>
            <Radio.Group onChange={handleShipingValue} value={shippingValue}>
              <Space direction="vertical">
                <Radio value={0}>
                  <b>USPS First-class</b> (shipping typically takes 3-5 days)
                </Radio>
                <Radio value={1}>
                  <b>USPS Priority</b> (shipping typically takes 1-3 days)
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 contentLeft">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h4 className="text-center fw-bold">Order Summary</h4>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="content">
              {/* <img
                className="img-or"
                src={coverImages.find((v) => v.name === albumCoverName)?.img}
                alt=""
              /> */}
              <CoverFram
                coverImg={
                  customAlbumCover
                    ? customAlbumCover?.front
                    : coverImages.find((v) => v.name === albumCoverName)?.img
                }
                albumImg={photos[0]?.url}
              />
            </div>
            <div className="row  align-items-center justify-content-md-center mt-1">
              <div className="col-3">
                <label for="inputtext16" className="col-form-label">
                  <b>Quantity:</b>
                </label>
              </div>
              <div className="col-6">
                <InputNumber
                  width={"100%"}
                  min={1}
                  // type="number"
                  id="input_number"
                  addonBefore={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => quantity >= 2 && setQuantity(quantity - 1)}
                    >
                      -
                    </span>
                  }
                  addonAfter={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setQuantity(quantity + 1)}
                    >
                      +
                    </span>
                  }
                  className="form-control text-center"
                  value={quantity}
                  onChange={(e) => {
                    if (e.target.valueAsNumber >= 1) {
                      setQuantity(e.target.valueAsNumber);
                    }
                  }}
                />
              </div>

            </div>
            {/* {customAlbumCover ? (
              <div className="row justify-content-md-between checkCard mt-2">
                <div className="col-12 text-primary">
                  Classic with Custom Cover. So, it's clear why the price
                  increased by <b>$3.00</b>
                </div>
              </div>
            ) : (
              ""
            )} */}
            <div className="row justify-content-md-between checkCard mt-3">
              <div className="col-9">
                <p className="mb-0">
                  <b>
                    {select?.size}{" "}
                    {!!customAlbumCover ? "with Custom Album Cover" : ""}
                  </b>
                </p>
                <span>{select?.page} pages</span>
              </div>
              <div className="col-3 text-right">
                <span>
                  {/* {products[0].price * quantity.toFixed(2)}</b> */}
                  <b>
                    $
                    {(Math.round(productPrice * quantity * 100) / 100).toFixed(
                      2
                    )}
                  </b>
                </span>
              </div>
            </div>

            <div className="row justify-content-md-between checkCard mt-2">
              <div className="col-6">
                <p className="mb-0">
                  <b>Shipping Charge</b>
                </p>
                <span>{shippings[shippingValue]?.label}</span>
              </div>
              <div className="col-3 text-right">
                <span>
                  <b>
                    ${(shippings[shippingValue]?.charges * quantity).toFixed(2)}
                  </b>
                </span>
              </div>
            </div>

            <div className="checkFooter row my-2 justify-content-md-between">
              <div className="col-6">
                <span>Subtotal</span>
              </div>
              <div className="col-3 text-right price">
                <span>
                  <b>
                    {(
                      Math.round(productPrice * quantity * 100) / 100 +
                      shippings[shippingValue].charges * quantity
                    ).toFixed(2)}
                  </b>
                </span>
              </div>
              <button className="btn p-3">
                <div className="btn-address" onClick={handleAddToCart}>
                  <span>
                    <b>Add To-Cart</b>
                  </span>
                  <BsCart2 className="icon" />
                  <b>{/* <span>({cartAddress?.quantity})</span> */}</b>
                </div>
              </button>

              <p
                className="checkout_btn p-3"
                style={{
                  opacity: address?.length > 0 ? 1 : 0.5,
                  pointerEvents: address?.length > 0 ? "auto" : "none",
                }}
                onClick={() => {
                  if (isViewOnly) {
                    dispatch(next());
                  } else {
                    handleClick(address);
                  }
                }}
              >
                <span>
                  <b>NEXT: CHECKOUT</b>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="steps-action">
        <Button
          className="btn-x"
          onClick={() => {
            if (isViewOnly) {
              dispatch(next());
            } else {
              handleClick();
            }
          }}
        >
          NEXT: CHECKOUT
        </Button>
      </div> */}
    </div>
  );
};
