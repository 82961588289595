import React, { useState } from "react";
import "./style.css";
import Birthdays from "../../../assets/perfect/Picswagger_is_perfect_for-1.png";
import Holidays from "../../../assets/perfect/Picswagger_is_perfect_for-2.png";
import Weddings from "../../../assets/perfect/Picswagger_is_perfect_for-4.png";
import Memories from "../../../assets/perfect/Picswagger_is_perfect_for-5.png";
import Sports from "../../../assets/perfect/Picswagger_is_perfect_for-3.png";
import Boo from "../../../assets/perfect/boo.png";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

const perfectImages = [
  { name: "Birthdays and Parties", title: Birthdays },
  { name: "Holidays / Special Occasions", title: Holidays },
  { name: "Weddings", title: Weddings },
  { name: "Vacation Memories", title: Memories },
  { name: "Sports Teams", title: Sports },
  { name: "Boos & BFFs", title: Boo },
];

export const PerfectSwagger = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  return (
    <div className="container mainDivPerfectSw">
      <>
        <div class="mainBannerSubDiv ">
          {/* col-sm-5 col-md-5 col-lg-4 */}
          <div className="BannerHeading ">
            <h1>
              PicSwagger
              <br /> is perfect for...
              {/* Create Your Own Photo Album With Pic
                <span style={{ color: "#2A5DF0" }}>Swagger</span> */}
            </h1>
            <br />
            <div>
              <span className="heading_p">{perfectImages[value]?.name} </span>
            </div>
            {/* <p>
                Maintaining a focus on meeting the needs of the present without
                compromising the needs of future generations.{" "}
              </p> */}
            <div className="heading_btn_div">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="btn BannerButton mb-3 p-2 px-3"
              >
                order now
                {/* <i className="fa fa-shopping-cart p-1"></i>Order Now */}
              </button>
            </div>
          </div>
          {/* col-sm-12 col-md-7 col-lg-8 */}
          <div className="BannerImg">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              scrollbar={{ draggable: true }}
              modules={[Pagination, Autoplay]}
              onSlideChange={(e) => {
                setValue(e.activeIndex);
              }}
            >
              {perfectImages?.map((record, i) => (
                <SwiperSlide key={i}>
                  <div className="slider_img_div">
                    <img
                      width={"100%"}
                      height={"100%"}
                      className="img-xu"
                      src={record.title}
                      alt="Snow"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </>

      {/* {
      {/* <div className="row">
        <div className="col-sm-4 col-md-4 col-lg-4">
          <h1 className="per-h1">
            <b>PicSwagger is Perfect For :</b>
          </h1>
          <p>
            <Radio.Group
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <Space direction="vertical">
                {perfectImages.map((val, index) => {
                  return (
                    <Radio value={index}>
                      <p>{val.name}</p>
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </p>
          <button onClick={()=>navigate('/orders')} className="btn BannerButton my-4 px-4">
            <i className="fa fa-shopping-cart me-2"></i>Order Now
          </button>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="imgDivSw">
            <img src={perfectImages[value].title} alt="" className="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};
