import React from "react";
import { Accordion } from "./Accordion";

export default function FAQ() {
  return (
    <div style={{ width: "80%" }}>
      <h1
        style={{
          fontFamily: "Groovy Fruity",
          fontSize: "70px",
          textAlign: "center",
        }}
      >
        For the Record: FAQs
      </h1>
      <Accordion />
    </div>
  );
}
