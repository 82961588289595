import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import img404 from "./img404.png";
export default function PageNotFound() {
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <img height={600} src={img404} alt="" />
      </div>
      <div>
        <Link to="/home">
          <Button>Back To Home</Button>
        </Link>
      </div>
    </div>
  );
}
