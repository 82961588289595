import React from "react";
import "./style.css";
export const SwaggerWork = ({ index, icon, title, description }) => {
  return (
    <div className="col-sm-12 col-md-3 col-lg-3">
      {index !== 0 ? (
        <div class="arrow">
          <div class="point"></div>
          <div class="curve"></div>
        </div>
      ) : (
        ""
      )}
      <div
        className="card  border-0 mt-4 px-3"
        style={{ "background-color": "transparent" }}
      >
        {/* <img className="w-25" src={img} class="card-img-top" alt="pic" /> */}
        <div className="icon">
          {/* <i className={icon}></i> */}
          <img src={icon} alt="" />
        </div>
        <div className="card-body">
          <h4 className="card-title m-0">
            <div className="x-desc-div">
              <span style={{ fontSize: "45px", color: "#eca9b7" }}>
                <strong>{index + 1}</strong>
              </span>
              <span className="display-linebreak">
                <b>{title}</b>
              </span>
            </div>
          </h4>
          {/* <p
            className="card-text text-start mt-2"
            style={{ fontSize: "17px", color: "gray" }}
          >
            {description}
          </p> */}
        </div>
      </div>
    </div>
  );
};
