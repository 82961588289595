import { useEffect, useState } from "react";
import axios from "axios";
export default function useUserOrders(trigger) {
  let baseUrl = "https://picswagger-backend.uc.r.appspot.com/";
  // let baseUrl = "http://localhost:5000/";

  const [userOrders, setUserOrders] = useState([]);
  const getData = async () => {
    let sdata = JSON.parse(localStorage.getItem("data"));
    let token = window.atob(sdata?.token);
    let userId = sdata?.data._id;
    let url = `${baseUrl}order/allUserOrders`;
    try {
      const res = await axios.post(url, {
        userId,
        token,
      });
      setUserOrders(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [trigger]);

  return { userOrders };
}
