import React from "react";
import { useState } from "react";
import { Banner } from "../components/Home/Banner/Banner";
import { Gallery } from "../components/Home/InspirationGallery/Gallery";
import { Questions } from "../components/Home/MoreQuestion/Questions";
import { PerfectSwagger } from "../components/Home/PerfectSwagger/PerfectSwagger";
import { ReadyPicSwagger } from "../components/Home/ReadyPicSwagger/ReadyPicSwagger";
import { reviewDetails } from "../components/Home/Review/data";
import { Review } from "../components/Home/Review/Review";
import { SwaggerWorkDetail } from "../components/Home/SwaggerWork/Data";
import { SwaggerWork } from "../components/Home/SwaggerWork/SwaggerWork";
import { PublicLayout } from "../components/PublicLayout/PublicLayout";

import "./pages.scss";
export const Home = () => {
  return (
    <PublicLayout>
      {/* Banner */}
      <Banner />
     
      {/* Swaggger Work */}
      <div className="container swaggerWork work-pages">
        <h1 className="">
          <b>How It Works</b>
        </h1>
        <div className="row text-center">
          {SwaggerWorkDetail?.map((record, index) => (
            <>
              <SwaggerWork
                index={index}
                icon={record.icon}
                title={record.title}
                description={record.description}
              />
            </>
          ))}
        </div>
      </div>
      {/* PerfectSwagger */}
      <div className="container">
        <PerfectSwagger />
      </div>
      {/* Inpspiration Gallery */}
      {/* <Gallery /> */}
      <div className="container">
        {/* REview Card*/}
        <Review />
        {/* More Questions */}
        <Questions />
      </div>
      {/* Ready to Pic Swagger */}
      <ReadyPicSwagger />
    </PublicLayout>
  );
};
