import React, { useEffect } from "react";
import SelectPicSwagger from "../components/Order/SelectPicSwagger/SelectPicSwagger";
import { PublicLayout } from "../components/PublicLayout/PublicLayout";
import { Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { previous } from "../redux/slices/orderSlice";
export const Orders = () => {
  const dispatch = useDispatch();
  const { currentIndex, name, photos, isViewOnly } = useSelector(
    (state) => state.orders
  );

  // console.log(currentIndex);
  const prev = () => {
    dispatch(previous());
  };
  //&& !isViewOnly
  let warn =
    (name !== "" || photos.length > 0) && !isViewOnly && currentIndex < 4;
  window.onbeforeunload = function () {
    if (warn) {
      return "Data will be lost if you leave the page,please save changes first!";
    } else {
      return null;
    }
  };
  useEffect(() => {}, [currentIndex]);
  return (
    <PublicLayout>
      <div className="container">
        <SelectPicSwagger currentIndex={currentIndex} prev={prev} />
      </div>
    </PublicLayout>
  );
};
