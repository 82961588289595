import React from "react";
import { useDispatch } from "react-redux";
import {
  NavLink,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import MyAccount from "../../components/myaccount/account/MyAccount";
import ApproveProjects from "../../components/myaccount/approveprojects/ApproveProjects";
import DraftProject from "../../components/myaccount/draftproject/DraftProject";
import NewAddress from "../../components/myaccount/newaddress/NewAddress";
import MyOrders from "../../components/myaccount/orders/MyOrders";
import PaymentsRecored from "../../components/myaccount/payments/Payments";
import RecipientsAddress from "../../components/myaccount/recipientsaddress/RecipientsAddress";
import RefferalsCommission from "../../components/myaccount/referralscommission/RefferalsCommission";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
import { RiCheckboxCircleLine } from "react-icons/ri";
import {
  saveAddress,
  saveAlbumCoverName,
  saveName,
  savePhoto,
  setCurrentIndex,
  setOrderId,
  setPaymentDetail,
} from "../../redux/slices/orderSlice";
import "./account.scss";
import Influencer from "../../components/myaccount/influencer/Influencer";

function Account() {
  const dispatch = useDispatch();
  const clearReduxData = () => {
    dispatch(saveAddress([]));
    dispatch(saveAlbumCoverName(""));
    dispatch(setCurrentIndex(0));
    dispatch(setOrderId(null));
    dispatch(savePhoto([]));
    dispatch(saveName(""));
    dispatch(setPaymentDetail({}));
  };
  const navigate = useNavigate();
  return (
    <PublicLayout>
      <div className="account">
        <div className="my-account">
          <h5>My Account</h5>
          <NavLink to="my-account" className="item-x">
            Profile
          </NavLink>
          <NavLink to="my-orders" className="item-x">
            Orders
          </NavLink>
          <NavLink to="approve-projects" className="item-x">
            Approved Projects
          </NavLink>
          {/* <NavLink to="draft-projects" className="item-x">
            Draft Projects
          </NavLink> */}
          <NavLink to="payments" className="item-x">
            Payment History
          </NavLink>
          <NavLink to="recipients-address" className="item-x">
            Recipients/Address
          </NavLink>
          <NavLink to="become-influencer" className="item-x">
            <div className="influencer-span">
              <RiCheckboxCircleLine />
              <sapn>Become Influencer</sapn>
            </div>
          </NavLink>
          {/* <NavLink to="refferals-commission" className="item-x">
            Referrals/Commissions
          </NavLink> */}
          <button
            onClick={() => {
              localStorage.clear();
              clearReduxData();
              navigate("/home");
            }}
          >
            Logout
          </button>
        </div>
        <div className="my-account-dashboard">
          <h5>My Account Dashboard</h5>

          <Routes>
            <Route path="my-account" element={<MyAccount />} />
            <Route path="my-orders" element={<MyOrders />} />
            <Route path="approve-projects" element={<ApproveProjects />} />
            <Route path="draft-projects" element={<DraftProject />} />
            <Route path="payments" element={<PaymentsRecored />} />
            <Route path="recipients-address" element={<RecipientsAddress />} />
            <Route
              path="refferals-commission"
              element={<RefferalsCommission />}
            />
            <Route
              path="/recipients-address/new-address"
              element={<NewAddress />}
            />
            <Route path="become-influencer" element={<Influencer />} />
          </Routes>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Account;
