import { Button, Modal, Form, Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import { updateAddress } from "../../../APIS/DatabaseApis";
import FullPageLoading from "../../Loading/FullPageLoading";

const { Option } = Select;
const EditAddressModal = ({
  showModal,
  setShowModal,
  address,
  setupdateTrigger,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data = {
        ...values,
      };
      await updateAddress(address._id, data);
      setupdateTrigger((current) => !current);
      handleCancel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (Object.keys(address).length > 0) {
      form.setFieldsValue({ ...address });
    }
  }, [JSON.stringify(address)]);

  return (
    <>
      <Modal
        title={"Edit Address"}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {loading ? <FullPageLoading /> : ""}
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            // autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please input your city name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please input your state name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Zip"
              name="zip"
              rules={[
                {
                  required: true,
                  message: "Please input your zip code",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select your country" allowClear>
                <Option value="United State">United State</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn-x">
                UPDATE
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditAddressModal;
