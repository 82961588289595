import { Row, Col } from "antd";
import React from "react";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
// import girl2 from "../../assets/inspiration/girl2.png";
import "./inspiration.scss";
import Reviews from "./reviews/Reviews";
function Inspiration() {
  return (
    <PublicLayout>
      <div className="inspirations">
        <div className="top-section">
          <div className="desc">
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <h1>
                  Create Your Own Photo Album With Pic<span>Swagger</span>
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Tempora magnam odio autem sint, officia exercitationem vel
                  illum harum, esse quo
                </p>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                {/* <img src={girl2} /> */}
              </Col>
            </Row>
          </div>
        </div>
        <div className="reviews-section">
          <h3>Reviews That Made Our Day</h3>
          <div className="desc-x">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipis cing elit. Tortor
              morbi turpis felis, faucibus.
            </p>
          </div>
          <Reviews />
        </div>
      </div>
    </PublicLayout>
  );
}

export default Inspiration;
