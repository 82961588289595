import { message } from "antd";
import { jsPDF } from "jspdf";
import axios from "axios";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../firebase";

import Compressor from "compressorjs";
let baseUrl = "https://picswagger-backend.uc.r.appspot.com/";
// let baseUrl = "http://localhost:5000/";
// const baseUrl = "https://picswagger-backend.uc.r.appspot.com/";

//Upload Image
export const uploadImage = async (file, path) => {
  // && file.name !== undefined
  if (file && file !== "") {
    let type = file?.type?.split("/")[1];
    return new Promise((resolve, reject) => {
      const myStorageRef = storageRef(
        storage,
        `${type ? path + "." + type : path + ".png"}`
      );
      const metadata = {
        contentType: file?.type ? file?.type : "image/png",
      };
      const uploadTask = uploadBytesResumable(myStorageRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          alert("check internet connection and try again!");
          console.log(error);
          reject(error);
        },

        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            resolve(url);
          });
        }
      );
    });
  }
};
//Delete images
export const storageUrlToPath = (file) => {
  var pictureItem = file;
  var url_token = pictureItem.split("?");
  var url = url_token[0].split("/");
  var filePath = url[url.length - 1].replaceAll("%2F", "/");
  return filePath;
};
export const deleteStorageFile = (path) => {
  let sRef = storageRef(storage, path);
  deleteObject(sRef);
};
//Resize Image

export const CropImage = (file, url) => {
  return new Promise((resolve, reject) => {
    //  let url = URL.createObjectURL(file);
    var img = document.createElement("img");
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = async function () {
      var width = img.width;
      var height = img.height;
      var canvas = document.createElement("canvas");
      width = width <= 2000 ? width : 2000;
      height = height <= 2000 ? height : 2000;
      width = width > height ? height : width;
      height = width < height ? width : height;
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      var imgSize = Math.min(img.width, img.height);
      var left = (img.width - imgSize) / 2;
      var top = (img.height - imgSize) / 2;
      ctx.drawImage(img, left, top, imgSize, imgSize, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          new Compressor(blob, {
            quality: 0.9,
            convertSize: 1200000,
            success: (result) => {
              console.log((result?.size / 1024).toFixed(2), "kb");
              resolve(result);
            },
          });
          //  console.log((blob?.size / 1024).toFixed(2), "kb");
        }
        //  file.type,
        // (file?.size/1024).toFixed(2)>2000.00? 0.6:0.7
      );
    };
    img.onerror = () => {
      resolve(file);
    };
  });
};
// let arr = [];
// arr[2] = 9;
// console.log(arr);

///save imagse
export const saveImgsToDb = (imgs, dbPath, setProgress) => {
  return new Promise((resolve, reject) => {
    try {
      let arr = [];
      imgs?.map(async (img, i) => {
        if (img.file && !img.uploadedAt && img.uploadedAt === undefined) {
          let d = Math.floor(Math.random() * 90000) + 10000;
          let path = `${dbPath}/${d}`;
          let cropImgFile = await CropImage(img.file, img.url);
          let url = await uploadImage(cropImgFile, path);
          arr.push({ id: i, url, uploadedAt: new Date() });
        } else {
          arr.push({ id: i, ...img });
        }
        setProgress(((100 / imgs.length) * arr.length).toFixed(2));
        if (imgs.length === arr.length) {
          setProgress(100);
          resolve(arr);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
export const updateUser = (data) => {
  return new Promise(async (resolve, reject) => {
    console.log(data);
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let id = sdata?.data._id;
      let url = `${baseUrl}user/updateUser`;
      const res = await axios.post(url, {
        data,
        id,
        token,
      });
      let resp = res?.data;

      if (resp) {
        console.log(resp);
        localStorage.setItem(
          "data",
          JSON.stringify({
            ...sdata,
            data: resp?.data,
          })
        );
        resolve(resp);
      }

      // localStorage.setItem("data", JSON.stringify({ data: resp.data }));

      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};

export const addNewOrder = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      const res = await axios.post(`${baseUrl}order/addOrder`, {
        ...data,
        token,
      });
      resolve(res.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};
export const updateOrder = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}order/updateOrder`;
      // let headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `JWT ${token}`,
      // };
      // let body = JSON.stringify({...data,i});
      // const res = await axios({
      //   method: "post",
      //   url: url,
      //   data: body,
      //   headers: headers,
      // });
      const res = await axios.post(`${baseUrl}order/updateOrder`, {
        ...data,
        id,
        token,
      });
      resolve(res.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};
export const deleteOrder = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      const res = await axios.post(`${baseUrl}order/removeOrder`, {
        id,
        token,
      });
      if (!data.duplicate) {
        await Promise.all(
          data?.albumImages?.map((v) => {
            deleteStorageFile(storageUrlToPath(v?.url));
          })
        );
      }

      resolve(res.data);
      message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};

export const addAddress = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let userId = sdata?.data._id;
      const res = await axios.post(`${baseUrl}address/addAddress`, {
        ...data,
        token,
        userId,
      });
      resolve(res.data);
      message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};
export const updateAddress = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}address/updateAddress`;
      const res = await axios.post(url, {
        ...data,
        id,
        token,
      });
      resolve(res.data);
      message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};

export const deleteAddress = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}address/removeAddress`;
      const res = await axios.post(url, {
        id,
        token,
      });
      resolve(res.data);
      message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};
export const createCustomer = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let id = sdata?.data._id;
      let url = `${baseUrl}stripe/createCustomer`;
      const res = await axios.post(url, {
        token,
        data,
        id,
      });
      await updateUser({ customerId: res.data.customer });
      localStorage.setItem(
        "data",
        JSON.stringify({
          ...sdata,
          data: { ...sdata?.data, customerId: res.data.customer },
        })
      );

      resolve(res.data.customer);

      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};

export const createPaymentIntent = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}stripe/createPaymentIntent`;
      const res = await axios.post(url, {
        token,
        data,
      });

      resolve(res.data);

      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};
export const getPaymentMethods = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let customer = sdata?.data?.customerId;
      let url = `${baseUrl}stripe/retrievePaymentMethods`;
      const res = await axios.post(url, {
        token,
        data: {
          customer,
          type: "card",
        },
      });
      resolve(res.data?.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      // message.error(error.message);
      // message.error("something went wrong!");
    }
  });
};
export const getAllProducts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // let sdata = JSON.parse(localStorage.getItem("data"));
      // let token = window.atob(sdata?.token);
      let url = `${baseUrl}product/allProducts`;
      // let headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `JWT ${token}`,
      // };
      const res = await axios.get(url);
      resolve(res.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
      // message.error("something went wrong!");
    }
  });
};
export const getPayments = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let customer = sdata?.data?.customerId;
      let url = `${baseUrl}stripe/retrievePayments`;
      const res = await axios.post(url, {
        token,
        data: {
          customer,
        },
      });
      resolve(res.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
      // message.error("something went wrong!");
    }
  });
};

export const zenDirectPlaceOrderApi = (data) => {
  // console.log("hello");
  console.log("data to zendirect is ", data);
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}order/placeorderToZenDirect`;
      const res = await axios.post(url, {
        token,
        data: data,
      });
      let resp = JSON.parse(res.data);
      if (resp.Message) {
        message.error(resp.Message);
        reject(resp.Message);
      } else {
        resolve(resp);
      }

      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
      // message.error("something went wrong!");
    }
  });
};
export const backgroundPlaceOrderapi = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let user = sdata?.data;
      let url = `${baseUrl}order/backgroundPlaceOrder`;
      const res = await axios.post(url, {
        token,
        data,
        user,
      });
      let resp = res.data;
      if (res.status == 200 || res.status == 201) {
        resolve(resp);
      } else {
        console.log("bkapi", res.data);
        reject(res.data);
      }
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
      // message.error("something went wrong!");
    }
  });
};
export const getCoupon = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}coupon/useCoupon`;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      };
      const res = await axios({
        method: "post",
        url: url,
        headers: headers,
        data: {
          code: code,
          userId: sdata.data._id,
        },
      });
      if (res.status === 200 || res.status === 201) {
        if (res.data) {
          // console.log(res.data, "couopon data is ");
          resolve(res.data);
        } else {
          reject("Coupon Invalid!");
          message.error("Invalid Coupon!");
        }
      } else {
        console.log(res.status);
        reject(res.statusText);
      }

      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(
        error.response?.data?.message
          ? error.response?.data?.message ===
            "No such coupon: 'TESTIT101'; a similar object exists in live mode, but a test mode key was used to make this request."
            ? "Coupon Expire"
            : error.response?.data?.message
          : "invalid coupon"
      );
    }
  });
};
// export const getCoupon = (code) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let sdata = JSON.parse(localStorage.getItem("data"));
//       let token = window.atob(sdata?.token);
//       let url = `${baseUrl}stripe/retrieveCoupon?code=${code}`;
//       let headers = {
//         "Content-Type": "application/json",
//         Authorization: `JWT ${token}`,
//       };
//       const res = await axios({
//         method: "get",
//         url: url,
//         headers: headers,
//       });
//       if (res.status === 200 || res.status === 201) {
//         if (res.data.valid) {
//           resolve(res.data);
//         } else {
//           reject("Coupon Invalid!");
//           message.error("Invalid Coupon!");
//         }
//       } else {
//         console.log(res.status);
//         reject(res.statusText);
//       }

//       // message.success("Successfully Done!");
//     } catch (error) {
//       console.log(error);
//       reject(error);
//       message.error(
//         error.response?.data?.message
//           ? error.response?.data?.message ===
//             "No such coupon: 'TESTIT101'; a similar object exists in live mode, but a test mode key was used to make this request."
//             ? "Coupon Expire"
//             : error.response?.data?.message
//           : "invalid coupon"
//       );
//     }
//   });
// };
export const getAllCoupons = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let url = `${baseUrl}stripe/retrieveAllCoupon`;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      };
      const res = await axios({
        method: "get",
        url: url,
        headers: headers,
      });
      console.log(res.data);
      resolve(res.data);
      // message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error);
      message.error(error.message);
    }
  });
};
export const urlToImage = (imgs) => {
  return new Promise((resolve, reject) => {
    let imgsArr = [];
    imgs.map(async (v, i) => {
      var img = new Image();
      img.src = v.url;
      img.onload = async function () {
        imgsArr.push({ id: v.id, img: img, url: v.url });
        if (imgsArr.length === imgs.length) {
          resolve(imgsArr);
        }
      };
    });
  });
};
export const ImgsToPdf = async (photos, orderNumber, dispatch, filepath) => {
  return new Promise(async (resolve, reject) => {
    // let d = Math.floor(Math.random() * 9000000) + 1000000;
    let path = filepath ? filepath : `albumImagesPdf/${orderNumber}`;
    let pageNumber = 0;
    try {
      let imgs = await urlToImage(photos);
      const doc = new jsPDF("p", "in", [8.25, 8.25], true);
      imgs
        ?.sort((a, b) => a.id - b.id)
        .map(async (v, i) => {
          // var img = new Image();
          // img.src = v.url;
          // img.onload = async function () {
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          pageNumber !== 0 && doc.addPage();
          pageNumber = pageNumber + 1;
          // doc.text(`Picswagger:${name}`, 10, 40);
          // doc.text(`Page No: ${i + 1}`, 10, 60);
          // doc.text(`Album Order No: ${v.id + 1}`, 10, 80);
          // doc.textWithLink(`Click To Open Image`, 10, 100, {
          //   url: v.url,
          // });
          doc.addImage(
            v.img,
            (width - 8.25) / 2,
            (height - 8.25) / 2,
            8.25,
            8.25,
            v.url,
            "FAST"
          );
          if (pageNumber === photos.length) {
            let blob = doc.output("blob");
            // doc.save(path + ".pdf");
            let url = await uploadImage(blob, path);
            if (dispatch) {
              dispatch(url);
            }
            resolve(url);
          }
          // };
        });
    } catch (error) {
      reject(error);
    }
  });
};
export const CustomCoverImgsToPdf = async (
  photos,
  orderNumber,
  dispatch,
  filepath
) => {
  return new Promise(async (resolve, reject) => {
    // let d = Math.floor(Math.random() * 9000000) + 1000000;
    let path = filepath ? filepath : `customCoverImagesPdf/${orderNumber}`;
    let pageNumber = 0;
    try {
      let imgs = await urlToImage(photos);
      const doc = new jsPDF("p", "in", [9, 9], true);
      imgs
        ?.sort((a, b) => a.id - b.id)
        .map(async (v, i) => {
          // var img = new Image();
          // img.src = v.url;
          // img.onload = async function () {
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          pageNumber !== 0 && doc.addPage();
          pageNumber = pageNumber + 1;
          // doc.text(`Picswagger:${name}`, 10, 40);
          // doc.text(`Page No: ${i + 1}`, 10, 60);
          // doc.text(`Album Order No: ${v.id + 1}`, 10, 80);
          // doc.textWithLink(`Click To Open Image`, 10, 100, {
          //   url: v.url,
          // });
          doc.addImage(
            v.img,
            (width - 9) / 2,
            (height - 9) / 2,
            9,
            9,
            v.url,
            "FAST"
          );
          if (pageNumber === photos.length) {
            let blob = doc.output("blob");
            // doc.save(path + ".pdf");
            let url = await uploadImage(blob, path);
            if (dispatch) {
              dispatch(url);
            }
            resolve(url);
          }
          // };
        });
    } catch (error) {
      reject(error);
    }
  });
};
export const sendSuccessMail = (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      let sdata = JSON.parse(localStorage.getItem("data"));
      let token = window.atob(sdata?.token);
      let user = sdata?.data;
      let url = `${baseUrl}order/sendOrderSuccessMail`;
      const res = await axios.post(url, {
        user,
        order,
        token,
      });
      resolve(res.data);
      message.success("Successfully Done!");
    } catch (error) {
      console.log(error);
      reject(error);
      message.error(error.message);
    }
  });
};

export const saveInfluencer = async (influencer) => {
  let sdata = JSON.parse(localStorage.getItem("data"));
  let token = window.atob(sdata?.token);
  let url = `${baseUrl}influencer/saveInfluencer`;
  let headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${token}`,
  };
  const res = await axios({
    method: "post",
    url: url,
    headers: headers,
    data: {
      ...influencer,
      userId: sdata.data._id,
    },
  });
  return res.data;
};
export const findInfluencer = async () => {
  let sdata = JSON.parse(localStorage.getItem("data"));
  let token = window.atob(sdata?.token);
  let url = `${baseUrl}influencer/findInfluencer/${sdata.data._id}`;
  let headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${token}`,
  };
  const res = await axios({
    method: "get",
    url: url,
    headers: headers,
  });
  return res.data;
};
