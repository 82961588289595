import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { data } from "./data";
import "./reviews.scss";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Reviews = () => {
  return (
    <div className="reviews">
      <Carousel
        responsive={responsive}
        infinite={true}
        arrows={true}
        className="container"
        renderArrowsOutside={true}
      >
        {data?.map((record) => (
          <div className="card-x">
            <img src={record.img} />
            <div className="desc">
              <h6>{record.comment}</h6>
              <p>@{record.username}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default Reviews;
