import { Input, Radio, Space, Button } from "antd";
import React, { useState, useEffect } from "react";
import "./style.css";
import defalut from "../../../../assets/orders/defalut.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSelect,
  next,
  setProductPrice,
} from "../../../../redux/slices/orderSlice";
import useAllProducts from "../../../hooks/useAllProducts";
import { productDetails } from "./productDetails";
import InfoAlert from "../../../modals/infoAlert/InfoAlert";
import FullPageLoading from "../../../Loading/FullPageLoading";
export const Select = () => {
  const dispatch = useDispatch();
  const { allProducts } = useAllProducts();
  const { select, isViewOnly, productPrice } = useSelector(
    (state) => state.orders
  );
  const [sizeValue, setSizeValue] = useState(0);
  const [loading, setloading] = useState(true);
  const [pageValue, setPageValue] = useState(0);
  const [price, setPrice] = useState(0);
  const [image, setimage] = useState();
  const sizes = [`Classic (8x8")`, `Mini (4x4")`];
  const pages = [16, 20, 24];
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  const clickHandler = async () => {
    if (price > 0) {
      dispatch(
        saveSelect({
          size: sizes[sizeValue],
          page: pages[pageValue],
        })
      );
      dispatch(setProductPrice(price));
      dispatch(next());
    } else {
      setAlertInfo({
        show: true,
        title: "Some Proccessing!",
        msg: "Please wait data fetching...",
      });
      // alert("Please wait data fetching...");
    }
  };

  useEffect(() => {
    if (select.hasOwnProperty("size") && select.hasOwnProperty("page")) {
      setSizeValue(
        sizes.findIndex((v) =>
          v.includes(select?.size?.slice(0, select?.size?.length - 2))
        )
      );
      setPageValue(pages.indexOf(select.page));
    }
  }, [select]);
  
  const resetProduct = () => {
    let obj = allProducts?.find(
      (v) =>
        sizes[sizeValue].includes(v?.size?.slice(0, v?.size?.length - 2)) &&
        pages[pageValue] === v?.pages
    );
    if (obj) {
      setimage(obj?.image);
      setPrice(obj?.price);
    }
  };
  useEffect(() => {
    if (price > 0 || isViewOnly) {
      setloading(false);
    }
    resetProduct();
  }, [JSON.stringify(allProducts), pageValue, sizeValue, price]);

  return (
    <div className="container step1Order">
      <InfoAlert
        title={alertInfo?.title}
        msg={alertInfo.msg}
        setShowInfoAlert={(value) =>
          setAlertInfo({ ...alertInfo, show: value })
        }
        showInfoAlert={alertInfo?.show}
      />
      {loading ? <FullPageLoading /> : ""}
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h1 className="fw-bold">Select PicSwagger</h1>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 img_col">
            {/* <h3 className="fw-bold">Select PicSwagger</h3> */}
            <div className="content">
              <img
                className=""
                width={"100%"}
                height={"100%"}
                src={image ? image : defalut}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-5">
          <div className="contentRight">
            <h5 className="fw-bold">PicSwagger Size</h5>
            <Radio.Group
              onChange={(e) => {
                setSizeValue(e.target.value);
                // resetProduct();
              }}
              value={sizeValue}
            >
              <Space direction="vertical" className="fw-bold">
                <Radio value={0}>Classic (8x8")</Radio>
                <Radio value={1}>Mini (4x4")</Radio>
              </Space>
            </Radio.Group>
            <h5 className="fw-bold mt-3">Number of Pages</h5>
            <Radio.Group
              onChange={(e) => {
                setPageValue(e.target.value);
              }}
              value={pageValue}
            >
              <Space direction="vertical" className="fw-bold">
                <Radio value={0}>16</Radio>
                <Radio value={1}>20</Radio>
                <Radio value={2}>24</Radio>
              </Space>
            </Radio.Group>
            <h5 className="fw-bold mt-3">Product Details</h5>
            <div className="product_detail_div">
              {productDetails
                ?.filter(
                  (v) =>
                    v.size === sizes[sizeValue] && v.pages === pages[pageValue]
                )
                .map((v, i) => {
                  return (
                    <p className="display-linebreak" style={{ color: "gray" }}>
                      {v.detial}
                    </p>
                  );
                })}
            </div>
            {/* <ul style={{ color: "gray" }}>
              <li>8, 8"x8" photos per side (16 in total)</li>
              <li>Covers a ~5 foot area spread out on a table </li>
              <li>Printed on premium 130# paper stock</li>
              <li>Delivered in a hip album cover of your choice</li>
              <li> $16 plus shipping</li>
              <li>
                First-class or Priority shipping available to anywhere in the
                US.
              </li>
              <li>You'll love it or your money back!</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="steps-action">
        <Button
          className="btn-x"
          onClick={() => {
            if (isViewOnly) {
              dispatch(next());
            } else {
              clickHandler();
            }
          }}
        >
          NEXT: ADD PHOTOS
        </Button>
      </div>
    </div>
  );
};
