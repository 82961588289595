import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentIndex: 0,
  name: "",
  albumCoverName: "",
  select: {},
  photos: [],
  address: [],
  yourAddresses: [],
  instaState: false,
  orderId: null,
  isViewOnly: false,
  productPrice: 0,
  paymentDetail: {},
  filePath: null,
  orderNumber: null,
  customAlbumCover: null,
};
const orderSlice = createSlice({
  name: "orders",
  initialState,

  reducers: {
    next: (state, action) => {
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    },
    previous: (state, action) => {
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
      };
    },
    saveSelect: (state, action) => {
      return {
        ...state,
        select: action.payload,
      };
    },
    savePhoto: (state, action) => {
      return {
        ...state,
        photos: [...action.payload],
      };
    },
    saveName: (state, action) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    saveAlbumCoverName: (state, action) => {
      return {
        ...state,
        albumCoverName: action.payload,
      };
    },
    saveAddress: (state, action) => {
      return {
        ...state,
        address: action.payload,
      };
    },
    saveYourAddresses: (state, action) => {
      return {
        ...state,
        yourAddresses: action.payload,
      };
    },
    instaAuthState: (state, action) => {
      return {
        ...state,
        instaState: action.payload,
      };
    },
    setCurrentIndex: (state, action) => {
      return {
        ...state,
        currentIndex: action.payload,
      };
    },
    setOrderId: (state, action) => {
      return {
        ...state,
        orderId: action.payload,
      };
    },
    setIsViewOnly: (state, action) => {
      return {
        ...state,
        isViewOnly: action.payload,
      };
    },
    setProductPrice: (state, action) => {
      return {
        ...state,
        productPrice: action.payload,
      };
    },
    setPaymentDetail: (state, action) => {
      return {
        ...state,
        paymentDetail: action.payload,
      };
    },
    setFilePath: (state, action) => {
      return {
        ...state,
        filePath: action.payload,
      };
    },
    setOrderNumber: (state, action) => {
      return {
        ...state,
        orderNumber: action.payload,
      };
    },
    setCustomAlbumCover: (state, action) => {
      return {
        ...state,
        customAlbumCover: action.payload,
      };
    },
  },
});
export const {
  saveSelect,
  savePhoto,
  next,
  previous,
  saveName,
  saveAlbumCoverName,
  saveAddress,
  saveYourAddresses,
  instaAuthState,
  setCurrentIndex,
  setOrderId,
  setIsViewOnly,
  setProductPrice,
  setPaymentDetail,
  setFilePath,
  setOrderNumber,
  setCustomAlbumCover,
} = orderSlice.actions;
export default orderSlice.reducer;
