import React, { useState, useEffect } from "react";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
import "./products.scss";
// import product from "../../assets/products/product.png";
import useAllProducts from "../../components/hooks/useAllProducts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  saveName,
  savePhoto,
  saveSelect,
  setCurrentIndex,
  setOrderId,
  saveAlbumCoverName,
  saveAddress,
  setProductPrice,
  setFilePath,
  setOrderNumber,
  setPaymentDetail,
} from "../../redux/slices/orderSlice";
// import { longLiveAccessToken } from "../../APIS/API";
import FullPageLoading from "../../components/Loading/FullPageLoading";
import { Empty } from "antd";
function Products() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allProducts } = useAllProducts();
  const [loading, setloading] = useState(true);

  const clearReduxData = () => {
    dispatch(saveAddress([]));
    dispatch(saveAlbumCoverName(""));
    dispatch(setCurrentIndex(0));
    dispatch(setOrderId(null));
    dispatch(savePhoto([]));
    dispatch(saveName(""));
    dispatch(setFilePath(null));
    dispatch(setOrderNumber(null));
    dispatch(setPaymentDetail({}));
    // dispatch(setProductPrice(0));
  };
  const handleSelectProduct = (product) => {
    dispatch(
      saveSelect({
        size: product?.size,
        page: product?.pages,
      })
    );
    dispatch(setProductPrice(product?.price));
    clearReduxData();
    navigate("/orders");
  };
  useEffect(() => {
    if (allProducts.length > 0) {
      setloading(false);
    }
    setTimeout(() => {
      setloading(false);
    }, 10000);
  }, [JSON.stringify(allProducts)]);
  return (
    <PublicLayout>
      {loading ? <FullPageLoading /> : allProducts.length > 0 ? "" : <Empty />}

      <div className="products">
        {/* <h1>Products</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipis cing elit. Tortor morbi
          turpis felis, faucibus.
        </p> */}
        {/* <div><button onClick={longLiveAccessToken} >longlivetoken</button></div> */}
        <h1 className="fw-bold">PicSwagger Classic (8x8" Panels)</h1>

        <div className="pic-standards">
          {allProducts
            ?.filter((v) => v.size === 'Classic (8x8")')
            .sort((a, b) => a.pages - b.pages)
            .map((product, i) => {
              return (
                <div key={i} className="card-x">
                  <img
                    src={product.image}
                    onClick={() => window.open(product.image)}
                    alt=""
                  />
                  <h5 className="fw-bold pt-2">{product?.pages}-Page</h5>
                  {/* <p className="size">
                    Size: {product.size.charAt(product.size.length - 3)}X
                    {product?.pages / 2}”
                  </p> */}
                  {/* <p>{product?.description}</p> */}
                  <button
                    onClick={() => {
                      handleSelectProduct(product);
                    }}
                    className="btn"
                  >
                    Order ${product?.price}
                  </button>
                </div>
              );
            })}
        </div>
        <h1 className="fw-bold">PicSwagger Mini (4x4" Panels)</h1>
        <div className="pic-mini">
          {allProducts
            ?.filter((v) => v.size === 'Mini (4x4")')
            .sort((a, b) => a.pages - b.pages)
            .map((product, i) => {
              return (
                <div key={i} className="card-x">
                  <img
                    src={product.image}
                    onClick={() => window.open(product.image)}
                    alt=""
                  />
                  <h5 className="fw-bold pt-2">{product?.pages}-Page</h5>
                  {/* <p className="size">
                    Size: {product.size.charAt(product.size.length - 5)}X
                    {product?.pages / 2}”
                  </p> */}
                  {/* <p>{product?.description}</p> */}
                  <button
                    onClick={() => {
                      handleSelectProduct(product);
                    }}
                    className="btn"
                  >
                    Order ${product?.price}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </PublicLayout>
  );
}

export default Products;
