import upload from '../../../assets/works/upload.png'
import approve from "../../../assets/works/proof.png";
import album from "../../../assets/works/album.png";
import send from "../../../assets/works/send.png";



export const SwaggerWorkDetail_ = [

  {
    icon: "fa fa-file-image-o",
    title: "1 Upload your Photo / Design on PicSwagger",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
  },
  {
    icon: "fa fa-picture-o",
    title: "2 Select The Cover photo and style",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
  },
  {
    icon: "fa fa-shopping-cart",
    title: "3 Place the order on picswagger",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
  },
  {
    icon: "fa fa-shopping-cart",
    title: "3 Place the order on picswagger",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.",
  },
];

export const SwaggerWorkDetail= [
  {
    id: 1,
    icon: upload,
    title: `Upload your 
    Photos`,
  },
  {
    id: 2,
    icon: approve,
    title: `Approve your 
    PicSwagger`,
  },
  {
    id: 3,
    icon: album,
    title: `Choose your 
    Album Cover`,
  },
  {
    id: 4,
    icon: send,
    title: `Send it to 
    Print`,
  },
];