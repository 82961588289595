import React, { useState, useEffect } from "react";

import "../style.css";
import "./checkout.scss";

import { Popconfirm, message, Button, Radio, Space, Select } from "antd";

import { BiMinus, BiPlus } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAddress,
  savePhoto,
  saveAlbumCoverName,
  saveName,
  saveSelect,
  setCurrentIndex,
  setOrderId,
  setProductPrice,
  setPaymentDetail,
  setFilePath,
  setOrderNumber,
  setIsViewOnly,
  setCustomAlbumCover,
} from "../../../../../redux/slices/orderSlice";
import { useNavigate } from "react-router-dom";
import {
  getCoupon,
  updateOrder,
  zenDirectPlaceOrderApi,
  uploadImage,
  updateOrderStatus,
  backgroundPlaceOrderapi,
  ImgsToPdf,
  sendSuccessMail,
} from "../../../../../APIS/DatabaseApis";
import FullPageLoading from "../../../../Loading/FullPageLoading";
import CheckoutForm from "./CheckoutForm";
import { productIds, upgradesIds, shppingMethods } from "./zendirectData";
import useAllProducts from "../../../../hooks/useAllProducts";
import { coverImages } from "../ChooseAlbum";
import CoverFramSmall from "../CoverFram/CoverFramSmall";
import InfoAlert from "../../../../modals/infoAlert/InfoAlert";
import { jsPDF } from "jspdf";
import { split } from "lodash";
const { Option } = Select;
export const Checkout = () => {
  const { allProducts } = useAllProducts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    address,
    name,
    photos,
    orderId,
    isViewOnly,
    paymentDetail,
    select,
    albumCoverName,
    currentIndex,
    filePath,
    orderNumber,
    customAlbumCover,
  } = useSelector((state) => state.orders);
  let picswaggerName = name;
  const [chngInd, setChngIndex] = useState(-999);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  // const [grandTotal, setgrandTotal] = useState("");

  const [shipping, setShipping] = useState({});
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    title: "",
    msg: "",
  });
  const shippings = [
    {
      label: "USPS First-class",
      charges: shipping?.firstClassCharges ? shipping?.firstClassCharges : 0,
      hint: "shipping typically take 2-3 days",
    },
    {
      label: "USPS Priority",
      charges: shipping?.priorityShippingCharges
        ? shipping?.priorityShippingCharges
        : 0,
      hint: "shipping typically take 1-2 days",
    },
  ];
  const [subTotal, setSubTotal] = useState(
    (
      Math.round(
        address.reduce((acc, val) => {
          acc +=
            val.quantity * val.price +
            shippings[val.shippingValue].charges * val.quantity;
          return acc;
        }, 0) * 100
      ) / 100
    ).toFixed(2)
  );
  const [loading, setloading] = useState(false);
  const plusItem = (item) => {
    let arr = JSON.parse(JSON.stringify(address));
    const index = address.findIndex((x) => x.id == item.id);
    arr[index].quantity++;
    dispatch(saveAddress([...arr]));
    subTotalCalculate([...arr]);
  };
  const minusItem = (item) => {
    let arr = JSON.parse(JSON.stringify(address));
    const index = address.findIndex((x) => x.id == item.id);
    if (arr[index].quantity >= 2) arr[index].quantity--;
    dispatch(saveAddress([...arr]));
    subTotalCalculate([...arr]);
  };
  const handleDeletion = (item) => {
    if (address.length === 1) {
      // alert("atleast one item required for place order");
      let arr = JSON.parse(JSON.stringify(address));
      const index = address.findIndex((x) => x.id == item.id);
      arr.splice(index, 1);
      dispatch(saveAddress([...arr]));
      subTotalCalculate([...arr]);
      dispatch(setCurrentIndex(currentIndex - 1));
    } else {
      let arr = JSON.parse(JSON.stringify(address));
      const index = address.findIndex((x) => x.id == item.id);
      arr.splice(index, 1);
      dispatch(saveAddress([...arr]));
      subTotalCalculate([...arr]);
    }
  };
  const cancel = (e) => {
    message.error("You are cancelled your changes!");
  };
  const onShippingChange = (e, item) => {
    let arr = JSON.parse(JSON.stringify(address));
    const index = address.findIndex((x) => x.id == item.id);
    arr[index].shippingValue = e.target.value;
    dispatch(saveAddress([...arr]));
    subTotalCalculate([...arr]);
    setChngIndex(-99);
  };
  const subTotalCalculate = (arr, shippingCharges) => {
    setSubTotal(
      (
        Math.round(
          arr.reduce((acc, val) => {
            let cahrges = shippingCharges
              ? Object.values(shippingCharges)[val.shippingValue]
              : shippings[val.shippingValue].charges;
            acc += val.quantity * val.price + cahrges * val.quantity;
            return acc;
          }, 0) * 100
        ) / 100
      ).toFixed(2)
    );
  };

  const clearReduxData = () => {
    return new Promise((resolve, reject) => {
      dispatch(saveAddress([]));
      dispatch(saveAlbumCoverName(""));
      dispatch(setCurrentIndex(0));
      dispatch(setOrderId(null));
      dispatch(savePhoto([]));
      dispatch(saveSelect({}));
      dispatch(saveName(""));
      dispatch(setProductPrice(0));
      dispatch(setPaymentDetail({}));
      dispatch(setFilePath(null));
      dispatch(setOrderNumber(null));
      dispatch(setIsViewOnly(false));
      dispatch(setCustomAlbumCover(null));
      resolve("redux clear");
    });
  };
  const handlePlaceOrder = async (payment) => {
    try {
      setloading(true);
      let d = orderNumber;
      let pdfUrl = filePath
        ? filePath
        : await ImgsToPdf(photos, d, (url) => dispatch(setFilePath(url)));
      updateOrder(orderId, { filePath: pdfUrl });
      // address.every((v) => v.order_id)?address:
      let addresses = await zenderictApiData(d, pdfUrl?.split("&token=")?.[0]);
      let data = {
        currentIndex: 5,
        addresses: addresses,
        status: "order",
        // orderStatus: "Pending",
        orderDate: new Date(),
        orderNumber: d,
        payment: payment ? payment : paymentDetail,
        discount,
        coupon,
        filePath: pdfUrl,
        updatedAt: new Date(),
        orderPlaceToZendirect: true,
        totalAmount:
          Math.round(subTotal - discount) > 0
            ? (subTotal - discount).toFixed(2)
            : 0.0,
      };
      // setloading(false);
      // return;
      let order = await updateOrder(orderId, data);

      // send email order success
      await sendSuccessMail({ ...order, subTotal });
      // backgroundPlaceOrderapi(order);
      await clearReduxData();
      // // backgroundPlaceOrderapi(d);
      // localStorage.setItem("reload", "true")p;
      setloading(false);
      navigate("/orders-success/" + d);
      // window.location.reload();
      // window.open(window.location.origin+"/orders-success/" + d,"_self");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  // const backgroundPlaceOrderapi = async (orderNumber) => {
  //   try {
  //     let pdfUrl = await newImgsToPdf();
  //     let addresses = await zenderictApiData(
  //       orderNumber,
  //       pdfUrl?.split("&token=")?.[0]
  //     );
  //     let data = {
  //       addresses: addresses,
  //       filePath: pdfUrl,
  //     };
  //     await updateOrder(orderId, data);
  //     clearReduxData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const zenderictApiData = async (orderNumber, pdfUrl) => {
    return new Promise((resolve, reject) => {
      let productId = select?.size?.includes("Mini")
        ? productIds["Mini"][select?.page]
        : productIds["Classic"][select?.page];
      let upgradeId = upgradesIds[albumCoverName];
      let newAddresses = [];
      address?.map(async (addr, i) => {
        let number = i + 1;
        let modifiedAddress = {
          name: addr.name,
          street: addr.address,
          city: addr.city,
          state: addr.state,
          postalcode: addr.zip,
          country: addr?.country,
        };
        let zenDirectData = {
          partnerOrdernumber: orderNumber.toString() + number,
          lineItems: [
            {
              lineNum: `${1}`,
              quantity: `${addr?.quantity}`,
              productId: productId,
              files: [
                {
                  path: pdfUrl,
                  pageCount: `${photos.length}`,
                },
              ],
              upgrades: [
                {
                  id: upgradeId,
                },
              ],
              variables: [
                {
                  variableName: "orderIndex",
                  variableValue: `${i}`,
                },
                {
                  variableName: "orderId",
                  variableValue: orderId,
                },
              ],
            },
          ],
          returnAddress: modifiedAddress,
          recipients: {
            recipientAddress: [modifiedAddress],
          },
          shipMethod: shppingMethods[shippings[addr?.shippingValue].label],
        };
        if (customAlbumCover) {
          zenDirectData?.lineItems.push({
            lineNum: `${2}`,
            quantity: `${addr?.quantity}`,
            productId: select?.size?.includes("Mini") ? "156" : "157",
            files: [
              {
                path: customAlbumCover?.pdf?.split("&token=")?.[0],
                pageCount: `${2}`,
              },
            ],
            upgrades: [
              {
                id: upgradeId,
              },
            ],
            variables: [
              {
                variableName: "orderIndex",
                variableValue: `${i}`,
              },
              {
                variableName: "orderId",
                variableValue: orderId,
              },
            ],
          });
        }

        try {
          let resp = await zenDirectPlaceOrderApi(zenDirectData);
          console.log(resp);
          newAddresses.push({
            ...addr,
            statusId: resp?.statusId,
            order_id: resp?.order_id,
          });
          if (newAddresses.length === address.length) {
            dispatch(saveAddress([...newAddresses]));
            let order = await updateOrder(orderId, {
              addresses: newAddresses,
              orderPlaceToZendirect: true,
            });
            // console.log(order);

            resolve(newAddresses);
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    });
  };

  useEffect(() => {
    if (allProducts?.length > 0) {
      let obj = allProducts?.find(
        (v) =>
          select?.size.includes(v?.size?.slice(0, v?.size?.length - 2)) &&
          select?.page === v?.pages
      );
      if (obj) {
        let shippingData = {
          firstClassCharges: obj?.fstShippingPrice.toFixed(2),
          priorityShippingCharges: obj?.priorityShippingPrice.toFixed(2),
        };
        setShipping(shippingData);
        subTotalCalculate([...address], shippingData);
      }
    }
  }, [
    JSON.stringify(allProducts),
    JSON.stringify(select),
    JSON.stringify(address),
  ]);

  return (
    <>
      {loading ? <FullPageLoading /> : ""}
      <div className="checkout">
        <InfoAlert
          title={alertInfo?.title}
          msg={alertInfo.msg}
          setShowInfoAlert={(value) =>
            setAlertInfo({ ...alertInfo, show: value })
          }
          showInfoAlert={alertInfo?.show}
        />

        {address.length > 0
          ? address.map((item, index) => {
              const {
                name,
                address,
                city,
                state,
                country,
                zip,
                quantity,
                price,
                shippingValue,
              } = item;
              return (
                <div className="orderSummary">
                  <div className="address">
                    <p>
                      <span className="span">Ship-To Recipent:</span>&nbsp;{" "}
                      {name}-{address} {city} {state} {country} {zip}
                    </p>
                  </div>
                  <div className="address-content">
                    <div className="address-card">
                      <div className="imgs">
                        {/* <img
                        src={
                          coverImages.find((v) => v.name === albumCoverName)
                            ?.img
                        }
                      /> */}
                        <CoverFramSmall
                          coverImg={
                            customAlbumCover
                              ? customAlbumCover?.front
                              : coverImages.find(
                                  (v) => v.name === albumCoverName
                                )?.img
                          }
                          albumImg={photos[0]?.url}
                        />
                        <h5>{picswaggerName}</h5>
                      </div>
                      <div className="counters">
                        <BiMinus
                          className="icon"
                          onClick={() => minusItem(item)}
                        />
                        <h6>{quantity}</h6>
                        <BiPlus
                          className="icon"
                          onClick={() => plusItem(item)}
                        />
                      </div>
                      <div className="prices">
                        <h5>
                          $
                          {(Math.round(price * quantity * 100) / 100).toFixed(
                            2
                          )}
                        </h5>
                        <Popconfirm
                          title="Are you sure about the delete action?"
                          onConfirm={() => {
                            handleDeletion(item);
                          }}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="left"
                        >
                          <p>
                            <RiDeleteBinLine className="icon" />
                            delete
                          </p>
                        </Popconfirm>
                      </div>
                    </div>
                    {customAlbumCover ? (
                      <div className="address-card">
                        <h5 className="text-primary">
                          <b>
                            {select?.size}{" "}
                            {!!customAlbumCover
                              ? "with Custom Album Cover"
                              : ""}
                          </b>
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* ADDRESS ROW */}
                    <div className="address-change-x">
                      {chngInd == index ? (
                        <div className="imgs">
                          <h5 className="fw-bold">Select Shipping Service</h5>
                          <Radio.Group
                            onChange={(e) => onShippingChange(e, item)}
                          >
                            <Space direction="vertical">
                              <Radio value={0}>
                                <b>USPS First-class</b> (shipping typically
                                takes 2-3 days)
                              </Radio>
                              <Radio value={1}>
                                <b>USPS Priority</b> (shipping typically takes
                                1-2 days)
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                      ) : (
                        <div className="imgs">
                          <h5
                            style={{
                              color: "#ea6a45",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setChngIndex(index)}
                          >
                            Change
                          </h5>
                          <div className="address-change">
                            <h5>Selected Shipping Service</h5>
                            <span>
                              (Typically Arrives in{" "}
                              {shippingValue === 0 ? "3-5" : "1-3"} business
                              days)
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="counters">
                        <h5>{shippings[shippingValue].label}</h5>
                      </div>
                      <div className="prices">
                        <h5>
                          $
                          {(
                            shippings[shippingValue].charges * quantity
                          ).toFixed(2)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        {/* <div>
        <button
          onClick={async() => {
            let resp = await updateOrderStatus();
            console.log(resp);
           
          }}
        >
          orederStatus
        </button>
      </div> */}
        <div className="orderSummary osm-hex">
          <h4>Add Promo or Coupon Code</h4>
          <div className="inpt">
            <input
              type="text"
              placeholder="Promo Code"
              // value={coupon}
              onChange={(e) => setCoupon(e.target.value.toUpperCase())}
            />
            <button
              disabled={coupon === ""}
              onClick={async () => {
                try {
                  setloading(true);
                  let res = await getCoupon(coupon);
                  if (res.status) {
                    let discnt = (res?.data?.amount / 100).toFixed(2);
                    setDiscount(discnt);
                    setAlertInfo({
                      show: true,
                      title: "Congratulations!",
                      msg: `$${
                        res?.data.amount / 100
                      } coupon code successfully applied.`,
                    });
                  } else {
                    setAlertInfo({
                      show: true,
                      title: "Sorry!",
                      msg: res.msg,
                    });
                  }

                  // setgrandTotal(subTotal - discnt > 0? subTotal - discnt:0);

                  setloading(false);
                } catch (error) {
                  setloading(false);
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="orderSummary osm-hex">
          <div className="final">
            <h6 className="fw-bold">Subtotal</h6>
            <h6 className="fw-bold">${subTotal}</h6>
          </div>
          <div className="final">
            <h6 className="fw-bold">
              Discount (
              {Math.round((discount / subTotal) * 100).toFixed(2) > 100
                ? 100.0
                : Math.round((discount / subTotal) * 100).toFixed(2)}
              %)
            </h6>
            <h6 className="fw-bold">${discount}</h6>
          </div>
          <div className="final">
            <h6 className="fw-bold">Tax</h6>
            <h6 className="fw-bold">$0</h6>
          </div>
          <hr />
          <div className="final">
            <h4 className="fw-bold">Total</h4>
            <h4 className="fw-bold">
              <span>
                $
                {(subTotal - discount).toFixed(2) > 0
                  ? (subTotal - discount).toFixed(2)
                  : 0.0}
              </span>
            </h4>
          </div>
        </div>

        {(Object.keys(paymentDetail).length > 0 ||
          isViewOnly ||
          (subTotal - discount).toFixed(2) <= 0.0) && (
          <div className="steps-action" style={{ marginTop: "1px" }}>
            <Button
              onClick={async () => {
                if (isViewOnly) {
                  await clearReduxData();
                  navigate("/account/my-orders");
                } else {
                  handlePlaceOrder();
                }
                // handlePlaceOrder();
              }}
              className="btn-x"
              style={{ width: "150px", borderRadius: "10px" }}
            >
              {isViewOnly ? "BACK TO ORDERS" : "PLACE ORDER"}
            </Button>
          </div>
        )}

        {!isViewOnly &&
          Object.keys(paymentDetail).length === 0 &&
          (subTotal - discount).toFixed(2) > 0 && (
            <div>
              <CheckoutForm
                amount={
                  Math.round(subTotal - discount) > 0
                    ? subTotal - discount
                    : 0.0
                }
                placeOrder={handlePlaceOrder}
              />
            </div>
          )}
        {/* <div>
          {success == true ? (
            <>
              <Button className="btn-x" onClick={keepOrder}>
                keep order
              </Button>
              <Button className="btn-x" onClick={gohome}>
                Go Home
              </Button>
            </>
          ) : (
            ""
          )}
        </div> */}
      </div>
    </>
  );
};
