import React from "react";
import "./commission.scss";
import { Row, Col } from "antd";

function RefferalsCommission() {
  const addrs = [
    {
      id: 1,
      name: "Jason Roy",
      orderDate: "10/31/2022",
      total: 55,
      commPerc: 20,
      commDol: 11,
    },
    {
      id: 2,
      name: "Andrew Symonds",
      orderDate: "10/31/2022",
      total: 55,
      commPerc: 20,
      commDol: 11,
    },
    {
      id: 3,
      name: "Alex Henry",
      orderDate: "10/31/2022",
      total: 55,
      commPerc: 20,
      commDol: 11,
    },
    {
      id: 4,
      name: "Liza Homer",
      orderDate: "10/31/2022",
      total: 55,
      commPerc: 20,
      commDol: 11,
    },
  ];
  return (
    <div className="commission-x">
      <h5>My Referrals / Commissions</h5>
      <div className="your-reff">
        <h5>Your Referral:</h5>
        <input type="text" value="XYZ1234" />
      </div>
      <div className="desc">
        <p>
          Use this referral code to Share PicSwagger with your friends and
          family. You'll earn 20% on any referrals first order.
          <br />
          And, your referrals also get 20% off the printing on their 1st order.
        </p>
      </div>
      <h5 className="my-comm">My Commission</h5>
      <div className="addresses">
        <Row>
          <Col xs={7} sm={7} lg={7}>
            <b>Name</b>
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <b>Order Date</b>
          </Col>
          <Col xs={5} sm={5} lg={5}>
            <b>Printing Total</b>
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <b>Commission %</b>
          </Col>
          <Col xs={4} sm={4} lg={4}>
            <b>Commission $</b>
          </Col>
        </Row>

        {addrs.map((itm, indx) => {
          return (
            <Row className="py-3 border-bottom">
              <Col xs={7} sm={7} lg={7}>
                {itm.name}
              </Col>
              <Col xs={4} sm={4} lg={4}>
                {itm.orderDate}
              </Col>
              <Col xs={5} sm={5} lg={5}>
                {itm.total}
              </Col>
              <Col xs={4} sm={4} lg={4}>
                {itm.commPerc}%
              </Col>
              <Col xs={4} sm={4} lg={4}>
                {itm.commDol}$
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}

export default RefferalsCommission;
