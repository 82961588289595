import React from "react";
import { footerItem } from "./data";
import { FooterComponent } from "./FooterComponent";
import logo from "../../assets/logo/logo.png";
import "./style.css";
import { RiFacebookCircleLine } from "react-icons/ri";
import { TbBrandTwitter } from "react-icons/tb";
import { AiOutlineInstagram } from "react-icons/ai";
import { ImPinterest2 } from "react-icons/im";
import { FiYoutube } from "react-icons/fi";
import { TbBrandTiktok } from "react-icons/tb";
import { Link } from "react-router-dom";
export const Footer = () => {
  
  return (
    <div className="footer row footer-x">
      <div className="col-sm-12 col-md-3 col-lg-3">
        <img src={logo} alt="Picswagger" className="img-x" />
        <div className="icons-x">
          <a
            href=" https://www.facebook.com/profile.php?id=100088519580151"
            target="_blank"
          >
            <RiFacebookCircleLine className="icons" />
          </a>

          <a href="https://twitter.com/PicSwagger" target="_blank">
            <TbBrandTwitter className="icons" />
          </a>

          <a href="https://www.instagram.com/picswagger/" target="_blank">
            <AiOutlineInstagram className="icons" />
          </a>

          <a href=" https://www.pinterest.com/picswagger/" target="_blank">
            <ImPinterest2 className="icons" />
          </a>
          <a href="https://www.tiktok.com/@picswagger" target="_blank">
            <TbBrandTiktok className="icons" />
          </a>
          <a
            href=" https://www.youtube.com/channel/UCcOqFloRDEpCX355FzqmU0Q"
            target="_blank"
          >
            <FiYoutube className="icons" />
          </a>
        </div>
      </div>
      {/* {footerItem?.map((record) => (
        <FooterComponent
          title={record.title}
          item1={record.item1}
          item2={record.item2}
          item3={record.item3}
          item4={record.item4}
          item5={record.item5}
        />
      ))} */}
      {/* <hr className="mt-3 mb-3" /> */}

      {footerItem?.map((record) => (
        <FooterComponent title={record.title} items={record.items} />
      ))}
      <p
        className="text-center"
        style={{ fontSize: "20px", color: "gray", marginTop: "50px" }}
      >
        @ Copyright {new Date().getFullYear()} PicSwagger
      </p>
    </div>
  );
};
