import React from "react";
import FAQ from "../../components/FAQ/FAQ";
import { PublicLayout } from "../../components/PublicLayout/PublicLayout";
import "./tutorial.scss";
function Tutorial() {
  return (
    <PublicLayout>
      <div className="tutorial">
    

        <FAQ />
        {/* <h1>Hello tutorials page</h1> */}
      </div>
    </PublicLayout>
  );
}

export default Tutorial;
