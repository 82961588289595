import { PublicLayout } from "../../../components/PublicLayout/PublicLayout";
import "./signin.scss";
import React, { useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import { userSuccess } from "../../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import loginLogo from "../../../assets/login/loginLogo.png";
import ForgotPasswordModal from "../../../components/modals/forgotPassword/ForgotPasswordModal";
import axios from "axios";
function Signin() {
  const navigate = useNavigate();
  const [isEye, setIsEye] = useState(true);
  const [loadingX, setLoadingX] = useState(false);
  const [error, setError] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email format")
      .required("Email is Required"),
    password: Yup.string().required("Password is Required").min(8),
  });
  const { currentIndex } = useSelector((state) => state.orders);
  const onSubmit = async () => {
    if (formik.values.email && formik.values.password) {
      setLoadingX(true);
      // console.log("formik values ", formik.values);
      try {
        const res = await axios.post(
          "https://picswagger-backend.uc.r.appspot.com/user/signin",
          { ...formik.values }
        );
        setLoadingX(false);
        const user = res.data;
        // console.log("user data is ", user);
        let token = window.btoa(user.token);
        localStorage.setItem(
          "data",
          JSON.stringify({ data: user.user, token: token })
        );
        navigate(currentIndex > 0 ? "/orders" : "/home");
        // console.log("user loged data is ", user);
      } catch (e) {
        setLoadingX(false);
        setError(e?.response?.data?.message);
        console.log(e);
      }
      // signInWithEmailAndPassword(
      //   auth,
      //   formik.values.email,
      //   formik.values.password
      // )
      //   .then((result) => {
      //     const user = result.user;
      //     if (user) {
      //       dispatch(userSuccess(user));
      //       setLoadingX(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setLoadingX(false);
      //   });
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <PublicLayout>
      <ForgotPasswordModal
        showForgotPasswordModal={showForgotPasswordModal}
        setShowForgotPasswordModal={setShowForgotPasswordModal}
      />
      <div className="signin-x">
        {loadingX && <FullPageLoading />}
        <div className="login-form">
          <img src={loginLogo} alt="" />
          {/* <h2>
            <b>Hello Again!</b>
          </h2> */}
          <h2>Login or create a new account to continue.</h2>
          {error && (
            <span id="error">
              <b>Error: </b>
              {error}
            </span>
          )}
          <form className="formm" onSubmit={formik.handleSubmit}>
            <div className="fields">
              <input
                type="email"
                className="field-email"
                placeholder="Enter your Email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div id="error">{formik.errors.email}</div>
              ) : null}
              <UserOutlined className="icon-user" />
              <div className="password-field">
                <input
                  type={isEye ? "password" : "text"}
                  className="field-password"
                  name="password"
                  placeholder="Enter your password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div id="error">{formik.errors.password}</div>
                ) : null}
                {isEye ? (
                  <AiFillEye
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="icon-eye"
                    onClick={() => setIsEye(!isEye)}
                  />
                )}
                <LockOutlined className="icon-lock" />
              </div>
            </div>
            <p
              className="forgot"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Forgot Password?
            </p>
            <div className="btn">
              <button type="submit">Sign In </button>
            </div>
          </form>
          <div>
            <p onClick={() => navigate("/signup")} className="create_acct_p">
              <b>
                Or, <u>Create new account </u>
              </b>
            </p>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Signin;
