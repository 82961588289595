import React, { useState, useEffect } from "react";
import "./approve.scss";
// import gift from "../../../assets/checkout/gift.png";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
// import { RiFacebookCircleLine } from "react-icons/ri";
// import { TbBrandTwitter } from "react-icons/tb";
// import { AiOutlineInstagram } from "react-icons/ai";
// import { ImPinterest2 } from "react-icons/im";
// import { FiYoutube } from "react-icons/fi";
// import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { Empty, Popconfirm } from "antd";
import useUserOrders from "../../hooks/useUserOrders";
import {
  saveName,
  savePhoto,
  saveSelect,
  setCurrentIndex,
  setOrderId,
  saveAlbumCoverName,
  saveAddress,
  setProductPrice,
  setPaymentDetail,
  setIsViewOnly,
  setFilePath,
  setOrderNumber,
  setCustomAlbumCover,
} from "../../../redux/slices/orderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { addNewOrder, deleteOrder } from "../../../APIS/DatabaseApis";
import { coverImages } from "../../Order/SelectPicSwagger/Content/ChooseAlbum";
import FullPageLoading from "../../Loading/FullPageLoading";
function ApproveProjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateTrigger, setupdateTrigger] = useState(false);
  const { userOrders } = useUserOrders(updateTrigger);
  const [loading, setloading] = useState(true);
  const editHandler = (order) => {
    dispatch(
      saveSelect({
        size: order.size,
        page: order.pages,
      })
    );
    dispatch(setIsViewOnly(false));
    dispatch(savePhoto(JSON.parse(JSON.stringify(order.albumImages))));
    dispatch(saveName(order.albumName));
    dispatch(setOrderId(order._id));
    dispatch(setCurrentIndex(order.currentIndex));
    dispatch(setProductPrice(order.productPrice));
    order?.albumCoverName &&
      dispatch(saveAlbumCoverName(order?.albumCoverName));
    dispatch(saveAddress(order?.addresses ? order?.addresses : []));
    dispatch(setPaymentDetail(order?.payment ? order?.payment : {}));
    dispatch(setFilePath(order?.filePath ? order?.filePath : null));
    dispatch(setOrderNumber(order?.orderNumber ? order?.orderNumber : null));
    dispatch(
      setCustomAlbumCover(
        order?.customAlbumCover
          ? {
              front: order?.customAlbumCover?.front,
              back: order?.customAlbumCover?.back,
              pdf: order?.customAlbumCover?.pdf,
            }
          : null
      )
    );
    navigate("/orders"); //////
  };
  const clearReduxData = () => {
    dispatch(saveAddress([]));
    dispatch(saveAlbumCoverName(""));
    dispatch(setCurrentIndex(0));
    dispatch(setOrderId(null));
    dispatch(savePhoto([]));
    dispatch(saveName(""));
    dispatch(setPaymentDetail({}));
    dispatch(setFilePath(null));
    dispatch(setOrderNumber(null));
    dispatch(setCustomAlbumCover(null));
  };
  // const duplicateHandle = async (item) => {
  //   try {
  //     let nameLength = userOrders?.filter((v) =>
  //       v.albumName.includes(item.albumName)
  //     ).length;
  //     delete item._id;
  //     let data = {
  //       ...item,
  //       duplicate: true,
  //       albumName: item.albumName + `(${nameLength})`,
  //     };
  //     await addNewOrder(data);
  //     setupdateTrigger(!updateTrigger);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const deleteHandle = async (item) => {
    try {
      await deleteOrder(item?._id, item);
      clearReduxData();
      setupdateTrigger(!updateTrigger);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (userOrders.length > 0) {
      setloading(false);
    }
    setTimeout(() => {
      setloading(false);
    }, 10000);
  }, [JSON.stringify(userOrders)]);

  return (
    <div className="approve-xyz">
      <h5>ORDERS</h5>
      {loading ? (
        <FullPageLoading />
      ) : userOrders?.filter((v) => v.status === "approve").length > 0 ? (
        ""
      ) : (
        <Empty />
      )}
      <div className="orders-x">
        {userOrders
          ?.filter((v) => v.status === "approve")
          ?.map((order, i) => {
            return (
              <div key={i} className="o-card">
                <div className="main-content">
                  <div className="o-img">
                    <img
                      width={150}
                      height={150}
                      src={
                        order?.customAlbumCover
                          ? order?.customAlbumCover?.front
                          : coverImages.find(
                              (v) =>
                                order?.albumCoverName &&
                                v.name === order?.albumCoverName
                            )?.img || coverImages[0]?.img
                      }
                    />
                  </div>
                  <div className="o-date">
                    <h6>Order Number</h6>
                    <p>{order?.orderNumber}</p>
                  </div>
                  <div
                    onClick={() => {
                      editHandler(order);
                    }}
                    className="o-btn"
                  >
                    <button>Order</button>
                  </div>
                  <div className="o-options">
                    <h6>Options</h6>
                    <div className="icons">
                      <div
                        onClick={() => {
                          editHandler(order);
                        }}
                        className="icon-box"
                      >
                        <BiEdit className="icon" />
                        <span>Edit</span>
                      </div>
                      {/* <div className="icon-box">
                      <BiDuplicate className="icon" />
                      <span>Duplicate</span>
                    </div> */}
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => {
                          deleteHandle(order);
                        }}
                      >
                        <div className="icon-box">
                          <RiDeleteBinLine className="icon" />
                          <span>Delete</span>
                        </div>
                      </Popconfirm>
                    </div>
                  </div>
                  {/* <div className="o-share">
                  <h6>Order Status</h6>
                  <p className="share-link">
                    <span>Share Link:</span>
                    <span className="link">bitlyxyz.com</span>
                    <HiOutlineDocumentDuplicate className="icon" />
                  </p>
                  <p className="share-link">
                    <span>Social Share:</span>
                    <RiFacebookCircleLine className="icon" />
                    <AiOutlineInstagram className="icon" />
                    <ImPinterest2 className="icon" />
                  </p>
                  <p className="chk">
                    <Checkbox>Allow anyone with link to order</Checkbox>
                  </p>
                  <p className="chk">
                    <Checkbox
                      style={{
                        width: "250px",
                      }}
                    >
                      Allow anyone with this link to use as template and make
                      changes
                    </Checkbox>
                  </p>
                </div> */}
                </div>
                <p>{`${order.albumName}/${
                  order?.albumCoverName
                    ? order?.albumCoverName
                    : coverImages[0]?.name
                }`}</p>
              </div>
            );
          })}

        {/* <div className="o-card">
          <div className="main-content">
            <div className="o-img">
              <img src={gift} />
            </div>
            <div className="o-btn">
              <button>Order</button>
            </div>
            <div className="o-options">
              <h6>Options</h6>
              <div className="icons">
                <div className="icon-box">
                  <BiEdit className="icon" />
                  <span>Edit</span>
                </div>
                <div className="icon-box">
                  <BiDuplicate className="icon" />
                  <span>Duplicate</span>
                </div>
                <div className="icon-box">
                  <RiDeleteBinLine className="icon" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
            <div className="o-share">
              <h6>Order Status</h6>
              <p className="share-link">
                <span>Share Link:</span>
                <span className="link">bitlyxyz.com</span>
                <HiOutlineDocumentDuplicate className="icon" />
              </p>
              <p className="share-link">
                <span>Social Share:</span>
                <RiFacebookCircleLine className="icon" />
                <AiOutlineInstagram className="icon" />
                <ImPinterest2 className="icon" />
              </p>
              <p className="chk">
                <Checkbox>Allow anyone with link to order</Checkbox>
              </p>
              <p className="chk">
                <Checkbox
                  style={{
                    width: "250px",
                  }}
                >
                  Allow anyone with this link to use as template and make
                  changes
                </Checkbox>
              </p>
            </div>
          </div>
          <p>PicSwagger w/ stock album cover</p>
        </div>
        <div className="o-card">
          <div className="main-content">
            <div className="o-img">
              <img src={gift} />
            </div>
            <div className="o-btn">
              <button>Order</button>
            </div>
            <div className="o-options">
              <h6>Options</h6>
              <div className="icons">
                <div className="icon-box">
                  <BiEdit className="icon" />
                  <span>Edit</span>
                </div>
                <div className="icon-box">
                  <BiDuplicate className="icon" />
                  <span>Duplicate</span>
                </div>
                <div className="icon-box">
                  <RiDeleteBinLine className="icon" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
            <div className="o-share">
              <h6>Order Status</h6>
              <p className="share-link">
                <span>Share Link:</span>
                <span className="link">bitlyxyz.com</span>
                <HiOutlineDocumentDuplicate className="icon" />
              </p>
              <p className="share-link">
                <span>Social Share:</span>
                <RiFacebookCircleLine className="icon" />
                <AiOutlineInstagram className="icon" />
                <ImPinterest2 className="icon" />
              </p>
              <p className="chk">
                <Checkbox>Allow anyone with link to order</Checkbox>
              </p>
              <p className="chk">
                <Checkbox
                  style={{
                    width: "250px",
                  }}
                >
                  Allow anyone with this link to use as template and make
                  changes
                </Checkbox>
              </p>
            </div>
          </div>
          <p>PicSwagger w/ stock album cover</p>
        </div> */}
      </div>
    </div>
  );
}

export default ApproveProjects;
